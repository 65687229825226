import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import CheckboxInput from 'components/elements/Input/CheckboxInput'
import Input from 'components/elements/Input/Input'
import InputDate from 'components/elements/Input/InputDate'
import SelectMunicipalityOptions from 'components/elements/SelectOptions/SelectMunicipalityOptions'
import SelectProvinceOptions from 'components/elements/SelectOptions/SelectProvinceOptions'
import { TRANSFER_FOMENTO_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useToast from 'hooks/useToast'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'

import { FomentoInfo, FomentoPost, StepFomentoForm } from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useNavigate, useParams } from 'react-router-dom'
import { useConfigStore } from 'stores/useConfigStore'
import { useFomentoStore } from 'stores/useFomentoStore'
import { emptyString } from 'utils/common'
import { dateToSQL } from 'utils/dateUtils'
import { getFormikError } from 'utils/formikUtils.ts'

const FomentoForm = () => {
	const { transferId } = useParams() as { transferId: string }
	const transferIdNumber = parseInt(transferId)
	const navigate = useNavigate()
	const { t } = useTranslation()
	const configState = useConfigStore()
	const fomentoState = useFomentoStore()
	const [hasShowDistant, setHasShowDistant] = useState<boolean>(false)
	const [actualFomentoInfo, setActualFomentoInfo] = useState<FomentoInfo | null>(null)
	const { showBackendErrorToast } = useToast()

	const FOMENTO_STATUS_URL = `${TRANSFER_FOMENTO_URL}/${transferId}`

	const communicate = async () => {
		if (!transferId) return

		try {
			await fomentoState.communicate(parseInt(transferId))
			navigate(FOMENTO_STATUS_URL)
		} catch (err) {
			showBackendErrorToast(err)
		}
	}

	const getFomentoInfo = async () => {
		const actualFomentoInfo = await fomentoState.getFomentoInfoByBookingId(transferIdNumber)
		setActualFomentoInfo(actualFomentoInfo)
	}

	const formik = useFormik<StepFomentoForm>({
		initialValues: {
			provinceOriginId: null,
			municipalityOriginId: null,
			provinceDestinationId: null,
			municipalityDestinationId: null,
			provinceDistantId: null,
			municipalityDistantId: null,
			destinationFreely: false,
			addressDistant: emptyString,
			finishedAt: undefined
		},
		onSubmit: async (values) => {
			const fomentoForm: FomentoPost = {
				provinceOrigin: getProvinceCode(values.provinceOriginId),
				municipalityOrigin: getMunicipalityCode(values.municipalityOriginId),
				provinceDestination: getProvinceCode(values.provinceDestinationId),
				municipalityDestination: getMunicipalityCode(values.municipalityDestinationId),
				provinceDistant: getProvinceCode(values.provinceDistantId),
				municipalityDistant: getMunicipalityCode(values.municipalityDistantId),
				destinationFreely: values.destinationFreely,
				addressDistant: values.addressDistant,
				finishedAt: values.finishedAt ? values.finishedAt : undefined
			}

			try {
				if (actualFomentoInfo?.id) {
					await fomentoState.updateFomento(fomentoForm, parseInt(transferId))
				} else {
					await fomentoState.createFomento(fomentoForm, parseInt(transferId))
				}
				await communicate()
			} catch (err) {
				console.error(err)
				showBackendErrorToast(err)
			}
		}
	})

	useEffect(() => {
		getFomentoInfo()
	}, [transferIdNumber])

	useEffect(() => {
		const hasShow = hasToShowDistant()
		setHasShowDistant(hasShow)
	}, [
		formik.values['municipalityOriginId'],
		formik.values['municipalityDestinationId'],
		formik.values['provinceOriginId'],
		formik.values['provinceDestinationId']
	])

	useEffect(() => {
		if (!actualFomentoInfo) return
		formik.setFieldValue(
			'provinceOriginId',
			getProvinceByCodeHelper(actualFomentoInfo.provinceOrigin)
		)
		formik.setFieldValue(
			'municipalityOriginId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				actualFomentoInfo.provinceOrigin,
				actualFomentoInfo.municipalityOrigin
			)
		)

		formik.setFieldValue(
			'provinceDestinationId',
			getProvinceByCodeHelper(actualFomentoInfo.provinceDestination)
		)

		formik.setFieldValue(
			'municipalityDestinationId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				actualFomentoInfo.provinceDestination,
				actualFomentoInfo.municipalityDestination
			)
		)

		formik.setFieldValue(
			'provinceDistantId',
			getProvinceByCodeHelper(actualFomentoInfo.provinceDistant)
		)

		formik.setFieldValue(
			'municipalityDistantId',
			getMunicipalityByProvinceCodeAndMunicipalityCodeHelper(
				actualFomentoInfo.provinceDistant,
				actualFomentoInfo.municipalityDistant
			)
		)
		formik.setFieldValue('destinationFreely', actualFomentoInfo.destinationFreely)
		formik.setFieldValue('addressDistant', actualFomentoInfo.addressDistant)
		formik.setFieldValue('finishedAt', actualFomentoInfo.finishedAt)
	}, [actualFomentoInfo])

	const getProvinceCode = (provinceId: number | null) => {
		if (!provinceId) return null
		return configState.provinces.byId[provinceId]?.code
	}

	const getMunicipalityCode = (municipalityId: number | null) => {
		if (!municipalityId) return null
		return configState.municipalities.byId[municipalityId].municipalityCode
	}

	const getProvinceByCodeHelper = (provinceCode: string | null) => {
		if (!provinceCode) return null
		return configState.getProvinceByCode(provinceCode).id
	}

	const getMunicipalityByProvinceCodeAndMunicipalityCodeHelper = (
		provinceCode: string | null,
		municipalityCode: string | null
	) => {
		if (!provinceCode || !municipalityCode) return null
		return configState.getMunicipalityByProvinceCodeAndMunicipalityCode(
			provinceCode,
			municipalityCode
		).id
	}

	const hasToShowDistant = () => {
		if (!formik.values['municipalityOriginId'] || !formik.values['municipalityDestinationId']) {
			return false
		}
		return formik.values['municipalityOriginId'] === formik.values['municipalityDestinationId']
	}

	return (
		<FormLayout
			title={t('fomento.form_title')}
			leftButton={<ButtonBack />}
			footer={
				<ButtonComponent fullWidth onClick={formik.handleSubmit}>
					{t('fomento.communicate')}
				</ButtonComponent>
			}>
			<FormSectionLayout title={t('transfer.create_steps.step_fomento.h1')} noDivider>
				<InputDate
					id='finishedAt'
					label={t('transfer.create_steps.step_fomento.selectors.finishedAt.label')}
					value={formik.values['finishedAt'] ? new Date(formik.values['finishedAt']) : undefined}
					onSelect={(date) => formik.setFieldValue('finishedAt', dateToSQL(date))}
					error={getFormikError(formik, 'finishedAt')}
				/>
				<CheckboxInput
					label={t('transfer.create_steps.step_fomento.free_text')}
					id='destinationFreely'
					onChange={(e) => formik.setFieldValue('destinationFreely', e.target.checked)}
					checked={formik.values['destinationFreely']}
					styles={{ margin: '1rem 0' }}
				/>
				<SelectProvinceOptions
					onSelect={(provinceId) => {
						if (provinceId !== formik.values['provinceOriginId']) {
							formik.setFieldValue('provinceOriginId', provinceId)
							formik.setFieldValue('municipalityOriginId', null)
						}
					}}
					id='provinceOriginId'
					selectedId={formik.values['provinceOriginId']}
					error={getFormikError(formik, 'provinceOriginId')}
					label={t('transfer.create_steps.step_fomento.selectors.province.label.origin')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.province.placeholders.origin'
					)}
				/>
				<SelectMunicipalityOptions
					onSelect={(municipalityId) =>
						formik.setFieldValue('municipalityOriginId', municipalityId)
					}
					id='municipalityOriginId'
					selectedId={formik.values['municipalityOriginId']}
					provinceSelected={getProvinceCode(formik.values['provinceOriginId'])}
					error={getFormikError(formik, 'municipalityOriginId')}
					label={t('transfer.create_steps.step_fomento.selectors.municipality.label.origin')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.municipality.placeholders.origin'
					)}
				/>
				<SelectProvinceOptions
					onSelect={(provinceId) => {
						if (provinceId !== formik.values['provinceDestinationId']) {
							formik.setFieldValue('provinceDestinationId', provinceId)
							formik.setFieldValue('municipalityDestinationId', null)
						}
					}}
					id='provinceDestinationId'
					selectedId={formik.values['provinceDestinationId']}
					error={getFormikError(formik, 'provinceDestinationId')}
					label={t('transfer.create_steps.step_fomento.selectors.province.label.destination')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.province.placeholders.destination'
					)}
					isLabelOptional={formik.values['destinationFreely']}
				/>
				<SelectMunicipalityOptions
					onSelect={(municipalityId) =>
						formik.setFieldValue('municipalityDestinationId', municipalityId)
					}
					id='municipalityDestinationId'
					selectedId={formik.values['municipalityDestinationId']}
					provinceSelected={getProvinceCode(formik.values['provinceDestinationId'])}
					error={getFormikError(formik, 'municipalityDestinationId')}
					label={t('transfer.create_steps.step_fomento.selectors.municipality.label.destination')}
					placeholder={t(
						'transfer.create_steps.step_fomento.selectors.municipality.placeholders.destination'
					)}
					isLabelOptional={formik.values['destinationFreely']}
				/>
				{hasShowDistant && (
					<>
						<p>{t('transfer.create_steps.step_fomento.p')}</p>
						<SelectProvinceOptions
							onSelect={(provinceId) => {
								if (provinceId !== formik.values['provinceDistantId']) {
									formik.setFieldValue('provinceDistantId', provinceId)
									formik.setFieldValue('municipalityDistantId', null)
								}
							}}
							id='provinceDistantId'
							selectedId={formik.values['provinceDistantId']}
							error={getFormikError(formik, 'provinceDistantId')}
							label={t('transfer.create_steps.step_fomento.selectors.province.label.afar')}
							placeholder={t(
								'transfer.create_steps.step_fomento.selectors.province.placeholders.afar'
							)}
						/>
						<SelectMunicipalityOptions
							onSelect={(municipalityId) =>
								formik.setFieldValue('municipalityDistantId', municipalityId)
							}
							id='municipalityDistantId'
							selectedId={formik.values['municipalityDistantId']}
							provinceSelected={getProvinceCode(formik.values['provinceDistantId'])}
							error={getFormikError(formik, 'municipalityDistantId')}
							label={t('transfer.create_steps.step_fomento.selectors.municipality.label.afar')}
							placeholder={t(
								'transfer.create_steps.step_fomento.selectors.municipality.placeholders.afar'
							)}
						/>
						<Input
							type='text'
							label={t('transfer.create_steps.step_fomento.selectors.address.label.afar')}
							placeholder={t(
								'transfer.create_steps.step_fomento.selectors.address.placeholders.afar'
							)}
							id='addressDistant'
							onChange={(e) => formik.setFieldValue('addressDistant', e.target.value)}
							className={formik.values['addressDistant'] ? 'selected' : 'not-selected'}
							valueSelected={formik.values['addressDistant']}
							error={getFormikError(formik, 'addressDistant')}
						/>
					</>
				)}
			</FormSectionLayout>
		</FormLayout>
	)
}

export default FomentoForm
