import { User, UserForm } from 'models/User'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserStore } from 'stores/useUserStore'

const useUsers = (userId?: number) => {
	const userState = useUserStore()
	const [user, setUser] = useState<User | null>(null)
	const [users, setUsers] = useState<User[]>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<Nullable<string>>(null)

	const { t } = useTranslation()

	useEffect(() => {
		if (userId !== undefined) {
			fetchUser(userId)
		} else {
			fetchUsers()
		}
	}, [userId])

	const fetchUser = async (userId: number) => {
		setLoading(true)
		setError(null)

		try {
			let fetchedUser = userState.users.byId[userId]
			if (!fetchedUser) {
				fetchedUser = await userState.fetchUserById(userId)
				if (!fetchedUser) {
					throw new Error('User not found')
				}
			}
			setUser(fetchedUser)
			setUsers([])
		} catch (e) {
			setError(t('user.error_load_user'))
		}
		setLoading(false)
	}

	const fetchUsers = async () => {
		setLoading(true)
		setError(null)

		try {
			let fetchedUsers = userState.users.allIds.map((id) => userState.users.byId[id])

			if (fetchedUsers.length === 0) {
				fetchedUsers = await userState.fetchUsers()
			}

			setUsers(fetchedUsers)
			setUser(null)
		} catch (e) {
			setError(t('user.error_load_users'))
		}

		setLoading(false)
	}

	const updateUserById = async (user: Partial<UserForm>, userId: number) =>
		await userState.updateUserById(user, userId)

	const updateUser = async (user: Partial<UserForm>) => await userState.updateUser(user)

	return {
		user,
		users,
		currentUser: userState.currentUser,
		loading,
		error,
		updateUser,
		updateUserById
	}
}

export default useUsers
