import MyCompanyInputs from 'components/Settings/MyAccount/MyCompany/MyCompanyInputs'
import ButtonComponent from 'components/elements/Button/Button'
import StepNumber from 'components/elements/StepNumber/StepNumber'
import { ONBOARDING_CREATE_FIRST_PROVIDER_URL, ONBOARDING_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { CompanyForm } from 'models/Company'
import { DocumentType } from 'models/Provider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { useCompanyStore } from 'stores/useCompanyStore'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString } from 'utils/common'
import * as yup from 'yup'
import { ONBOARDING_FORM_PROGRESS, ONBOARDING_STEP } from './FirstSteps'
import './FirstSteps.scss'
import FirstStepsCounter from './FirstStepsCounter'

const MyCompanyProfile: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const companyState = useCompanyStore()
	const configStore = useConfigStore()
	const [loading, setLoading] = useState(false)

	const formik = useFormik<CompanyForm>({
		initialValues: {
			name: emptyString,
			document: emptyString,
			documentTypeId: null,
			mainEmail: emptyString,
			phone: emptyString,
			address: emptyString,
			provinceId: null,
			cityId: null,
			zipCode: emptyString,
			website: emptyString
		},
		validationSchema: yup.object().shape({
			name: yup
				.string()
				.required(
					t('errors.required_m', { field: t('onboarding.first_steps.label.company_name') })
				),
			document: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.nif') })),
			documentTypeId: yup
				.string()
				.required(t('errors.required_m', { field: t('general.document_type') })),
			mainEmail: yup
				.string()
				.email(t('errors.mail_not_valid'))
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.email') })),
			phone: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.telephone') })),
			address: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.address') })),
			provinceId: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.province') })),
			cityId: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.city') })),
			zipCode: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.post_code') }))
		}),
		onSubmit: async (values: CompanyForm) => {
			try {
				setLoading(true)
				const provinceCode = values.provinceId
					? configStore.provinces.byId[parseInt(values.provinceId)]?.code
					: emptyString
				const cityCode = values.cityId
					? configStore.municipalities.byId[parseInt(values.cityId)]?.municipalityCode
					: emptyString
				const documentType = values.documentTypeId
					? configStore.documentTypes.byId[values.documentTypeId]?.name
					: DocumentType.CIF

				const editedCompany = {
					...values,
					documentType: documentType,
					province: provinceCode,
					city: cityCode
				}
				await companyState.updateCompany(editedCompany)
				toast.success(t('toast.success.description'))
				navigate(ONBOARDING_CREATE_FIRST_PROVIDER_URL)
			} catch (error) {
				console.error({ error })
				toast.error(t('toast.error.description'))
			} finally {
				setLoading(false)
			}
		}
	})
	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	useEffect(() => {
		const isComplete = companyState.company.document === emptyString

		if (isComplete) {
			navigate(ONBOARDING_URL)
		}
	}, [companyState.company])

	return (
		<FormLayout
			title={t('onboarding.first_steps.form_title')}
			progress={ONBOARDING_FORM_PROGRESS[ONBOARDING_STEP.FIRST]}
			leftButton={
				<ButtonComponent variant='text' onClick={() => navigate(ONBOARDING_URL)}>
					{t('general.quit')}
				</ButtonComponent>
			}
			rightButton={<FirstStepsCounter step={ONBOARDING_STEP.FIRST} />}
			footer={
				<ButtonComponent type='submit' onClick={handleSubmit} fullWidth loading={loading}>
					{t('general.save_and_continue')}
				</ButtonComponent>
			}>
			<FormSectionLayout
				title={t('onboarding.first_steps.my_company_profile')}
				titleIcon={<StepNumber number={ONBOARDING_STEP.FIRST} size='large' />}>
				<MyCompanyInputs formik={formik} setFieldRef={setFieldRef} />
			</FormSectionLayout>
		</FormLayout>
	)
}

export default MyCompanyProfile
