import { useUserStore } from 'stores/index'

export function getUserToken(): string | undefined {
	const state = useUserStore.getState()
	return state?.currentUser?.token
}

export function getUserId(): number | undefined {
	const state = useUserStore.getState()
	return state?.currentUser?.id
}

export function getCompanyId(): number | undefined {
	const state = useUserStore.getState()
	return state?.currentUser?.companyId
}
