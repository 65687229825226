import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CrossIcon: FC<Props> = (props) => {
	const { width = 23, height = 23 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 23 23'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M18.4777 17.4606C18.5444 17.5274 18.5974 17.6067 18.6336 17.6939C18.6697 17.7812 18.6883 17.8747 18.6883 17.9692C18.6883 18.0636 18.6697 18.1571 18.6336 18.2444C18.5974 18.3316 18.5444 18.4109 18.4777 18.4777C18.4109 18.5444 18.3316 18.5974 18.2444 18.6336C18.1571 18.6697 18.0636 18.6883 17.9692 18.6883C17.8747 18.6883 17.7812 18.6697 17.6939 18.6336C17.6067 18.5974 17.5274 18.5444 17.4606 18.4777L11.5004 12.5165L5.54017 18.4777C5.4053 18.6125 5.22238 18.6883 5.03165 18.6883C4.84092 18.6883 4.658 18.6125 4.52313 18.4777C4.38827 18.3428 4.3125 18.1599 4.3125 17.9692C4.3125 17.7784 4.38827 17.5955 4.52313 17.4606L10.4843 11.5004L4.52313 5.54017C4.38827 5.4053 4.3125 5.22238 4.3125 5.03165C4.3125 4.84092 4.38827 4.658 4.52313 4.52313C4.658 4.38827 4.84092 4.3125 5.03165 4.3125C5.22238 4.3125 5.4053 4.38827 5.54017 4.52313L11.5004 10.4843L17.4606 4.52313C17.5955 4.38827 17.7784 4.3125 17.9692 4.3125C18.1599 4.3125 18.3428 4.38827 18.4777 4.52313C18.6125 4.658 18.6883 4.84092 18.6883 5.03165C18.6883 5.22238 18.6125 5.4053 18.4777 5.54017L12.5165 11.5004L18.4777 17.4606Z'
				fill='#F3535D'
			/>
		</svg>
	)
}

export default CrossIcon
