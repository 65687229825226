import { useTranslation } from 'react-i18next'

import { CalendarBlank, CreateTransfer, ResourcesIcon, WhatsappIcon } from 'assets/svgs'
import ActionButton from 'components/elements/ActionButton/ActionButton'

import SuccessMenu from 'components/elements/SuccessMenu/SuccessMenu'
import {
	CREATE_URL,
	DAILY_URL,
	RESOURCES_URL,
	TRANSFER_STEPS,
	TRANSFER_URL
} from 'constants/routes'
import useSupport from 'hooks/useSupport'
import { useNavigate } from 'react-router-dom'

const actionIconWhatsappBgColor = '#9AE5A0'

const SubscriptionSuccess = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const handleSupport = useSupport()

	return (
		<SuccessMenu
			title={`${t('settings.my_account.subscription.success_page.title')}`}
			subtitle={`${t('settings.my_account.subscription.success_page.subtitle')}`}>
			<p>{t('settings.my_account.subscription.success_page.description')}</p>
			<ActionButton
				icon={<CalendarBlank />}
				backGroundIcon='#3F52FF1A'
				onClick={() => navigate(DAILY_URL)}>
				{t('transfer.create_steps.step_end.buttons.view_daily')}
			</ActionButton>
			<ActionButton
				icon={<CreateTransfer />}
				backGroundIcon='#130D2A'
				onClick={() =>
					navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.ROUTE.toLocaleLowerCase()}`)
				}>
				{t('settings.my_account.subscription.success_page.create_transfer_button')}
			</ActionButton>
			<ActionButton
				icon={<ResourcesIcon width={24} isActive />}
				backGroundIcon='#f6f4ff'
				onClick={() => navigate(RESOURCES_URL)}>
				{t('routes.resources')}
			</ActionButton>
			<ActionButton
				icon={<WhatsappIcon />}
				backGroundIcon={actionIconWhatsappBgColor}
				noRightArrow
				onClick={handleSupport}>
				{t('settings.my_account.support')}
			</ActionButton>
		</SuccessMenu>
	)
}

export default SubscriptionSuccess
