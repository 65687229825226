import { useState } from 'react'
import { firstElement } from 'utils/common'

const useAccordion = () => {
	const [activeIndex, setActiveIndex] = useState<number | null>(firstElement)

	const toggleItem = (index: number) => {
		setActiveIndex(activeIndex === index ? null : index)
	}

	return { activeIndex, toggleItem }
}

export default useAccordion
