import Input from 'components/elements/Input/Input'
import InputSwitch from 'components/elements/Input/InputSwitch'
import SelecteVehicleClassOptions from 'components/elements/SelectOptions/SelecteVehicleClassOptions'
import { FormikProps } from 'formik'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { VehicleForm } from 'models/Vehicle'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormikError } from 'utils/formikUtils'

interface Props {
	formik: FormikProps<VehicleForm>
	setFieldRef: (field: string) => (ref: HTMLInputElement) => void
}

const VehicleInputs: FC<Props> = (props) => {
	const { formik, setFieldRef } = props
	const { t } = useTranslation()
	const [isPlateInputFocused, setIsPlateInputFocused] = useState(false)

	return (
		<>
			<FormSectionLayout title={t('vehicle.vehicle_details')}>
				<Input
					ref={setFieldRef('plate')}
					type='text'
					id='plate'
					label={t('vehicle.plate_label')}
					placeholder={t('vehicle.plate_placeholder')}
					onFocus={() => setIsPlateInputFocused(true)}
					onBlur={() => setIsPlateInputFocused(false)}
					onChange={(e) => formik.setFieldValue('plate', e.target.value.toUpperCase())}
					error={getFormikError(formik, 'plate')}
					valueSelected={formik.values.plate}
				/>
				{isPlateInputFocused && <span>{t('vehicle.plate_info')}</span>}
				<Input
					ref={setFieldRef('brand')}
					type='text'
					id='brand'
					label={t('vehicle.brand_label')}
					placeholder={t('vehicle.brand_placeholder')}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'brand')}
					valueSelected={formik.values.brand}
				/>
				<Input
					ref={setFieldRef('model')}
					type='text'
					id='model'
					label={t('vehicle.model_label')}
					placeholder={t('vehicle.model_placeholder')}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'model')}
					valueSelected={formik.values.model}
				/>
				<SelecteVehicleClassOptions
					ref={setFieldRef('classId')}
					label={t('vehicle.class_label')}
					selectedId={formik.values.classId}
					onSelect={(id) => formik.setFieldValue('classId', id)}
					error={getFormikError(formik, 'classId')}
				/>
			</FormSectionLayout>
			<FormSectionLayout title={t('vehicle.vehicle_condition')}>
				<InputSwitch
					id='isActive'
					label={t('general.active')}
					value={formik.values.isActive}
					onChange={(value) => formik.setFieldValue('isActive', value)}
					dataTest='switch-active'
				/>
			</FormSectionLayout>
		</>
	)
}

export default VehicleInputs
