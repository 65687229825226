import { schema } from 'normalizr'
import { driverSchema } from './driverSchema'
import { providerSchema } from './providerSchema'
import { userSchema } from './userSchema'
import { vehicleSchema } from './vehicleSchema'

export const transferSchema = new schema.Entity('transfers', {
	driver: driverSchema,
	provider: providerSchema,
	vehicle: vehicleSchema,
	employee: userSchema
})
