import { UserRoles } from 'models/User'

export function currentUserIsAdmin(): boolean {
	if (localStorage.getItem('users store')) {
		const currentUser = JSON.parse(localStorage.getItem('users store') || '[]')?.state?.currentUser

		if (!currentUser) return false

		return (
			currentUser.userTypeId === UserRoles.admin ||
			currentUser.userTypeId === UserRoles['super-admin']
		)
	}

	return false
}
