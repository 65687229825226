import AssociationList, {
	AssociationOption
} from 'components/elements/AssociationList/AssociationList'
import { User } from 'models/User'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDriversStore } from 'stores/useDriversStore'

interface Props {
	user: User
	onChangeAssociations: (userIds: number[]) => void
}

const AssociationsDriver: FC<Props> = (props) => {
	const { user, onChangeAssociations } = props

	const driverStore = useDriversStore()
	const [selectedDrivers, setSelectedDrivers] = useState(new Set<number>())
	const [associationsOptions, setAssociationsOptions] = useState<AssociationOption[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const { t } = useTranslation()

	useEffect(() => {
		getAssociationsByUserId(user.id)
	}, [driverStore.driversAssociations])

	const getAssociationsByUserId = async (userId: number) => {
		const associations = driverStore.getAssociationsByUserId(userId)
		const driverIds = associations.map((association) => association.driverId)

		setSelectedDrivers(new Set(driverIds))
	}

	const formatAssociations = (): AssociationOption[] => {
		const newAssociations: AssociationOption[] = driverStore.drivers.allIds
			.filter(
				(id) => driverStore.drivers.byId[id].isActive && driverStore.drivers.byId[id].isVisible
			)
			.map((id) => {
				const driver = driverStore.drivers.byId[id]
				const isChecked = selectedDrivers.has(driver.id)
				return {
					id: driver.id,
					name: driver.name,
					isSelected: isChecked
				}
			})
		return newAssociations
	}

	const handleSelection = (selected: AssociationOption[]) => {
		const newSelectedUsers = new Set<number>()
		selected.forEach((association) => {
			if (association.isSelected) {
				newSelectedUsers.add(association.id)
			}
		})
		setSelectedDrivers(newSelectedUsers)
		onChangeAssociations(Array.from(newSelectedUsers))
	}
	const getAllDriverAssociations = async () => {
		setIsLoading(true)
		await driverStore.fetchAllDriverAssociations()
		setIsLoading(false)
	}

	useEffect(() => {
		const associations = formatAssociations()
		setAssociationsOptions(associations)
		onChangeAssociations(Array.from(selectedDrivers))
	}, [selectedDrivers])

	useEffect(() => {
		getAllDriverAssociations()
	}, [])

	return (
		<AssociationList
			isLoading={isLoading}
			associations={associationsOptions}
			title={t('driver_association.association_list_title')}
			addButtonText={t('driver_association.association_list_button')}
			modalContent={{
				title: t('driver_association.association_list_modal_title'),
				subtitle: t('driver_association.association_list_modal_subtitle'),
				description: t('driver_association.association_list_modal_description')
			}}
			handleSelection={handleSelection}
		/>
	)
}

export default AssociationsDriver
