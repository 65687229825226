import { DocumentType } from 'models/Provider'

export interface CompanyForm {
	name: string
	document: string
	documentTypeId: Nullable<number>
	address: string
	zipCode: string
	phone: string
	mainEmail: string
	website: string
	provinceId: Nullable<string>
	cityId: Nullable<string>
}
export interface Company {
	id: number
	name: string
	document: string
	documentType: keyof typeof DocumentType
	address: string
	zipCode: string
	phone: string
	mainEmail: string
	website: string
	logo: string
	logoType: string
	billingEmail: string
	contractEnd: string
	stripeId: string
	createdAt: string
	isActive: boolean
	province: string
	city: string
	hasTransfers: boolean
}
export const enum SubscriptionFrequency {
	month = 'month',
	year = 'year'
}
export interface SubscriptionType {
	id: number
	name: string
	color: string
	plan: string
	amount: number
	taxPercent: number
	extraCompanies: number
	maxEnrollments: number
	order: number
	isActive: boolean
	amountVatIncluded: number
	type: SubscriptionFrequency
}

export interface MySubscription {
	id: number
	companyId: number
	subscriptionId: string
	customerId: string
	since: string
	until: string
	interval: SubscriptionFrequency
	intervalCount: number
	price: number
	planId: string
	vat: number
	status: string
	registrationDate: string
	subscriptionTypeName: string
	amountVatIncluded: number
}
