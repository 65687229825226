import { TrashFilledIcon, TrashIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import InputSwitch from 'components/elements/Input/InputSwitch'
import Loading from 'components/elements/Loading/Loading'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import {
	ERROR_URL,
	NOT_FOUND_URL,
	RESOURCES_URL,
	VEHICLES_EDIT_URL,
	VEHICLES_LIST_URL
} from 'constants/routes'
import useVehicles from 'hooks/useVehicles'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useVehiclesStore } from 'stores/useVehiclesStore'
import { emptyString } from 'utils/common'
import '../ResourceView.scss'

import DetailDate from './DetailDate'

const VehicleView: FC = () => {
	const { vehicleId } = useParams<{ vehicleId: string }>()
	const vehicleIdNumber = parseInt(vehicleId ?? emptyString) || undefined
	const { vehicle, loading, error } = useVehicles(vehicleIdNumber)
	const vehicleState = useVehiclesStore()

	const navigate = useNavigate()
	const { t } = useTranslation()

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

	const closeDeleteModal = () => setIsDeleteModalVisible(false)

	useEffect(() => {
		if (!loading) {
			if (error) {
				navigate(ERROR_URL, { state: { description: error, link: RESOURCES_URL } })
			} else if (!vehicle || !vehicle.isVisible) {
				navigate(NOT_FOUND_URL)
			}
		}
	}, [vehicle, loading, error, navigate])

	const deleteVehicle = async () => {
		if (!vehicleIdNumber) return
		await vehicleState.deleteVehicle(vehicleIdNumber)
		navigate(VEHICLES_LIST_URL)
	}

	if (loading) return <Loading fullscreen />

	if (!vehicle || !vehicle.isVisible) return null

	return (
		<>
			<FormLayout
				title={t('vehicle.view_title')}
				leftButton={<ButtonBack url={VEHICLES_LIST_URL} />}
				rightButton={
					<ButtonComponent
						onClick={() => navigate(`${VEHICLES_EDIT_URL}/${vehicleId}`)}
						variant='only-text'>
						<strong>{t('general.edit')}</strong>
					</ButtonComponent>
				}>
				<FormSectionLayout title={t('vehicle.vehicle_details')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('vehicle.plate_label')}>{vehicle.plate}</DetailsCardItem>
						<DetailsCardItem title={t('vehicle.brand_label')}>{vehicle.brand}</DetailsCardItem>
						<DetailsCardItem title={t('vehicle.model_label')}>{vehicle.model}</DetailsCardItem>
						<DetailsCardItem title={t('vehicle.class_label')}>{vehicle.class}</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				{vehicle.cardDate || vehicle.itvDate || vehicle.oilDate || vehicle.tiresDate ? (
					<FormSectionLayout title={t('vehicle.maintenance')} noDivider>
						<DetailsCard>
							{vehicle.cardDate && (
								<DetailsCardItem title={t('vehicle.transport_card_label')}>
									<DetailDate date={new Date(vehicle.cardDate)} />
								</DetailsCardItem>
							)}
							{vehicle.itvDate && (
								<DetailsCardItem title={t('vehicle.itv')}>
									<DetailDate date={new Date(vehicle.itvDate)} />
								</DetailsCardItem>
							)}
							{vehicle.oilDate && (
								<DetailsCardItem title={t('vehicle.oil_check_label')}>
									<DetailDate date={new Date(vehicle.oilDate)} />
								</DetailsCardItem>
							)}
							{vehicle.tiresDate && (
								<DetailsCardItem title={t('vehicle.change_tires_label')}>
									<DetailDate date={new Date(vehicle.tiresDate)} />
								</DetailsCardItem>
							)}
						</DetailsCard>
					</FormSectionLayout>
				) : null}
				<FormSectionLayout title={t('vehicle.vehicle_condition')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('vehicle.condition')}>
							<InputSwitch
								id='status'
								label={t('vehicle.condition')}
								value={vehicle.isActive}
								dataTest='switch-active'
							/>
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				<ButtonComponent
					className='resource-view-delete-button'
					onClick={() => setIsDeleteModalVisible(true)}>
					<TrashFilledIcon />
					<span className='body1'>{t('vehicle.delete_vehicle_button')}</span>
				</ButtonComponent>
			</FormLayout>
			<ModalActions
				type='error'
				icon={<TrashIcon />}
				title={t('vehicle.delete_modal.title')}
				description={t('vehicle.delete_modal.description')}
				primaryButton={{
					text: t('vehicle.delete_modal.primary_button'),
					className: 'modal-delete-resource-primary-button',
					onClick: () => deleteVehicle()
				}}
				secondaryButton={{
					text: t('general.cancel'),
					className: 'modal-delete-resource-secondary-button',
					onClick: closeDeleteModal
				}}
				isVisible={isDeleteModalVisible}
				onClose={closeDeleteModal}
			/>
		</>
	)
}

export default VehicleView
