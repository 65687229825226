import { FC } from 'react'
import { SwipeableList } from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css'

interface Props {
	children: React.ReactNode
}

const SwipeList: FC<Props> = (props) => {
	const { children } = props

	return <SwipeableList>{children}</SwipeableList>
}

export default SwipeList
