import './EmptyList.scss'

interface Props {
	icon: React.ReactNode
	children: React.ReactNode
}

const EmptyList: React.FC<Props> = (props) => {
	const { icon, children } = props

	return (
		<div className='empty-list'>
			<div className='empty-list-icon'>{icon}</div>
			<div className='empty-list-children'>{children}</div>
		</div>
	)
}

export default EmptyList
