import { ENVIRONMENT } from 'constants/environment'
import { ChangeMyPasswordForm, User, UserForm, UserPost, UserRoles } from 'models/User'
import { normalize } from 'normalizr'
import { userSchema } from 'schemas/index'
import {
	Credentials,
	changeMyPasswordService,
	createUserByTypeService,
	fetchUsersService,
	getMeService,
	getUserByIdService,
	loginService,
	skipTourService,
	updateUserService
} from 'services/user'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

interface State {
	users: {
		byId: { [id: number]: User }
		allIds: number[]
	}
	currentUser: User
	addUser: (user: User) => void
	logIn: (credentials: Credentials) => Promise<void>
	getMe: (token: string) => Promise<void>
	logOut: () => void
	createUserByType: (user: UserPost) => Promise<User>
	updateUser: (user: Partial<UserForm>) => Promise<void>
	updateUserById: (user: Partial<UserForm>, userId: number) => Promise<void>
	changeMyPassword: (newPassword: ChangeMyPasswordForm) => Promise<void>
	fetchUsers: () => Promise<User[]>
	fetchUserById: (id: number) => Promise<User>
	isAdmin(): boolean
	resetUsers: () => void
	skipTour: () => void
}

export const useUserStore = create<State>()(
	devtools(
		persist(
			(set, get) => ({
				users: {
					byId: {},
					allIds: []
				},
				currentUser: {} as User,
				addUser: (user) => {
					const normalizedUser = normalize(user, userSchema)
					set((state) => ({
						users: {
							byId: { ...state.users.byId, ...normalizedUser.entities.users },
							allIds: [...state.users.allIds, normalizedUser.result]
						}
					}))
				},
				logIn: async (credentials) => {
					const userFromResponse = await loginService(credentials)

					set(() => ({
						currentUser: userFromResponse
					}))
				},
				getMe: async (token) => {
					const userFromResponse = await getMeService(token)

					set(() => ({
						currentUser: userFromResponse
					}))
				},
				logOut: () => {
					const domain = window.location.hostname.split('.').slice(-2).join('.')
					document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.${domain};`

					set({ currentUser: {} as User })
				},
				updateUser: async (user) => {
					const userId = get().currentUser.id
					const updatedUser = await updateUserService(user, userId)

					set((state) => ({
						currentUser: { ...updatedUser, token: state.currentUser.token },
						users: {
							byId: {
								...state.users.byId,
								[userId]: { ...updatedUser, token: state.currentUser.token }
							},
							allIds: state.users.allIds
						}
					}))
				},
				updateUserById: async (user, userId) => {
					const updatedUser = await updateUserService(user, userId)

					set((state) => ({
						users: {
							byId: {
								...state.users.byId,
								[userId]: updatedUser
							},
							allIds: state.users.allIds
						}
					}))
				},
				changeMyPassword: async (newPassword) => {
					await changeMyPasswordService(newPassword)
				},
				createUserByType: async (user) => {
					const userFromResponse = await createUserByTypeService(user)
					const normalizedData = normalize(userFromResponse, userSchema)
					set((state) => ({
						users: {
							byId: { ...state.users.byId, ...normalizedData.entities.users },
							allIds: [...state.users.allIds, normalizedData.result]
						}
					}))
					return userFromResponse
				},
				fetchUsers: async () => {
					const users = await fetchUsersService()
					const normalizedData = normalize(users, [userSchema])
					set({
						users: {
							byId: { ...normalizedData.entities.users },
							allIds: normalizedData.result
						}
					})
					return users
				},
				fetchUserById: async (id) => {
					const user = await getUserByIdService(id)
					const normalizedData = normalize(user, userSchema)
					set((state) => ({
						users: {
							byId: { ...state.users.byId, ...normalizedData.entities.users },
							allIds: [...state.users.allIds, normalizedData.result]
						}
					}))
					return user
				},
				isAdmin: () => {
					const currentUser = get().currentUser
					const rolesAdmins = [UserRoles.admin, UserRoles['super-admin']]
					return rolesAdmins.includes(currentUser.userTypeId as UserRoles)
				},
				resetUsers: () => {
					set({ users: { byId: {}, allIds: [] } })
				},
				skipTour: async () => {
					await skipTourService()
					set((state) => ({
						currentUser: {
							...state.currentUser,
							showTutorial: false
						}
					}))
				}
			}),
			{
				name: 'users store',
				storage: createJSONStorage(() => localStorage)
			}
		),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'users store'
		}
	)
)
