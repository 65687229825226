import { ENVIRONMENT } from 'constants/environment'
import { ProviderAssociations, ProviderPost, type Provider } from 'models/Provider'
import { normalize } from 'normalizr'
import { providerAssociationSchema, providerSchema } from 'schemas/index'
import {
	createProviderService,
	deleteAssociationsByProviderId,
	deleteProviderById,
	getAllProvidersService,
	getProviderAssociations,
	getProviderById,
	resetAndSetAssociationsToProvider,
	updateProviderService
} from 'services/providers'
import { createProviderAssociationsByUserService } from 'services/user'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface ProvidersState {
	providers: {
		byId: { [id: number]: Provider }
		allIds: number[]
	}
	providersAssociations: {
		byId: { [id: number]: ProviderAssociations }
		allIds: number[]
	}
	addProvider: (provider: Provider) => void
	removeProvider: (providerId: number) => Promise<void>
	removeProviderAssociations: (providerId: number) => void
	fetchProviders: () => Promise<Provider[]>
	fetchProviderById: (id: number) => Promise<Provider>
	resetProviders: () => void
	resetAllProviderInfo: () => void
	createProvider: (provider: ProviderPost) => Promise<Provider>
	updateProvider: (provider: Partial<ProviderPost>, providerId: number) => Promise<Provider>
	fetchAllProviderAssociations: () => Promise<ProviderAssociations[]>
	getAssociationsByProviderId: (providerId: number) => ProviderAssociations[]
	getAssociationsByUserId: (userId: number) => ProviderAssociations[]
	createAssociationsByUserId: (
		userId: number,
		providerIds: number[]
	) => Promise<ProviderAssociations[]>
	resetAndSetAssociationsToProvider: (
		providerId: number,
		associations: number[]
	) => Promise<ProviderAssociations[]>
	deleteAssociationsByProviderId: (providerId: number) => Promise<void>
}

export const useProvidersStore = create<ProvidersState>()(
	devtools(
		(set, get) => ({
			providers: {
				byId: {},
				allIds: [] as number[]
			},
			providersAssociations: {
				byId: {},
				allIds: [] as number[]
			},
			addProvider: (provider) => {
				const normalizedProvider = normalize(provider, providerSchema)
				set((state) => ({
					providers: {
						byId: { ...state.providers.byId, ...normalizedProvider.entities.providers },
						allIds: [...state.providers.allIds, normalizedProvider.result]
					}
				}))
			},
			removeProvider: async (providerId) => {
				await deleteProviderById(providerId)

				set((state) => {
					const newProviders: { [key: string]: Provider } = {
						...state.providers.byId
					}
					Object.keys(newProviders).forEach((key) => {
						if (newProviders[key].id === providerId) {
							delete newProviders[key]
						}
					})
					return {
						providers: {
							byId: newProviders,
							allIds: Object.keys(newProviders).map((key) => parseInt(key))
						}
					}
				})
			},
			removeProviderAssociations: (providerId) => {
				set((state) => {
					const newAssociations: { [key: string]: ProviderAssociations } = {
						...state.providersAssociations.byId
					}
					Object.keys(newAssociations).forEach((key) => {
						if (newAssociations[key].providerId === providerId) {
							delete newAssociations[key]
						}
					})
					return {
						providersAssociations: {
							byId: newAssociations,
							allIds: Object.keys(newAssociations).map((key) => parseInt(key))
						}
					}
				})
			},
			fetchProviders: async () => {
				const providers = await getAllProvidersService()
				const normalizedData = normalize(providers, [providerSchema])
				set({
					providers: {
						byId: { ...normalizedData.entities.providers },
						allIds: normalizedData.result
					}
				})
				return providers
			},
			fetchProviderById: async (id) => {
				const provider = await getProviderById(id)
				const normalizedData = normalize(provider, providerSchema)
				set({
					providers: {
						byId: { ...normalizedData.entities.providers },
						allIds: normalizedData.result
					}
				})
				return provider
			},
			resetProviders: () => {
				set({ providers: { byId: {}, allIds: [] } })
			},
			resetAllProviderInfo: () => {
				set({
					providers: { byId: {}, allIds: [] },
					providersAssociations: { byId: {}, allIds: [] }
				})
			},
			createProvider: async (provider) => {
				const createdProvider = await createProviderService(provider)
				const normalizedData = normalize(createdProvider, providerSchema)
				set((state) => ({
					providers: {
						byId: { ...state.providers.byId, ...normalizedData.entities.providers },
						allIds: [...state.providers.allIds, normalizedData.result]
					}
				}))
				return createdProvider
			},
			updateProvider: async (provider, providerId) => {
				const updatedProvider = await updateProviderService(provider, providerId)
				const normalizedData = normalize(updatedProvider, providerSchema)
				set((state) => {
					const idExists = state.providers.allIds.includes(normalizedData.result)

					return {
						providers: {
							byId: { ...state.providers.byId, ...normalizedData.entities.providers },
							allIds: idExists
								? state.providers.allIds
								: [...state.providers.allIds, normalizedData.result]
						}
					}
				})
				return updatedProvider
			},
			fetchAllProviderAssociations: async () => {
				const associations = await getProviderAssociations()
				const normalizedData = normalize(associations, [providerAssociationSchema])

				set(() => ({
					providersAssociations: {
						byId: normalizedData.entities.providersAssociations || {},
						allIds: normalizedData.result || []
					}
				}))
				return associations
			},
			getAssociationsByProviderId: (providerId: number) => {
				const associations = get().providersAssociations.byId
				const associationsFounded = Object.values(associations).filter(
					(association) => association.providerId === providerId
				) as ProviderAssociations[]
				return associationsFounded
			},
			getAssociationsByUserId: (userId: number) => {
				const associations = get().providersAssociations.byId
				const associationsFounded = Object.values(associations).filter(
					(association) => association.userId === userId
				) as ProviderAssociations[]
				return associationsFounded
			},
			createAssociationsByUserId: async (userId, providerIds) => {
				const associations = await createProviderAssociationsByUserService(userId, providerIds)
				const normalizedData = normalize(associations, [providerAssociationSchema])
				set((state) => ({
					providersAssociations: {
						byId: {
							...state.providersAssociations.byId,
							...normalizedData.entities.providersAssociations
						},
						allIds: [...state.providersAssociations.allIds, ...normalizedData.result]
					}
				}))
				return associations
			},
			resetAndSetAssociationsToProvider: async (providerId: number, associations: number[]) => {
				const newAssociations = await resetAndSetAssociationsToProvider(providerId, associations)
				const normalizedData = normalize(newAssociations, [providerAssociationSchema])
				set((state) => ({
					...state,
					providersAssociations: {
						byId: {
							...normalizedData.entities.providersAssociations
						},
						allIds: normalizedData.result
					}
				}))
				return newAssociations
			},
			deleteAssociationsByProviderId: async (providerId: number) => {
				await deleteAssociationsByProviderId(providerId)

				get().removeProviderAssociations(providerId)
			}
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'providers store'
		}
	)
)
