import './List.scss'

interface Props {
	children: React.ReactNode
}

const List: React.FC<Props> = (props) => {
	const { children } = props

	return <div className='list'>{children}</div>
}

export default List
