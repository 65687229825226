import { BabySeat, Booster, Maxicosi } from 'assets/svgs'
import ExtraItem from 'components/elements/ExtrasItem/ExtraItem'
import { FormikProps } from 'formik'
import { AllStepForms, Extra, ExtraTypes, StepExtrasForm } from 'models/Transfer'
import { FC } from 'react'

const extras: Extra[] = [
	{
		id: 3,
		name: 'transfer.add_maxicosi_seat',
		key: ExtraTypes.maxicosiSeats,
		icon: <Maxicosi />
	},
	{
		id: 2,
		name: 'transfer.add_baby_seat',
		key: ExtraTypes.babySeats,
		icon: <BabySeat />
	},
	{
		id: 1,
		name: 'transfer.add_booster_seat',
		key: ExtraTypes.boosterSeats,
		icon: <Booster />
	}
]

interface Props {
	formik: FormikProps<StepExtrasForm> | FormikProps<AllStepForms>
}

const StepExtrasInputs: FC<Props> = (props) => {
	const { formik } = props

	return (
		<>
			<div className='step-extras-container'>
				{extras.map((extra) => (
					<ExtraItem
						extra={extra}
						key={extra.id}
						onChange={(p) => formik.setFieldValue(extra.key, p)}
						value={formik.values[extra.key] as number}
					/>
				))}
			</div>
		</>
	)
}

export default StepExtrasInputs
