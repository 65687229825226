import { MagnifyingGlassIcon, SteeringWheel, UserList } from 'assets/svgs'
import { NonAdminRoleKeys } from 'models/User'
import { FC } from 'react'
import './SelectRoleCard.scss'

const mockedCard = {
	driver: {
		title: 'Conductor',
		description:
			'El rol conductor es el blab labl a blabl al blalbala bllbal ba blab labl a blabl al blalbala bllbal ba'
	},
	provider: {
		title: 'Proveedor',
		description:
			'El rol proveedor es el blab labl a blabl al blalbala bllbal ba blab labl a blabl al blalbala bllbal ba'
	},
	revisor: {
		title: 'Revisor',
		description:
			'El rol revisor es el blab labl a blabl al blalbala bllbal ba blab labl a blabl al blalbala bllbal ba'
	}
}

interface Props {
	type: NonAdminRoleKeys
	onClick: () => void
}

const SelectRoleCard: FC<Props> = (props) => {
	const { type, onClick } = props

	const icon = {
		provider: <UserList width={25} height={25} />,
		driver: <SteeringWheel width={25} height={25} />,
		revisor: <MagnifyingGlassIcon width={25} height={25} />
	}

	return (
		<button className='select-role-card' onClick={onClick}>
			<header className='select-role-card-header'>
				<div className={`select-role-card-header-icon ${type}`}>{icon[type]}</div>
				<span className='select-role-card-header-title'>{mockedCard[type].title}</span>
			</header>
			<p className='select-role-card-description'>{mockedCard[type].description}</p>
		</button>
	)
}

export default SelectRoleCard
