import { FilePdfIcon, FileXlsIcon } from 'assets/svgs'
import ActionButton from 'components/elements/ActionButton/ActionButton'
import { ColumnItem } from 'components/elements/DragAndDropColumnList/DragAndDropColumnList'
import ModalFormLayout from 'components/elements/Modal/ModalFormLayout/ModalFormLayout'
import { useModal } from 'hooks/useModal'
import useToast from 'hooks/useToast'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { HistoricalExportFormat, HistoricalExportType } from 'models/Elements'
import { TransferFilter } from 'models/Transfer'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	exportHistoricalPdfTransfersService,
	exportHistoricalXlsTransfersService
} from 'services/transfers'
import GenerateDocumentModal from './GenerateDocumentModal'

interface Props {
	isVisible: boolean
	toggleModalVisibility: () => void
	filter: TransferFilter
}

const ExportHistoricalModal: React.FC<Props> = (props) => {
	const { isVisible, toggleModalVisibility, filter } = props
	const [type, setType] = useState<HistoricalExportType>()
	const { showSuccessToast, showErrorToast, showBackendErrorToast } = useToast()
	const [loading, setLoading] = useState(false)

	const { t } = useTranslation()
	const [generateDocModalIsVisible, toggleGenerateDocModalVisibility] = useModal()

	const select = (type: HistoricalExportType) => {
		setType(type)
		toggleGenerateDocModalVisibility()
	}

	const handleExport = async (colums: ColumnItem[]) => {
		setLoading(true)

		const columnsSelectd = colums.filter((column) => column.selected)

		if (!columnsSelectd.length) {
			showErrorToast({
				description: t('historical.export_modal.no_columns_selected'),
				duration: 2000
			})
			return setLoading(false)
		}

		try {
			if (type === HistoricalExportFormat.xls) {
				const xls = await exportHistoricalXlsTransfersService(filter, columnsSelectd)
				await downloadXls(xls)
			}

			if (type === HistoricalExportFormat.pdf) {
				const pdf = await exportHistoricalPdfTransfersService(filter, columnsSelectd)
				await downloadPdf(pdf)
			}
			showSuccessToast({
				description: t('historical.export_modal.success'),
				duration: 2000
			})
		} catch (error) {
			showBackendErrorToast(error)
		} finally {
			setLoading(false)
			toggleGenerateDocModalVisibility()
			toggleModalVisibility()
		}
	}

	const downloadPdf = async (pdfString: string) => {
		const a = document.createElement('a')
		a.href = 'data:application/octet-stream;base64,' + pdfString
		a.target = '_blank'
		a.download = 'listado_reservas.pdf'
		a.click()
	}

	const downloadXls = async (xmlString: string) => {
		const a = document.createElement('a')
		a.href = 'data:application/vnd.ms-excel;base64,' + xmlString
		a.target = '_blank'
		a.download = 'listado_reservas.xls'
		a.click()
	}

	return (
		<ModalFormLayout
			title={t('historical.export_modal.title')}
			isVisible={isVisible}
			toggleVisibility={toggleModalVisibility}>
			<FormSectionLayout smallGap>
				<ActionButton
					icon={<FileXlsIcon />}
					backGroundIcon='#217346'
					onClick={() => select(HistoricalExportFormat.xls)}>
					{t('historical.export_modal.xls')}
				</ActionButton>
				<ActionButton
					icon={<FilePdfIcon />}
					backGroundIcon='#F45151'
					onClick={() => select(HistoricalExportFormat.pdf)}>
					{t('historical.export_modal.pdf')}
				</ActionButton>
			</FormSectionLayout>
			<GenerateDocumentModal
				isVisible={generateDocModalIsVisible}
				toggleModalVisibility={toggleGenerateDocModalVisibility}
				exportData={handleExport}
				loading={loading}
			/>
		</ModalFormLayout>
	)
}

export default ExportHistoricalModal
