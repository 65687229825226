import ButtonComponent from 'components/elements/Button/Button'
import { DAILY_URL } from 'constants/routes'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './NotAllowed.scss'

const NotAllowed: FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<div className='allowed'>
			<div className='allowed-container'>
				<h1>{t('not_allowed.title')}</h1>
				<h3>{t('not_allowed.subtitle')}</h3>
				<p className='body1 allowed-description'>{t('not_allowed.description')}</p>
			</div>
			<ButtonComponent variant='text' onClick={() => navigate(DAILY_URL)}>
				{t('not_allowed.action')}
			</ButtonComponent>
		</div>
	)
}
export default NotAllowed
