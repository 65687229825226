import { MagnifyingGlassIcon, SteeringWheel, UserList } from 'assets/svgs'
import { AllUserRoles, UserRoles } from 'models/User'
import { FC } from 'react'
import { useConfigStore } from 'stores/useConfigStore'
import './RoleTag.scss'

interface Props {
	roleId: AllUserRoles
}

const RoleTag: FC<Props> = (props) => {
	const { roleId } = props
	const configState = useConfigStore()
	const role = configState.roles.byId[roleId]

	const icon = {
		[UserRoles.provider]: <UserList />,
		[UserRoles.driver]: <SteeringWheel />,
		[UserRoles.revisor]: <MagnifyingGlassIcon width={16} height={16} />,
		[UserRoles.admin]: null,
		[UserRoles['super-admin']]: null
	}

	return (
		<div className='role-tag'>
			<div className='role-tag-icon'>{icon[roleId]}</div>
			<span className='role-tag-name'>{role.name}</span>
		</div>
	)
}

export default RoleTag
