import { UserList } from 'assets/svgs'
import EmptyList from 'components/elements/EmptyList/EmptyList'
import Search from 'components/elements/Search/Search'
import TabContainer from 'components/elements/TabContainer/TabContainer'
import { StatusTabs } from 'models/Elements'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './GenericList.scss'

interface Props {
	searchTerm: string
	hasItems: boolean
	hasResults: boolean
	tabSelected?: keyof typeof StatusTabs
	setTab?: (tab: StatusTabs) => void
	setSearchTerm: (searchTerm: string) => void
	noItemsText: JSX.Element
	noResultsText: JSX.Element
	searchPlaceholder: string
	children: JSX.Element
	firstTabName?: string
	secondTabName?: string
	innerHeader?: ReactNode
}

const GenericList = (props: Props) => {
	const {
		searchTerm,
		hasItems,
		hasResults,
		setSearchTerm,
		tabSelected,
		setTab,
		noItemsText,
		noResultsText,
		searchPlaceholder,
		children,
		firstTabName,
		secondTabName,
		innerHeader
	} = props

	const { t } = useTranslation()

	const isEmpty = !hasResults && !searchTerm
	const hasTabs = tabSelected && setTab

	const tabs = [
		{
			id: 0,
			label: firstTabName ?? t('general.actives'),
			type: StatusTabs.active,
			onSelect: () => hasTabs && setTab(StatusTabs.active)
		},
		{
			id: 1,
			label: secondTabName ?? t('general.inactives'),
			type: StatusTabs.inactive,
			onSelect: () => hasTabs && setTab(StatusTabs.inactive)
		}
	]
	const getSelectedTab = () => {
		return tabs.find((tab) => tab.type === tabSelected)?.id as number
	}

	return (
		<div
			className={`generic-list-container${!hasResults || !searchTerm ? ' is-empty' : emptyString}`}>
			{isEmpty && !hasItems ? null : (
				<div className='generic-list-wrapper'>
					<div className='generic-list-search'>
						<Search
							placeholder={searchPlaceholder}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
					</div>
					{hasTabs ? (
						<TabContainer
							tabs={tabs}
							onTabChange={(tabId) => tabs[tabId].onSelect()}
							selectedTab={getSelectedTab()}
						/>
					) : null}
				</div>
			)}

			<div className='generic-list-content-area'>
				{innerHeader && <header className='generic-list-content-area-header'>{innerHeader}</header>}
				{children}
			</div>

			{!hasResults && searchTerm && <EmptyList icon={<UserList />}>{noResultsText}</EmptyList>}
			{isEmpty && <EmptyList icon={<UserList />}>{noItemsText}</EmptyList>}
		</div>
	)
}

export default GenericList
