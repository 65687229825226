import { FunnelIcon, ListIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import DailyTransferCard from 'components/elements/DailyTransferCard/DailyTransferCard'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import EmptyList from 'components/elements/EmptyList/EmptyList'
import HeaderWithNotificationBell from 'components/elements/HeaderWithNotificationBell/HeaderWithNotificationBell'
import SelectDateRangeOptions from 'components/elements/SelectOptions/SelectDateRangeOptions'
import TabContainer from 'components/elements/TabContainer/TabContainer'
import { CURRENCY_SYMBOL } from 'constants/common'
import { useModal } from 'hooks/useModal'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import { TransferFilter } from 'models/Transfer'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTransfersStore } from 'stores/useTransfersStore'
import { amountOfTransfersTabId, emptyString, firstRangeOfFilter } from 'utils/common'
import { currentUserIsAdmin } from 'utils/currentUser'
import { getDateRange } from 'utils/dateUtils'
import { formatNumberSpanishStyle } from 'utils/functions'
import ExportHistoricalModal from './ExportHistoricalModal'
import FilterCostsModal from './FilterCostsModal'
import './Historical.scss'

const requiredAsterisk = '(*)'

const HistoricalPage: React.FC = () => {
	const { t } = useTranslation()
	const [selectedTab, setSelectedTab] = useState(amountOfTransfersTabId)
	const [filterCostsModalIsVisible, toggleFilterCostsModalVisibility] = useModal()
	const [exportModalIsVisible, toggleExportModalVisibility] = useModal()
	const { startDate, endDate } = getDateRange(firstRangeOfFilter)
	const [filter, setFilter] = useState<TransferFilter>({ startDate, endDate })
	const transfersState = useTransfersStore()
	const isAdmin = currentUserIsAdmin()

	useEffect(() => {
		transfersState.fetchHistoricalTransfers(filter)
	}, [filter])

	const handleTabChange = (tabId: number) => {
		setSelectedTab(tabId)
	}

	const tabs = [
		{
			id: 0,
			label: t('historical.amounts')
		},
		{
			id: 1,
			label: `${t('historical.transfers')} (${transfersState.historical.allIds.length})`
		}
	]

	const totals = [
		{
			title: `${t('historical.totals.prices')} ${
				transfersState && transfersState.historical.hidePrice ? requiredAsterisk : emptyString
			}`,
			value: `${formatNumberSpanishStyle(transfersState.historical.totalPrice)} ${CURRENCY_SYMBOL}`
		},
		{
			title: t('historical.totals.commissions'),
			value: `${formatNumberSpanishStyle(
				transfersState.historical.totalCommission
			)} ${CURRENCY_SYMBOL}`
		},
		{
			title: t('historical.totals.tolls'),
			value: `${formatNumberSpanishStyle(transfersState.historical.totalToll)} ${CURRENCY_SYMBOL}`
		}
	]

	const hasHistorical = transfersState.historical.allIds.length > 0

	return (
		<DashboardLayout>
			<div className='historical-header'>
				<HeaderWithNotificationBell title='Histórico' />
				<div className='historical-filter-buttons'>
					<SelectDateRangeOptions
						onSelect={(range) => {
							setFilter((prevFilter) => ({
								...prevFilter,
								...range
							}))
						}}
					/>
					<ButtonComponent
						className='historical-header-filter-button'
						leftIcon={<FunnelIcon />}
						onClick={toggleFilterCostsModalVisibility}>
						{t('historical.filter')}
					</ButtonComponent>
				</div>
			</div>
			<TabContainer tabs={tabs} onTabChange={handleTabChange} selectedTab={selectedTab} />
			<div className='historical-content'>
				{selectedTab === amountOfTransfersTabId ? (
					<>
						<p className='historical-content-description body2'>
							{t('historical.costs_description')}
						</p>
						<p className='body2'>
							{transfersState.historical.hidePrice ? (
								<small>
									<sup>{requiredAsterisk}</sup> {t('historical.hide_price_description')}
								</small>
							) : null}
						</p>
						{totals.map((cost, index) => (
							<DetailsCard key={index}>
								<DetailsCardItem title={cost.title}>{cost.value}</DetailsCardItem>
							</DetailsCard>
						))}
					</>
				) : null}

				{hasHistorical && selectedTab !== amountOfTransfersTabId
					? transfersState.historical.allIds.map((id) => <DailyTransferCard key={id} id={id} />)
					: null}

				{selectedTab !== amountOfTransfersTabId && !hasHistorical ? (
					<EmptyList icon={<ListIcon />}>{t('historical.empty_list')}</EmptyList>
				) : null}
			</div>
			{isAdmin ? (
				<>
					<div className='historical-export-button'>
						<ButtonComponent onClick={toggleExportModalVisibility} fullWidth>
							{t('historical.export_button')}
						</ButtonComponent>
					</div>
					<ExportHistoricalModal
						isVisible={exportModalIsVisible}
						toggleModalVisibility={toggleExportModalVisibility}
						filter={filter}
					/>
				</>
			) : null}
			<FilterCostsModal
				isVisible={filterCostsModalIsVisible}
				toggleModalVisibility={toggleFilterCostsModalVisibility}
				onSelect={(filter) => {
					setFilter((prevFilter) => ({
						...prevFilter,
						...filter
					}))
				}}
			/>
		</DashboardLayout>
	)
}

export default HistoricalPage
