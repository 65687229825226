import { CrossIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import GenericList from 'components/elements/GenericList/GenericList'
import ListItem from 'components/elements/List/ListItem/ListItem'
import Loading from 'components/elements/Loading/Loading'
import {
	ERROR_URL,
	FRIENDS_ASSOCIATION_URL,
	FRIENDS_SEARCH_URL,
	RESOURCES_URL
} from 'constants/routes'
import useFriends from 'hooks/useFriends'
import useSearch from 'hooks/useSearch'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { FriendTab, RequestTypeTab, StatusTabs } from 'models/Elements'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { emptyString, zeroElements } from 'utils/common'
import './FriendList.scss'

const FriendList = () => {
	const { friendTab, requestType } = useParams()
	const {
		friends,
		requestsSent,
		requestReceived,
		rejectReceivedRequest,
		loadingRejectReceivedRequest,
		rejectSentRequest,
		loadingRejectSentRequest,
		loading,
		error
	} = useFriends()

	const [tab, setTab] = useState(
		friendTab === FriendTab.friends ? StatusTabs.inactive : StatusTabs.active
	)
	const [requestTab, setRequestTab] = useState(requestType ?? RequestTypeTab.received)
	const [searchTerm, setSearchTerm] = useState(emptyString)
	const hasItems =
		friends.length > zeroElements ||
		requestsSent.length > zeroElements ||
		requestReceived.length > zeroElements

	const navigate = useNavigate()
	const { t } = useTranslation()

	const isFriendsTabSelected = useMemo(() => tab === StatusTabs.active, [tab])

	const isRequestsSentTabSelected = useMemo(() => {
		return tab === StatusTabs.inactive && RequestTypeTab.sent === requestTab
	}, [tab, requestTab])

	const isRequestsReceivedTabSelected = useMemo(() => {
		return requestTab === RequestTypeTab.received && tab === StatusTabs.inactive
	}, [tab, requestTab])

	const filterFriends = useMemo(() => {
		if (isFriendsTabSelected) {
			return friends
		}
		if (isRequestsSentTabSelected) {
			return requestsSent
		}
		if (isRequestsReceivedTabSelected) {
			return requestReceived
		}
		return friends
	}, [friends, tab, requestTab, requestsSent, requestReceived])

	const searchKey = useMemo(() => {
		if (isFriendsTabSelected) {
			return 'receivingCompanyName'
		}
		if (isRequestsSentTabSelected) {
			return 'receivingCompanyName'
		}
		if (isRequestsReceivedTabSelected) {
			return 'sendingCompanyName'
		}
		return 'receivingCompanyName'
	}, [isFriendsTabSelected, isRequestsSentTabSelected, isRequestsReceivedTabSelected])

	const searchResults = useSearch(filterFriends, [searchKey], searchTerm)

	useEffect(() => {
		setSearchTerm(emptyString)
	}, [tab, requestTab])

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: RESOURCES_URL } })

	return (
		<FormLayout
			title={t('friend.list_title')}
			leftButton={<ButtonBack url={RESOURCES_URL} />}
			rightButton={
				<ButtonComponent onClick={() => navigate(FRIENDS_SEARCH_URL)} variant='only-text'>
					<strong>{t('general.add')}</strong>
				</ButtonComponent>
			}>
			<GenericList
				hasItems={hasItems}
				searchTerm={searchTerm}
				hasResults={!!searchResults.length}
				setSearchTerm={setSearchTerm}
				tabSelected={tab}
				setTab={setTab}
				firstTabName={t('friend.list_title')}
				secondTabName={t('friend.requests')}
				innerHeader={
					!isFriendsTabSelected && (
						<div className='friend-list-tabs-header'>
							<ButtonComponent
								className={`friend-list-switch-button ${
									isRequestsReceivedTabSelected ? ' active' : emptyString
								}`}
								onClick={() => setRequestTab(RequestTypeTab.received)}>
								{t('friend.received')}
							</ButtonComponent>
							<ButtonComponent
								className={`friend-list-switch-button ${
									isRequestsSentTabSelected ? ' active' : emptyString
								}`}
								onClick={() => setRequestTab(RequestTypeTab.sent)}>
								{t('friend.sent')}
							</ButtonComponent>
						</div>
					)
				}
				noItemsText={
					<Trans
						i18nKey='friend.no_items_text'
						components={{
							span: <span />
						}}
					/>
				}
				noResultsText={
					<Trans
						i18nKey='friend.no_results_text'
						components={{
							span: <span />
						}}
						values={{ query: searchTerm }}
					/>
				}
				searchPlaceholder={t('friend.search_placeholder')}>
				<>
					{searchResults.map((friend) => {
						if (!friend) return null
						return (
							<ListItem
								key={friend.id}
								onClick={() => null}
								right={
									<>
										{isRequestsReceivedTabSelected ? (
											<div className='friend-list-request-item-right'>
												<ButtonComponent
													onClick={() => rejectReceivedRequest(friend.id)}
													loading={loadingRejectReceivedRequest}
													dataTest='reject-request'
													variant='only-icon'>
													<CrossIcon />
												</ButtonComponent>
												<ButtonComponent
													className='friend-list-request-item-button'
													loading={loadingRejectReceivedRequest}
													onClick={() =>
														navigate(FRIENDS_ASSOCIATION_URL, {
															state: { type: 'receive', requestId: friend.id }
														})
													}>
													{t('general.accept')}
												</ButtonComponent>
											</div>
										) : null}
										{isRequestsSentTabSelected ? (
											<div className='friend-list-request-item-right'>
												<ButtonComponent
													onClick={() => rejectSentRequest(friend.id)}
													loading={loadingRejectSentRequest}
													dataTest='reject-request'
													variant='only-icon'>
													<CrossIcon />
												</ButtonComponent>
												<div className='friend-list-request-item-tag'>
													{t('friend.pending_acceptance')}
												</div>
											</div>
										) : null}
									</>
								}>
								{isRequestsSentTabSelected ? (
									<span className='friend-list-item-title'>{friend.receivingCompanyName}</span>
								) : null}

								{isFriendsTabSelected ? (
									<div className='friend-list-item'>
										<span className='friend-list-item-title'>{friend.receivingCompanyName}</span>
										<span className='friend-list-item-company'>{friend.sendingProviderName}</span>
									</div>
								) : null}

								{isRequestsReceivedTabSelected ? (
									<span className='friend-list-item-title'>{friend.sendingCompanyName}</span>
								) : null}
							</ListItem>
						)
					})}
				</>
			</GenericList>
		</FormLayout>
	)
}
export default FriendList
