import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Loading from 'components/elements/Loading/Loading'
import ProfileImageInput from 'components/elements/ProfileImageInput/ProfileImageInput'
import { MY_COMPANY_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { CompanyForm } from 'models/Company'
import { DocumentType } from 'models/Provider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { useCompanyStore } from 'stores/useCompanyStore'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString } from 'utils/common'
import * as yup from 'yup'
import './MyCompany.scss'
import MyCompanyInputs from './MyCompanyInputs'

const MyCompanyEdit: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const companyState = useCompanyStore()
	const configStore = useConfigStore()
	const [loading, setLoading] = useState(false)

	const formik = useFormik<CompanyForm>({
		initialValues: {
			name: emptyString,
			document: emptyString,
			documentTypeId: null,
			mainEmail: emptyString,
			phone: emptyString,
			address: emptyString,
			provinceId: emptyString,
			cityId: emptyString,
			zipCode: emptyString,
			website: emptyString
		},
		validationSchema: yup.object().shape({
			name: yup
				.string()
				.required(
					t('errors.required_m', { field: t('onboarding.first_steps.label.company_name') })
				),
			document: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.nif') })),
			documentTypeId: yup
				.string()
				.required(t('errors.required_m', { field: t('general.document_type') })),
			mainEmail: yup
				.string()
				.email(t('errors.mail_not_valid'))
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.email') })),
			phone: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.telephone') })),
			address: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.address') })),
			provinceId: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.province') })),
			cityId: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.city') })),
			zipCode: yup
				.string()
				.required(t('errors.required_m', { field: t('onboarding.first_steps.label.post_code') }))
		}),
		onSubmit: async (values: CompanyForm) => {
			try {
				setLoading(true)
				const provinceCode = values.provinceId
					? configStore.provinces.byId[parseInt(values.provinceId)]?.code
					: emptyString
				const cityCode = values.cityId
					? configStore.municipalities.byId[parseInt(values.cityId)]?.municipalityCode
					: emptyString
				const documentType = values.documentTypeId
					? configStore.documentTypes.byId[values.documentTypeId]?.name
					: DocumentType.CIF

				const editedCompany = {
					...values,
					documentType: documentType,
					province: provinceCode,
					city: cityCode
				}
				await companyState.updateCompany(editedCompany)
				toast.success(t('toast.success.description'))
				navigate(MY_COMPANY_URL)
			} catch (error) {
				console.error({ error })
				toast.error(t('toast.error.description'))
			} finally {
				setLoading(false)
			}
		}
	})

	useEffect(() => {
		const documentTypeId = configStore.getDocumentTypeByClass(companyState.company.documentType)?.id
		const provinceId = configStore.getProvinceByCode(companyState.company.province)?.id
		const cityId = configStore.getMunicipalityByProvinceCodeAndMunicipalityCode(
			companyState.company.province,
			companyState.company.city
		)?.id

		formik.setValues({
			name: companyState.company.name,
			document: companyState.company.document,
			documentTypeId: documentTypeId,
			mainEmail: companyState.company.mainEmail,
			phone: companyState.company.phone,
			address: companyState.company.address,
			provinceId: provinceId ? provinceId.toString() : null,
			cityId: cityId ? cityId.toString() : null,
			zipCode: companyState.company.zipCode,
			website: companyState.company.website
		})
	}, [])

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	if (loading) return <Loading fullscreen />

	return (
		<FormLayout
			title={t('settings.my_account.my_company.title')}
			leftButton={<ButtonBack url={MY_COMPANY_URL} />}
			rightButton={
				<ButtonComponent onClick={handleSubmit} variant='only-text'>
					{t('general.save')}
				</ButtonComponent>
			}>
			<div className='my-company'>
				<div className='my-company-profile-image'>
					<ProfileImageInput />
				</div>
				<FormSectionLayout>
					<MyCompanyInputs formik={formik} setFieldRef={setFieldRef} />
				</FormSectionLayout>
			</div>
		</FormLayout>
	)
}

export default MyCompanyEdit
