import {
	AnulatedFomento,
	CommunicatedFomento,
	ConfirmedFomento,
	NotCommunicatedFomento
} from 'assets/svgs'
import { type Fomento } from 'models/Fomento'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './CategoryTag.scss'

interface Props {
	fomento?: Fomento | null
}

interface StatusFomento {
	text: string
	iconSvg: React.ReactNode
}

const FomentoCategoryTag: React.FC<Props> = (props) => {
	const { fomento } = props
	const { t } = useTranslation()

	const [status, setStatus] = useState<StatusFomento>({
		text: t('fomento.without_communicating'),
		iconSvg: <NotCommunicatedFomento />
	})

	useEffect(() => {
		if (!fomento) return
		const status = getStatus(fomento)
		setStatus(status)
	}, [fomento])

	const getStatus = (fomento: Fomento): StatusFomento => {
		if (!fomento) {
			return { text: t('fomento.without_communicating'), iconSvg: <NotCommunicatedFomento /> }
		}

		if (fomento.confirmedAt) {
			return {
				text: t('fomento.confirmed'),
				iconSvg: <ConfirmedFomento />
			}
		} else if (fomento.cancelledAt) {
			return {
				text: t('fomento.annulled'),
				iconSvg: <AnulatedFomento />
			}
		} else if (fomento.scheduledAt) {
			return {
				text: t('fomento.communicated'),
				iconSvg: <CommunicatedFomento />
			}
		} else {
			return {
				text: t('fomento.without_communicating'),
				iconSvg: <NotCommunicatedFomento />
			}
		}
	}

	return (
		<div className='fomento-category-tag'>
			{status.iconSvg}
			{status.text}
		</div>
	)
}

export default FomentoCategoryTag
