import { CURRENCY_SYMBOL } from 'constants/common'
import './SubscriptionCardContent.scss'

interface Props {
	title: string
	price: number
	details: string[]
}

const SubscriptionCardContent: React.FC<Props> = (props) => {
	const { title, price, details } = props

	return (
		<div className='subscription-card-content'>
			<h2 className='subscription-card-content-title'>{title}</h2>
			<span className='subscription-card-content-price'>
				{price}
				{CURRENCY_SYMBOL}
			</span>
			{details.map((detail, index) => (
				<span key={index} className='subscription-card-content-detail'>
					{detail}
				</span>
			))}
		</div>
	)
}

export default SubscriptionCardContent
