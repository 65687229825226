import { Provider } from 'models/Provider'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProvidersStore } from 'stores/useProvidersStore'

const useProviders = (providerId?: number) => {
	const providerState = useProvidersStore((state) => state)
	const [provider, setProvider] = useState<Provider | null>(null)
	const [providers, setProviders] = useState<Provider[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<Nullable<string>>(null)
	const { t } = useTranslation()

	useEffect(() => {
		if (providerId !== undefined) {
			fetchProvider(providerId)
		} else {
			fetchProviders()
		}
	}, [providerId])

	useEffect(() => {
		fetchAssociations()
	}, [])

	useEffect(() => {
		setProviders(providerState.providers.allIds.map((id) => providerState.providers.byId[id]))
	}, [providerState.providers])

	const fetchAssociations = async () => {
		await providerState.fetchAllProviderAssociations()
	}

	const fetchProvider = useCallback(
		async (providerId: number) => {
			setLoading(true)
			setError(null)

			try {
				let fetchedProvider = providerState.providers.byId[providerId]
				if (!fetchedProvider) {
					fetchedProvider = await providerState.fetchProviderById(providerId)
				}
				setProvider(fetchedProvider)
				setProviders([])
			} catch (e) {
				setError(t('provider.provider_error'))
				console.error(e)
			} finally {
				setLoading(false)
			}
		},
		[providerId, providerState, t]
	)

	const fetchProviders = async () => {
		setLoading(true)
		setError(null)

		try {
			let fetchedProviders = providerState.providers.allIds.map(
				(id) => providerState.providers.byId[id]
			)
			if (fetchedProviders.length === 0) {
				fetchedProviders = await providerState.fetchProviders()
			}
			setProvider(null)
		} catch (e) {
			setError(t('provider.providers_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const hasAssociations = (providerId: number) => {
		let hasAssociation = false

		providerState.providersAssociations.allIds.forEach((id) => {
			const association = providerState.providersAssociations.byId[id]
			if (association.providerId === providerId) {
				hasAssociation = true
			}
		})
		return hasAssociation
	}

	const removeProvider = async (providerId: number) => {
		setLoading(true)
		setError(null)

		try {
			await providerState.removeProvider(providerId)
		} catch (e) {
			setError(t('provider.delete_provider_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const activateProvider = async (providerId: number) => {
		try {
			await providerState.updateProvider({ isActive: true }, providerId)
		} catch (e) {
			setError(t('provider.activate_provider_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const deactivateProvider = async (providerId: number) => {
		try {
			await providerState.updateProvider({ isActive: false }, providerId)
		} catch (e) {
			setError(t('provider.deactivate_provider_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const getAssociationsByUserId = (userId: number) => {
		return providerState.getAssociationsByUserId(userId)
	}

	const getProviderByIdFromStore = (providerId: number) => {
		return providerState.providers.byId[providerId]
	}

	return useMemo(
		() => ({
			provider,
			providers,
			loading,
			error,
			hasAssociations,
			removeProvider,
			activateProvider,
			deactivateProvider,
			getAssociationsByUserId,
			getProviderByIdFromStore
		}),
		[provider, providers, loading, error, providerState]
	)
}

export default useProviders
