import { CloseIcon, UserCirclePlusIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import { DRIVERS_LIST_URL, USER_TYPE_DRIVER_REGISTER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import { useModal } from 'hooks/useModal'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { Driver, DriverForm, DriverPost } from 'models/Driver'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { useDriversStore } from 'stores/useDriversStore'
import { emptyString, zeroAmount } from 'utils/common'
import * as Yup from 'yup'
import DriverInputs from './DriverInputs'

const DriverNew = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const driverStore = useDriversStore()
	const [modalIsVisible, toggleModalVisibility] = useModal()
	const [driverCreated, setDriverCreated] = useState<Nullable<Driver>>(null)
	const [loading, setLoading] = useState(false)

	const formik = useFormik<DriverForm>({
		initialValues: {
			name: emptyString,
			email: emptyString,
			changeTransferEmail: true,
			communicationEmail: true,
			confirmationEmail: true,
			isActive: true,
			profit: undefined
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required(t('errors.required_m', { field: t('driver.name') })),
			email: Yup.string().email(t('errors.mail_not_valid'))
		}),
		onSubmit: async (values) => {
			const driver: DriverPost = {
				name: values.name,
				email: values.email,
				changeTransferEmail: values.changeTransferEmail,
				communicationEmail: values.communicationEmail,
				confirmationEmail: values.confirmationEmail,
				isActive: values.isActive,
				profit: values.profit ?? zeroAmount
			}

			try {
				setLoading(true)
				const driverCreated = await driverStore.createDriver(driver)
				setDriverCreated(driverCreated)
				toast.success(t('driver.success_create'))

				toggleModalVisibility()
			} catch (error) {
				toast.error(t('driver.error_create'))
			} finally {
				setLoading(false)
			}
		}
	})

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	return (
		<FormLayout
			title={t('driver.new_title')}
			rightButton={
				<ButtonComponent
					onClick={() => navigate(DRIVERS_LIST_URL)}
					variant='only-icon'
					icon={<CloseIcon />}
				/>
			}
			footer={
				<ButtonComponent type='submit' onClick={handleSubmit} fullWidth loading={loading}>
					{t('driver.save_new')}
				</ButtonComponent>
			}>
			<DriverInputs formik={formik} setFieldRef={setFieldRef} />

			<ModalActions
				icon={<UserCirclePlusIcon />}
				title={t('driver.access_confirmation_modal.title')}
				description={t('driver.access_confirmation_modal.description')}
				primaryButton={{
					text: t('driver.access_confirmation_modal.primary_button'),
					onClick: () => navigate(`${USER_TYPE_DRIVER_REGISTER_URL}/${driverCreated?.id}`)
				}}
				secondaryButton={{
					text: t('driver.access_confirmation_modal.secondary_button'),
					onClick: () => navigate(DRIVERS_LIST_URL)
				}}
				isVisible={modalIsVisible}
				onClose={toggleModalVisibility}
			/>
		</FormLayout>
	)
}

export default DriverNew
