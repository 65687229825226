import { ArrowSquareOutIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { FC } from 'react'

const googleMapsSearchUrl = 'https://www.google.com/maps/search/?api=1&query='

interface Props {
	address: string
}

const MapLink: FC<Props> = (props) => {
	const { address } = props

	const encodeAddressForURL = (address: string) => {
		const encodedAddress = encodeURIComponent(address)
		return `${googleMapsSearchUrl}${encodedAddress}`
	}

	const handleOpenMap = () => {
		const mapURL = encodeAddressForURL(address)
		window.open(mapURL, '_blank')
	}

	return (
		<ButtonComponent role='link' variant='only-icon' onClick={handleOpenMap}>
			<ArrowSquareOutIcon />
		</ButtonComponent>
	)
}

export default MapLink
