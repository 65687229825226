import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CheckCircle: FC<Props> = (props) => {
	const { width = 54, height = 54 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 54 54'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='CheckCircle'>
				<path
					id='Vector'
					d='M27 5.0625C22.6612 5.0625 18.4198 6.34911 14.8122 8.75964C11.2046 11.1702 8.3928 14.5963 6.7324 18.6049C5.07201 22.6134 4.63757 27.0243 5.48403 31.2798C6.3305 35.5353 8.41984 39.4441 11.4879 42.5122C14.5559 45.5802 18.4648 47.6695 22.7202 48.516C26.9757 49.3624 31.3866 48.928 35.3951 47.2676C39.4037 45.6072 42.8298 42.7954 45.2404 39.1878C47.6509 35.5802 48.9375 31.3388 48.9375 27C48.9314 21.1837 46.6181 15.6074 42.5054 11.4946C38.3926 7.38188 32.8163 5.06864 27 5.0625ZM36.6314 23.1314L24.8189 34.9439C24.6622 35.1008 24.4761 35.2253 24.2712 35.3102C24.0664 35.3951 23.8468 35.4388 23.625 35.4388C23.4032 35.4388 23.1837 35.3951 22.9788 35.3102C22.7739 35.2253 22.5878 35.1008 22.4311 34.9439L17.3686 29.8814C17.052 29.5648 16.8741 29.1353 16.8741 28.6875C16.8741 28.2397 17.052 27.8102 17.3686 27.4936C17.6852 27.177 18.1147 26.9991 18.5625 26.9991C19.0103 26.9991 19.4398 27.177 19.7564 27.4936L23.625 31.3643L34.2436 20.7436C34.4004 20.5868 34.5865 20.4624 34.7914 20.3776C34.9962 20.2927 35.2158 20.2491 35.4375 20.2491C35.6592 20.2491 35.8788 20.2927 36.0836 20.3776C36.2885 20.4624 36.4746 20.5868 36.6314 20.7436C36.7882 20.9004 36.9126 21.0865 36.9974 21.2914C37.0823 21.4962 37.1259 21.7158 37.1259 21.9375C37.1259 22.1592 37.0823 22.3788 36.9974 22.5836C36.9126 22.7885 36.7882 22.9746 36.6314 23.1314Z'
					fill='#00E062'
				/>
			</g>
		</svg>
	)
}

export default CheckCircle
