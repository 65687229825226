import { ENVIRONMENT } from 'constants/environment'
import {
	TransferFilter,
	type StartEndTransfer,
	type Transfer,
	type TransferPost
} from 'models/Transfer'
import { normalize } from 'normalizr'
import { ErrorResponse } from 'react-router-dom'
import { startEndTransferSchema, transferSchema } from 'schemas/index'
import {
	createTransferService,
	deleteTransferService,
	fetchHistoricalTransfersService,
	getTransferDetailsByIdService,
	getTransfersByDateService,
	updateTransferService
} from 'services/transfers'
import {
	endTransferService,
	getStartEndTransfersByDateService,
	getStartEndTransfersByTransferIdService,
	startTransferService
} from 'services/transfersStartEnd'

import { zeroAmount } from 'utils/common'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { useDriversStore, useProvidersStore, useUserStore, useVehiclesStore } from '.'

interface State {
	actualTransferCreation: Partial<TransferPost>
	transfers: {
		byId: { [id: number]: Transfer }
		allIds: number[]
	}
	startEndTransfers: {
		byId: { [id: number]: StartEndTransfer }
		allIds: number[]
	}
	historical: {
		byId: { [id: number]: Transfer }
		allIds: number[]
		totalPrice: number
		totalCommission: number
		totalToll: number
		hidePrice: boolean
	}
	createTransfer: (transfer: Partial<TransferPost>) => Promise<Transfer>
	updateTransfer: (id: number, transfer: Partial<TransferPost>) => Promise<Transfer>
	addTransfer: (transfer: Transfer) => void
	fetchTransfersByDate: (date: string) => Promise<Transfer[]>
	fetchTransferDetailsById: (id: number) => Promise<void>
	fetchStartEndTransfersByDate: (date: string) => Promise<StartEndTransfer[]>
	fetchStartEndTransfersByBookingId: (bookingId: number) => Promise<StartEndTransfer[]>
	fetchHistoricalTransfers: (filter: TransferFilter) => Promise<Transfer[]>
	getStartEndTransfersByBookingId: (bookingId: number) => StartEndTransfer[]
	startTransfer: (transferId: number) => Promise<StartEndTransfer>
	endTransfer: (transferId: number) => Promise<StartEndTransfer>
	resetTransfers: () => void
	resetAllTransferInfo: () => void
	updateActualTransferCreation: (transfer: Partial<TransferPost>) => void
	deleteTransfer: (transferId: number) => Promise<void>
}

export const useTransfersStore = create<State>()(
	devtools(
		(set, get) => ({
			actualTransferCreation: {} as Partial<TransferPost>,
			transfers: {
				byId: {},
				allIds: [] as number[]
			},
			startEndTransfers: {
				byId: {},
				allIds: [] as number[]
			},
			historical: {
				byId: {},
				allIds: [] as number[],
				totalPrice: zeroAmount,
				totalCommission: zeroAmount,
				totalToll: zeroAmount,
				hidePrice: false as boolean
			},
			createTransfer: async (transfer) => {
				const createdTransfer = await createTransferService(transfer)
				set((state) => ({
					transfers: {
						...state.transfers,
						byId: {
							...state.transfers.byId,
							[createdTransfer.id]: createdTransfer
						},
						allIds: [...state.transfers.allIds, createdTransfer.id]
					}
				}))
				return createdTransfer
			},
			updateTransfer: async (id, transfer) => {
				const updatedTransfer = await updateTransferService(id, transfer)
				set((state) => {
					const idExists = state.transfers.allIds.includes(updatedTransfer.id)

					return {
						transfers: {
							byId: {
								...state.transfers.byId,
								[updatedTransfer.id]: updatedTransfer
							},
							allIds: idExists
								? state.transfers.allIds
								: [...state.transfers.allIds, updatedTransfer.id]
						}
					}
				})
				return updatedTransfer
			},
			addTransfer: (transfer) => {
				set((state) => ({
					transfers: {
						...state.transfers,
						byId: {
							...state.transfers.byId,
							[transfer.id]: transfer
						},
						allIds: [...state.transfers.allIds, transfer.id]
					}
				}))
			},
			fetchTransfersByDate: async (date) => {
				const transfers = await getTransfersByDateService(date)

				const drivers = useDriversStore.getState().drivers
				const providers = useProvidersStore.getState().providers
				const vehicles = useVehiclesStore.getState().vehicles
				const users = useUserStore.getState().users

				const combinedData = {
					transfers,
					drivers: drivers,
					providers: providers,
					vehicles: vehicles,
					users: users
				}

				const normalizedData = normalize(combinedData, {
					transfers: [transferSchema]
				})

				set(
					(state) => ({
						transfers: {
							byId: {
								...state.transfers.byId,
								...normalizedData.entities.transfers
							},
							allIds: Array.from(
								new Set([...state.transfers.allIds, ...normalizedData.result.transfers])
							)
						}
					}),
					false,
					'FETCH_RESERVATIONS'
				)
				return transfers
			},
			fetchTransferDetailsById: async (id) => {
				if (get().transfers.byId[id]) {
					return
				}

				try {
					const transfer = await getTransferDetailsByIdService(id)
					set((state) => {
						const newById = { ...state.transfers.byId, [id]: transfer }
						const newAllIds = state.transfers.allIds.includes(id)
							? state.transfers.allIds
							: [...state.transfers.allIds, id]
						return {
							...state,
							transfers: {
								byId: newById,
								allIds: newAllIds
							}
						}
					})
				} catch (err) {
					const error = err as ErrorResponse
					const errorCode = error.status

					if (errorCode === 403) {
						window.location.href = '/403'
					}

					if (errorCode === 404) {
						window.location.href = '/404'
					}
				}
			},
			fetchStartEndTransfersByDate: async (date) => {
				const startEndTransferData = await getStartEndTransfersByDateService(date)
				const normalizedData = normalize(startEndTransferData, [startEndTransferSchema])

				set(
					(state) => ({
						startEndTransfers: {
							byId: {
								...state.startEndTransfers.byId,
								...normalizedData.entities.startEndTransfer
							},
							allIds: normalizedData.result
						}
					}),
					false,
					'FETCH_START_END_RESERVATIONS'
				)

				return startEndTransferData
			},
			fetchStartEndTransfersByBookingId: async (bookingId) => {
				const startEndTransferData = await getStartEndTransfersByTransferIdService(bookingId)
				const normalizedData = normalize(startEndTransferData, [startEndTransferSchema])

				set(
					(state) => ({
						startEndTransfers: {
							byId: {
								...state.startEndTransfers.byId,
								...normalizedData.entities.startEndTransfer
							},
							allIds: normalizedData.result
						}
					}),
					false,
					'FETCH_START_END_RESERVATIONS'
				)

				return startEndTransferData
			},
			fetchHistoricalTransfers: async (filter) => {
				const { transfers, totalCommission, totalPrice, totalToll, hidePrice } =
					await fetchHistoricalTransfersService(filter)
				const normalizedData = normalize(transfers, [transferSchema])
				set({
					historical: {
						byId: { ...normalizedData.entities.transfers },
						allIds: normalizedData.result,
						totalCommission,
						totalPrice,
						totalToll,
						hidePrice
					}
				})
				set(
					(state) => ({
						transfers: {
							byId: {
								...state.transfers.byId,
								...normalizedData.entities.transfers
							},
							allIds: Array.from(new Set([...state.transfers.allIds, ...normalizedData.result]))
						}
					}),
					false,
					'FETCH_RESERVATIONS'
				)
				return transfers
			},
			getStartEndTransfersByBookingId: (bookingId: number) => {
				const startEndTransfers = get().startEndTransfers.byId
				const startEndTransfersFounded = Object.values(startEndTransfers).filter(
					(startEndTransfer) => startEndTransfer.bookingId === bookingId
				)
				return startEndTransfersFounded
			},
			startTransfer: async (bookingId) => {
				const startEndTransfer = await startTransferService(bookingId)
				const normalizedData = normalize(startEndTransfer, startEndTransferSchema)

				set(
					(state) => ({
						startEndTransfers: {
							byId: {
								...state.startEndTransfers.byId,
								...normalizedData.entities.startEndTransfer
							},
							allIds: [...state.startEndTransfers.allIds, normalizedData.result]
						}
					}),
					false,
					'START_TRANSFER'
				)

				return startEndTransfer
			},
			endTransfer: async (bookingId) => {
				const endTransfer = await endTransferService(bookingId)
				const normalizedData = normalize(endTransfer, startEndTransferSchema)

				set(
					(state) => ({
						startEndTransfers: {
							byId: {
								...state.startEndTransfers.byId,
								...normalizedData.entities.startEndTransfer
							},
							allIds: state.startEndTransfers.allIds
						}
					}),
					false,
					'END_TRANSFER'
				)

				return endTransfer
			},
			resetTransfers: () => {
				set(
					{
						transfers: {
							byId: {},
							allIds: []
						}
					},
					false,
					'RESET_RESERVATIONS'
				)
			},
			resetAllTransferInfo: () => {
				set(
					{
						actualTransferCreation: {},
						transfers: {
							byId: {},
							allIds: []
						},
						startEndTransfers: {
							byId: {},
							allIds: []
						},
						historical: {
							byId: {},
							allIds: [],
							totalPrice: zeroAmount,
							totalCommission: zeroAmount,
							totalToll: zeroAmount,
							hidePrice: false
						}
					},
					false,
					'RESET_ALL_TRANSFER_INFO'
				)
			},
			updateActualTransferCreation: (transfer) => {
				set(
					(state) => ({
						actualTransferCreation: {
							...state.actualTransferCreation,
							...transfer
						}
					}),
					false,
					'UPDATE_ACTUAL_TRANSFER_CREATION'
				)
			},
			deleteTransfer: async (transferId) => {
				await deleteTransferService(transferId)

				const { transfers } = get()
				const newTransferIds = transfers.allIds.filter((id) => id !== transferId)
				const newTransferById = {} as { [id: number]: Transfer }
				newTransferIds.forEach((id) => {
					newTransferById[id] = transfers.byId[transferId]
				})
				set(
					{
						transfers: {
							byId: { ...newTransferById },
							allIds: newTransferIds
						}
					},
					false,
					'DELETE_TRANSFER'
				)
			}
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'transfers store'
		}
	)
)
