import FomentoCategoryTag from 'components/elements/CategoryTag/FomentoCategoryTag'
import { Fomento } from 'models/Fomento'
import { useTranslation } from 'react-i18next'
import './HeaderTransferDetails.scss'

interface Props {
	driver?: string
	fomento?: Nullable<Fomento>
	date?: string
	time: string
	backgroundColor: string
}

const HeaderTransferDetails: React.FC<Props> = (props) => {
	const { driver, fomento, date, time, backgroundColor } = props

	const { t } = useTranslation()

	return (
		<header className='transfer-details-arrival-header' style={{ backgroundColor }}>
			{driver && (
				<div className='transfer-details-arrival-header-item driver'>
					<span className='body2'>{t('transfer.driver')}</span>
					<div className='transfer-details-arrival-header-item-value'>{driver}</div>
				</div>
			)}
			{fomento !== null && (
				<div className='transfer-details-arrival-header-item'>
					<span className='body2'>{t('transfer.status')}</span>
					<FomentoCategoryTag fomento={fomento} />
				</div>
			)}
			{date && (
				<div className='transfer-details-arrival-header-item'>
					<span className='body2'>{t('transfer.date')}</span>
					<div className='transfer-details-arrival-header-item-value'>{date}</div>
				</div>
			)}
			<div className='transfer-details-arrival-header-item'>
				<span className='body2'>{t('transfer.time')}</span>
				<div className='transfer-details-arrival-header-item-value'>{time}</div>
			</div>
		</header>
	)
}

export default HeaderTransferDetails
