import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const BellSimpleIcon: FC<Props> = (props) => {
	const { width = 21, height = 20 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='BellSimple'>
				<path
					id='Vector'
					opacity='0.2'
					d='M16.7501 15H4.25015C4.14076 14.9993 4.03345 14.97 3.93896 14.9149C3.84448 14.8597 3.76611 14.7808 3.7117 14.6859C3.65729 14.591 3.62874 14.4835 3.62891 14.3741C3.62907 14.2647 3.65795 14.1572 3.71265 14.0625C4.22749 13.1719 4.87515 10.9227 4.87515 8.125C4.87515 6.63316 5.46778 5.20242 6.52267 4.14752C7.57756 3.09263 9.0083 2.5 10.5001 2.5C11.992 2.5 13.4227 3.09263 14.4776 4.14752C15.5325 5.20242 16.1251 6.63316 16.1251 8.125C16.1251 10.9234 16.7736 13.1719 17.2892 14.0625C17.344 14.1574 17.3728 14.2649 17.3729 14.3745C17.373 14.484 17.3444 14.5916 17.2898 14.6865C17.2352 14.7815 17.1566 14.8604 17.0619 14.9154C16.9672 14.9705 16.8597 14.9996 16.7501 15Z'
					fill='#9A76FF'
				/>
				<path
					id='Vector_2'
					d='M13.6248 17.5C13.6248 17.6658 13.5589 17.8247 13.4417 17.9419C13.3245 18.0592 13.1655 18.125 12.9998 18.125H7.99978C7.83402 18.125 7.67505 18.0592 7.55784 17.9419C7.44063 17.8247 7.37478 17.6658 7.37478 17.5C7.37478 17.3342 7.44063 17.1753 7.55784 17.0581C7.67505 16.9408 7.83402 16.875 7.99978 16.875H12.9998C13.1655 16.875 13.3245 16.9408 13.4417 17.0581C13.5589 17.1753 13.6248 17.3342 13.6248 17.5ZM17.8318 15C17.7234 15.191 17.5659 15.3497 17.3757 15.4595C17.1855 15.5694 16.9694 15.6265 16.7498 15.625H4.24978C4.03004 15.6247 3.81427 15.5665 3.6242 15.4562C3.43413 15.346 3.27649 15.1875 3.16716 14.9969C3.05783 14.8063 3.00068 14.5903 3.00147 14.3705C3.00226 14.1508 3.06096 13.9351 3.17165 13.7453C3.60525 12.9984 4.24978 10.8859 4.24978 8.125C4.24978 6.4674 4.90826 4.87769 6.08036 3.70558C7.25246 2.53348 8.84218 1.875 10.4998 1.875C12.1574 1.875 13.7471 2.53348 14.9192 3.70558C16.0913 4.87769 16.7498 6.4674 16.7498 8.125C16.7498 10.8852 17.3951 12.9984 17.8287 13.7453C17.9405 13.9354 17.9996 14.1518 18 14.3723C18.0004 14.5929 17.9421 14.8095 17.831 15H17.8318ZM16.7498 14.375C16.1459 13.3383 15.4998 10.9414 15.4998 8.125C15.4998 6.79892 14.973 5.52715 14.0353 4.58947C13.0976 3.65178 11.8259 3.125 10.4998 3.125C9.1737 3.125 7.90193 3.65178 6.96424 4.58947C6.02656 5.52715 5.49978 6.79892 5.49978 8.125C5.49978 10.9422 4.8529 13.3391 4.24978 14.375H16.7498Z'
					fill='#9A76FF'
				/>
			</g>
		</svg>
	)
}

export default BellSimpleIcon
