import { ThemeProvider } from '@mui/material/styles'
import { WarningIcon } from 'assets/svgs'
import Loading from 'components/elements/Loading/Loading'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import { LOGOUT_URL } from 'constants/routes'
import useInitializeAppData from 'hooks/useInitializeAppData'
import { useModal } from 'hooks/useModal'
import useSupport from 'hooks/useSupport'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import { Toaster } from 'sonner'
import { useUserStore } from 'stores/index'
import { materialTheme } from './designSystem/materialTheme'
import { UserRoles } from './models'
import { RoutesContainer } from './routes/routes'

function AppContent() {
	const { getAllData, isLoading } = useInitializeAppData()
	const currentUser = useUserStore((state) => state.currentUser)
	const [isBlockedAccessModalVisible, toggleBlockedAccessModalVisibility] = useModal()
	const handleSupport = useSupport()
	const { t } = useTranslation()
	const navigate = useNavigate()

	useEffect(() => {
		if (currentUser.id) {
			getAllData()

			if (currentUser.userTypeId === UserRoles.driver) {
				if (!currentUser.allowedDrivers.length) {
					toggleBlockedAccessModalVisibility()
				}
			} else if (currentUser.userTypeId === UserRoles.provider) {
				if (!currentUser.allowedProviders.length) {
					toggleBlockedAccessModalVisibility()
				}
			}
		}
	}, [currentUser])

	if (isLoading && currentUser.id) {
		return <Loading fullscreen />
	}

	const onCloseBlockedAccessModal = () => {
		toggleBlockedAccessModalVisibility()
		navigate(LOGOUT_URL)
	}

	const onContactSupport = () => {
		onCloseBlockedAccessModal()
		handleSupport()
	}

	return (
		<div className='App'>
			<Toaster richColors />
			<RoutesContainer />
			<ModalActions
				isVisible={isBlockedAccessModalVisible}
				onClose={onCloseBlockedAccessModal}
				type='error'
				icon={<WarningIcon />}
				title={t('blocked_access_modal.title')}
				description={t('blocked_access_modal.description', {
					entity:
						currentUser.userTypeId === UserRoles.driver
							? t('general.driver').toLocaleLowerCase()
							: t('general.provider').toLocaleLowerCase()
				})}
				primaryButton={{
					text: t('blocked_access_modal.contact_support'),
					onClick: onContactSupport
				}}
				secondaryButton={{
					text: t('general.quit'),
					onClick: onCloseBlockedAccessModal
				}}
			/>
		</div>
	)
}

function App() {
	return (
		<ThemeProvider theme={materialTheme}>
			<BrowserRouter>
				<AppContent />
			</BrowserRouter>
		</ThemeProvider>
	)
}

export default App
