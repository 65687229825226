import { ENVIRONMENT } from 'constants/environment'
import { DriverAssociations, DriverPost, type Driver } from 'models/Driver'
import { normalize } from 'normalizr'
import { driverAssociationSchema, driverSchema } from 'schemas/index'
import {
	createDriverService,
	deleteAssociationsByDriverId,
	deleteDriverById,
	getAllDriversService,
	getDriverAssociations,
	getDriverById,
	resetAndSetAssociationsToDriver,
	updateDriverService
} from 'services/drivers'
import { createDriverAssociationsByUserService } from 'services/user'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface DriversState {
	drivers: {
		byId: { [id: number]: Driver }
		allIds: number[]
	}
	driversAssociations: {
		byId: { [id: number]: DriverAssociations }
		allIds: number[]
	}
	addDriver: (driver: Driver) => void
	removeDriver: (driverId: number) => Promise<void>
	removeDriverAssociations: (driverId: number) => void
	fetchDrivers: () => Promise<Driver[]>
	fetchDriverById: (id: number) => Promise<Driver>
	resetDrivers: () => void
	resetAllDriverInfo: () => void
	createDriver: (driver: DriverPost) => Promise<Driver>
	updateDriver: (driver: Partial<DriverPost>, driverId: number) => Promise<Driver>
	fetchAllDriverAssociations: () => Promise<DriverAssociations[]>
	getAssociationsByDriverId: (driverId: number) => DriverAssociations[]
	getAssociationsByUserId: (driverId: number) => DriverAssociations[]
	createAssociationsByUserId: (userId: number, driverIds: number[]) => Promise<DriverAssociations[]>
	resetAndSetAssociationsToDriver: (
		driverId: number,
		associations: number[]
	) => Promise<DriverAssociations[]>
	deleteAssociationsByDriverId: (driverId: number) => Promise<void>
}

export const useDriversStore = create<DriversState>()(
	devtools(
		(set, get) => ({
			drivers: {
				byId: {},
				allIds: [] as number[]
			},
			driversAssociations: {
				byId: {},
				allIds: [] as number[]
			},
			addDriver: (driver) => {
				const normalizedDriver = normalize(driver, driverSchema)
				set((state) => ({
					drivers: {
						byId: { ...state.drivers.byId, ...normalizedDriver.entities.drivers },
						allIds: [...state.drivers.allIds, normalizedDriver.result]
					}
				}))
			},
			removeDriver: async (driverId) => {
				await deleteDriverById(driverId)

				set((state) => {
					const newDrivers: { [key: string]: Driver } = {
						...state.drivers.byId
					}
					Object.keys(newDrivers).forEach((key) => {
						if (newDrivers[key].id === driverId) {
							delete newDrivers[key]
						}
					})
					return {
						drivers: {
							byId: newDrivers,
							allIds: Object.keys(newDrivers).map((key) => parseInt(key))
						}
					}
				})
			},
			removeDriverAssociations: (driverId) => {
				set((state) => {
					const newAssociations: { [key: string]: DriverAssociations } = {
						...state.driversAssociations.byId
					}
					Object.keys(newAssociations).forEach((id) => {
						if (newAssociations[id].driverId === driverId) {
							delete newAssociations[id]
						}
					})
					return {
						driversAssociations: {
							byId: newAssociations,
							allIds: Object.keys(newAssociations).map((id) => parseInt(id))
						}
					}
				})
			},
			fetchDrivers: async () => {
				const drivers = await getAllDriversService()
				const normalizedData = normalize(drivers, [driverSchema])
				set((state) => ({
					drivers: {
						byId: { ...state.drivers.byId, ...normalizedData.entities.drivers },
						allIds: normalizedData.result
					}
				}))
				return drivers
			},
			fetchDriverById: async (id) => {
				const driver = await getDriverById(id)
				const normalizedData = normalize(driver, driverSchema)
				set({
					drivers: {
						byId: { ...normalizedData.entities.driver },
						allIds: normalizedData.result
					}
				})
				return driver
			},
			resetDrivers: () => {
				set(
					{
						drivers: {
							byId: {},
							allIds: []
						}
					},
					false,
					'RESET_DRIVERS'
				)
			},
			resetAllDriverInfo: () => {
				set(
					{
						drivers: {
							byId: {},
							allIds: []
						},
						driversAssociations: {
							byId: {},
							allIds: []
						}
					},
					false,
					'RESET_ALL_DRIVER_INFO'
				)
			},
			createDriver: async (driver) => {
				const newDriver = await createDriverService(driver)
				const normalizedData = normalize(newDriver, driverSchema)
				set((state) => ({
					drivers: {
						...state.drivers,
						byId: { ...state.drivers.byId, ...normalizedData.entities.drivers },
						allIds: [...state.drivers.allIds, normalizedData.result]
					}
				}))
				return newDriver
			},
			updateDriver: async (driver, driverId) => {
				const updatedDriver = await updateDriverService(driver, driverId)
				const normalizedData = normalize(updatedDriver, driverSchema)
				set((state) => ({
					drivers: {
						...state.drivers,
						byId: { ...state.drivers.byId, ...normalizedData.entities.drivers }
					}
				}))
				return updatedDriver
			},
			fetchAllDriverAssociations: async () => {
				const associations = await getDriverAssociations()
				const normalizedData = normalize(associations, [driverAssociationSchema])

				set(() => ({
					driversAssociations: {
						byId: normalizedData.entities.driversAssociations || {},
						allIds: normalizedData.result || []
					}
				}))
				return associations
			},
			getAssociationsByDriverId: (driverId) => {
				const associations = get().driversAssociations.byId
				const associationsFounded = Object.values(associations).filter(
					(association) => association.driverId === driverId
				) as DriverAssociations[]
				return associationsFounded
			},
			getAssociationsByUserId: (userId) => {
				const associations = get().driversAssociations.byId
				const associationsFounded = Object.values(associations).filter(
					(association) => association.userId === userId
				) as DriverAssociations[]
				return associationsFounded
			},
			createAssociationsByUserId: async (userId, driverIds) => {
				const associations = await createDriverAssociationsByUserService(userId, driverIds)
				const normalizedData = normalize(associations, [driverAssociationSchema])
				set((state) => ({
					driversAssociations: {
						byId: {
							...state.driversAssociations.byId,
							...normalizedData.entities.driversAssociations
						},
						allIds: [...state.driversAssociations.allIds, ...normalizedData.result]
					}
				}))
				return associations
			},
			resetAndSetAssociationsToDriver: async (driverId: number, associations: number[]) => {
				const newAssociations = await resetAndSetAssociationsToDriver(driverId, associations)
				const normalizedData = normalize(newAssociations, [driverAssociationSchema])
				set((state) => ({
					...state,
					driversAssociations: {
						byId: {
							...normalizedData.entities.driversAssociations
						},
						allIds: normalizedData.result
					}
				}))
				return newAssociations
			},
			deleteAssociationsByDriverId: async (driverId: number) => {
				await deleteAssociationsByDriverId(driverId)
				get().removeDriverAssociations(driverId)
			}
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'drivers store'
		}
	)
)
