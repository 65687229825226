import SelectRouteOptions from 'components/elements/SelectOptions/SelectRouteOptions'
import { FormikProps } from 'formik'
import { AllStepForms, StepRouteForm } from 'models/Transfer'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormikError } from 'utils/formikUtils'

interface Props {
	formik: FormikProps<StepRouteForm> | FormikProps<AllStepForms>
}

const StepRoutesInputs: FC<Props> = (props) => {
	const { formik } = props

	const { t } = useTranslation()

	return (
		<SelectRouteOptions
			onSelect={(route) => formik.setFieldValue('routeTypeId', route)}
			selectedId={formik.values['routeTypeId']}
			error={getFormikError(formik as FormikProps<StepRouteForm>, 'routeTypeId')}
			label={t('transfer.create_steps.step_route.h1')}
		/>
	)
}

export default StepRoutesInputs
