import { TransferShareIcon, UserCirclePlusFilledIcon } from 'assets/svgs'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import { DAILY_URL, FRIENDS_LIST_URL, TRANSFER_REQUESTS_URL } from 'constants/routes'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { FriendTab, RequestTypeTab } from 'models/Elements'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'
import './Notifications.scss'

const enum NotificationTypes {
	friendRequest = 'friend-request',
	transferShare = 'transfer-share'
}

interface Notification {
	id: string
	type: NotificationTypes
	text: string
	isRead: boolean
}

const mockedNotifications: Notification[] = [
	{
		id: '0',
		type: NotificationTypes.transferShare,
		text: 'Transfer Málaga VTCs quiere compartir un transfer contigo',
		isRead: true
	},
	{
		id: '1',
		type: NotificationTypes.friendRequest,
		text: 'Wego VTC quiere ser tu empresa amiga',
		isRead: true
	},
	{
		id: '2',
		type: NotificationTypes.friendRequest,
		text: 'Wego VTC quiere ser tu empresa amiga',
		isRead: false
	}
]

const Notifications: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const icon = useMemo(
		() => ({
			[NotificationTypes.transferShare]: <TransferShareIcon />,
			[NotificationTypes.friendRequest]: <UserCirclePlusFilledIcon />
		}),
		[]
	)

	const handleNotification = (notification: Notification) => {
		if (notification.type === NotificationTypes.transferShare) {
			navigate(`${TRANSFER_REQUESTS_URL}/${RequestTypeTab.received}`)
		} else if (notification.type === NotificationTypes.friendRequest) {
			navigate(`${FRIENDS_LIST_URL}/${FriendTab.requests}/${RequestTypeTab.received}`)
		}
	}

	return (
		<FormLayout title={t('notifications.title')} leftButton={<ButtonBack url={DAILY_URL} />}>
			{mockedNotifications.map((notification) => (
				<button
					className={`notification-card${notification.isRead ? ' read' : emptyString}`}
					key={notification.id}
					onClick={() => handleNotification(notification)}>
					<div className='notification-card-icon'>{icon[notification.type]}</div>
					<p className='notification-card-text body1'>{notification.text}</p>
				</button>
			))}
		</FormLayout>
	)
}

export default Notifications
