import Header from 'components/elements/Header/Header'
import React from 'react'
import './FormLayout.scss'

interface Props {
	title: string
	leftButton?: React.ReactNode
	rightButton?: React.ReactNode
	footer?: React.ReactNode
	children: React.ReactNode
	progress?: number
}

const FormLayout = (props: Props) => {
	const { children, title, leftButton, rightButton, footer, progress } = props

	return (
		<div className='form-layout'>
			<Header
				title={title}
				leftButton={leftButton}
				rightButton={rightButton}
				percentile={progress}
			/>

			<div className='form-layout-content'>
				<div className='form-layout-content-children'>{children}</div>
				{footer && <div className='form-layout-content-footer'>{footer}</div>}
			</div>
		</div>
	)
}

export default FormLayout
