const transferColors = {
	departure: '#b3d3f5',
	arrival: '#d6e9e6',
	transfer: '#f5f900',
	disposition: '#cf67ff',
	crane: '#ff9567'
}
const secondaryTransferColors = {
	departure: '#f0f5ff',
	arrival: '#ecfffc',
	transfer: '#fffff3',
	disposition: '#f9f4ff',
	crane: '#fff9f6'
}

export { secondaryTransferColors, transferColors }
