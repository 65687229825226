import AirplaneLanding from 'assets/svgs/AirplaneLanding'
import AirplaneTakeoff from 'assets/svgs/AirplaneTakeoff'
import BarricadeIcon from 'assets/svgs/BarricadeIcon'
import CarIcon from 'assets/svgs/CarIcon'
import CarProfileIcon from 'assets/svgs/CarProfileIcon'
import SimpleColouredCard from 'components/elements/SimpleColouredCard/SimpleColouredCard'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { secondaryTransferColors, transferColors } from 'constants/transfer'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { RouteTypeName, TranslationKeys } from 'models/Transfer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTransfersStore } from 'stores/useTransfersStore'
import './StepRoute.scss'

export const ROUTES = [
	{
		name: 'transfer.route.departure',
		type: RouteTypeName.departure,
		icon: <AirplaneTakeoff />,
		color: transferColors.departure,
		secondaryColor: secondaryTransferColors.departure
	},
	{
		name: 'transfer.route.arrival',
		type: RouteTypeName.arrival,
		icon: <AirplaneLanding />,
		color: transferColors.arrival,
		secondaryColor: secondaryTransferColors.arrival
	},
	{
		name: 'transfer.route.transfer',
		type: RouteTypeName.transfer,
		icon: <CarIcon isActive activeColor={transferColors.transfer} />,
		color: transferColors.transfer,
		secondaryColor: secondaryTransferColors.transfer
	},
	{
		name: 'transfer.route.disposition',
		type: RouteTypeName.disposition,
		icon: <CarProfileIcon />,
		color: transferColors.disposition,
		secondaryColor: secondaryTransferColors.disposition
	},
	{
		name: 'transfer.route.crane',
		type: RouteTypeName.crane,
		icon: <BarricadeIcon />,
		color: transferColors.crane,
		secondaryColor: secondaryTransferColors.crane
	}
] as {
	name: TranslationKeys
	type: keyof typeof RouteTypeName
	icon: JSX.Element
	color: string
	secondaryColor: string
}[]

const StepRoute = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const updateActualTransferCreation = useTransfersStore(
		(state) => state.updateActualTransferCreation
	)

	const handleRoute = (routeType: keyof typeof RouteTypeName) => {
		updateActualTransferCreation({ route: routeType })
		navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.DETAILS.toLocaleLowerCase()}`)
	}

	return (
		<FormTransferLayout step={1}>
			<FormSectionLayout title={t('transfer.create_steps.step_route.h1')} noDivider>
				<span className='step-route-description'>{t('transfer.create_steps.step_route.h2')}</span>
				<div className='step-route-card-container'>
					{ROUTES.map((route) => (
						<SimpleColouredCard
							key={route.name}
							icon={route.icon}
							title={t(route.name)}
							mainColor={route.color}
							secondaryColor={route.secondaryColor}
							onClick={() => {
								handleRoute(route.type)
							}}
						/>
					))}
				</div>
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepRoute
