import { TRANSFER_STEPS } from 'constants/routes'
import { useParams } from 'react-router-dom'
import StepAddresses from './Steps/StepAddresses'
import StepClient from './Steps/StepClient'
import StepDetails from './Steps/StepDetails'
import StepEnd from './Steps/StepEnd'
import StepExtras from './Steps/StepExtras'
import StepFomento from './Steps/StepFomento'
import StepObservations from './Steps/StepObservations'
import StepPrices from './Steps/StepPrices'
import StepRoute from './Steps/StepRoute'
import './TransferDetails.scss'

const TransferCreate = () => {
	const { step } = useParams()

	return (
		<>
			{step && step.toUpperCase() === TRANSFER_STEPS.ROUTE && <StepRoute />}
			{step && step.toUpperCase() === TRANSFER_STEPS.DETAILS && <StepDetails />}
			{step && step.toUpperCase() === TRANSFER_STEPS.ADDRESSES && <StepAddresses />}
			{step && step.toUpperCase() === TRANSFER_STEPS.FOMENTO && <StepFomento />}
			{step && step.toUpperCase() === TRANSFER_STEPS.PRICES && <StepPrices />}
			{step && step.toUpperCase() === TRANSFER_STEPS.CLIENT && <StepClient />}
			{step && step.toUpperCase() === TRANSFER_STEPS.EXTRAS && <StepExtras />}
			{step && step.toUpperCase() === TRANSFER_STEPS.OBSERVATIONS && <StepObservations />}
			{step && step.toUpperCase() === TRANSFER_STEPS.END && <StepEnd />}
		</>
	)
}

export default TransferCreate
