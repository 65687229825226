import { CarIcon, SteeringWheel, UserList, UserSwitch } from 'assets/svgs'
import ActionButton from 'components/elements/ActionButton/ActionButton'
import HeaderWithNotificationBell from 'components/elements/HeaderWithNotificationBell/HeaderWithNotificationBell'
import {
	DRIVERS_LIST_URL,
	FRIENDS_LIST_URL,
	PROVIDERS_LIST_URL,
	VEHICLES_LIST_URL
} from 'constants/routes'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'
import './Resources.scss'

const ResourcesList = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const options = useMemo(
		() => [
			{
				label: t('resources.providers'),
				icon: <UserList />,
				backGroundIcon: '#f0f2ff',
				onClick: () => navigate(PROVIDERS_LIST_URL)
			},
			{
				label: t('resources.drivers'),
				icon: <SteeringWheel />,
				backGroundIcon: '#f0f2ff',
				url: DRIVERS_LIST_URL,
				onClick: () => navigate(DRIVERS_LIST_URL)
			},
			{
				label: t('resources.vehicles'),
				icon: <CarIcon width={24} height={24} />,
				backGroundIcon: '#f0f2ff',
				url: VEHICLES_LIST_URL,
				onClick: () => navigate(VEHICLES_LIST_URL)
			},
			{
				label: t('resources.friends'),
				icon: <UserSwitch />,
				backGroundIcon: '#f0f2ff',
				url: emptyString,
				onClick: () => navigate(FRIENDS_LIST_URL)
			}
		],
		[]
	)

	return (
		<DashboardLayout>
			<div className='resources-container'>
				<HeaderWithNotificationBell title={t('resources.h1')} subtitle={t('resources.h2')} />
				<div className='options'>
					{options.map((option, index) => (
						<ActionButton
							key={index}
							icon={option.icon}
							backGroundIcon={option.backGroundIcon}
							onClick={option.onClick}>
							{option.label}
						</ActionButton>
					))}
				</div>
			</div>
		</DashboardLayout>
	)
}

export default ResourcesList
