import { SharedTransfer, Transfer } from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFomentoStore } from 'stores/useFomentoStore'
import { useTransfersShareStore } from 'stores/useTransfersShareStore'
import { useTransfersStore } from 'stores/useTransfersStore'
import { emptyString } from 'utils/common'
import { dateToSQL, dateToSpanishToTimestamp } from 'utils/dateUtils'

const useTransfers = (date: Date) => {
	const transfersState = useTransfersStore()
	const transferShareState = useTransfersShareStore()
	const fomentoState = useFomentoStore()
	const [transfersOrdered, setTransfersOrdered] = useState<Transfer[]>([])
	const [transfersExcluded, setTransfersExcluded] = useState<Transfer[]>([])
	const [allTransfers, setAllTransfers] = useState<Transfer[]>([])

	const [loading, setLoading] = useState<boolean>(false)
	const [error, setError] = useState<Nullable<string>>(null)
	const [pendingTransfers, setPendingTransfers] = useState<SharedTransfer[]>([])
	const dateString = dateToSQL(date)
	const { t } = useTranslation()

	useEffect(() => {
		fetchTransfers()

		return () => {
			setTransfersExcluded([])
		}
	}, [dateString])

	useEffect(() => {
		filterTransfersByCurrentDateAndOrder()
	}, [transfersState.transfers.allIds])
	useEffect(() => {
		const pendingTransfers = getAllTransferShared()
		setPendingTransfers(pendingTransfers)
	}, [transferShareState.sharedTransfers])

	const getAllTransferShared = () => {
		return transferShareState.getAllTransferSharedRequestsReceived()
	}

	const filterTransfersByCurrentDateAndOrder = () => {
		const transfersOrdered = Object.values(transfersState.transfers.byId)
			.filter((transfer) => transfer.date === dateString)
			.sort(
				(a, b) =>
					dateToSpanishToTimestamp(new Date(`${a.date} ${a.time}`)) -
					dateToSpanishToTimestamp(new Date(`${b.date} ${b.time}`))
			)
		setAllTransfers(transfersOrdered)
		setTransfersOrdered(transfersOrdered)
	}

	const fetchTransfers = async () => {
		setLoading(true)
		try {
			await transfersState.fetchTransfersByDate(dateString)
			await fomentoState.fetchFomentoByDate(dateString)
			await transferShareState.fetchSharedTransfersByDate(dateString)
			await transfersState.fetchStartEndTransfersByDate(dateString)
			await transferShareState.fetchAllTransferSharedRequests()
		} catch (error) {
			setError(t('transfer.error_load_transfer'))
		} finally {
			setLoading(false)
		}
	}

	const onSearch = (searchTerm: string) => {
		if (searchTerm === emptyString) {
			setTransfersOrdered(allTransfers)
			return
		}
		const searchedTransfers = allTransfers.filter((transfer) => {
			return (
				transfer.clientName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				transfer.originAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				transfer.destinationAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				transfer.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
				transfer.email?.toLowerCase().includes(searchTerm.toLowerCase())
			)
		})
		setTransfersOrdered(searchedTransfers)
	}

	const filterNextTransfers = () => {
		const now = new Date()
		const transfersFiltered = transfersOrdered.filter((transfer) => {
			const transferDate = new Date(`${transfer.date} ${transfer.time}`)
			return transferDate.getTime() >= now.getTime()
		})
		setTransfersOrdered(transfersFiltered)

		const transfersExcluded = transfersOrdered.filter((transfer) => {
			return !transfersFiltered.includes(transfer)
		})
		setTransfersExcluded(transfersExcluded)
	}

	return {
		transfers: transfersOrdered,
		transfersExcluded,
		allTransfers,
		onSearch,
		filterTransfersByCurrentDateAndOrder,
		filterNextTransfers,
		pendingTransfers,
		loading,
		error
	}
}

export default useTransfers
