import { ItemDropdown } from 'components/elements/DropdownSelector/DropdownSelector'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'

interface Props {
	title: string
	isVisible: boolean
	associations: ItemDropdown[]
	description?: string
	subtitle?: string
	isMultiple?: boolean
	toggleVisibility: () => void
	handleSelection: (selected: ItemDropdown[]) => void
}

const AssociationListModal: React.FC<Props> = (props) => {
	const {
		title,
		isVisible,
		description,
		subtitle,
		associations,
		isMultiple,
		toggleVisibility,
		handleSelection
	} = props

	const handleClick = (ids: number[]) => {
		handleSelection(associations.filter((association) => ids.includes(association.id)))
	}

	return (
		<DropdownSelectorModal
			title={title}
			visible={isVisible}
			toggleVisibility={toggleVisibility}
			items={associations}
			handleClick={handleClick}
			isMultiple={isMultiple}
			description={description}
			subtitle={subtitle}
		/>
	)
}

export default AssociationListModal
