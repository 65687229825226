import BackgroundTourStep1 from 'assets/images/background-tour-step-1.png'
import BackgroundTourStep2 from 'assets/images/background-tour-step-2.png'
import BackgroundTourStep3 from 'assets/images/background-tour-step-3.png'
import BackgroundTourStep4 from 'assets/images/background-tour-step-4.png'
import BackgroundTourStep5 from 'assets/images/background-tour-step-5.png'
import BackgroundTour from 'assets/images/background-tour.png'
import { ONBOARDING_URL } from 'constants/routes'
import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from 'stores/useUserStore'
import { oneElement } from 'utils/common'
import TourCard, { TOUR_STEP } from './TourCard'

const Tour: React.FC = () => {
	const [step, setStep] = useState(TOUR_STEP.DEFAULT)
	const userState = useUserStore()

	const navigate = useNavigate()

	const onContinue = () => setStep(step + oneElement)
	const onFinish = async () => {
		await userState.skipTour()
		navigate(ONBOARDING_URL)
	}

	return (
		<>
			{step === TOUR_STEP.DEFAULT && (
				<TourCard
					step={TOUR_STEP.DEFAULT}
					title={t('tour.stepDefault.title')}
					description={t('tour.stepDefault.description')}
					onContinue={onContinue}
					onFinish={onFinish}
					backgroundImage={BackgroundTour}
				/>
			)}
			{step === TOUR_STEP.FIRST && (
				<TourCard
					step={TOUR_STEP.FIRST}
					title={t('tour.step1.title')}
					description={t('tour.step1.description')}
					onContinue={onContinue}
					onFinish={onFinish}
					backgroundImage={BackgroundTourStep1}
				/>
			)}
			{step === TOUR_STEP.SECOND && (
				<TourCard
					step={TOUR_STEP.SECOND}
					title={t('tour.step2.title')}
					description={t('tour.step2.description')}
					onContinue={onContinue}
					onFinish={onFinish}
					backgroundImage={BackgroundTourStep2}
				/>
			)}
			{step === TOUR_STEP.THIRD && (
				<TourCard
					step={TOUR_STEP.THIRD}
					title={t('tour.step3.title')}
					description={t('tour.step3.description')}
					onContinue={onContinue}
					onFinish={onFinish}
					backgroundImage={BackgroundTourStep3}
				/>
			)}
			{step === TOUR_STEP.FOURTH && (
				<TourCard
					step={TOUR_STEP.FOURTH}
					title={t('tour.step4.title')}
					description={t('tour.step4.description')}
					onContinue={onContinue}
					onFinish={onFinish}
					backgroundImage={BackgroundTourStep4}
				/>
			)}
			{step === TOUR_STEP.FIFTH && (
				<TourCard
					step={TOUR_STEP.FIFTH}
					title={t('tour.step5.title')}
					description={t('tour.step5.description')}
					onContinue={onContinue}
					onFinish={onFinish}
					backgroundImage={BackgroundTourStep5}
				/>
			)}
		</>
	)
}

export default Tour
