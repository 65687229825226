import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const FileSearch: FC<Props> = (props) => {
	const { width = 28, height = 28 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='FileSearch'>
				<path id='Vector' opacity='0.2' d='M22.75 9.625H16.625V3.5L22.75 9.625Z' fill='#713FFF' />
				<path
					id='Vector_2'
					d='M23.3691 9.00594L17.2441 2.88094C17.1627 2.79971 17.0662 2.7353 16.96 2.69138C16.8538 2.64747 16.7399 2.62491 16.625 2.625H6.125C5.66087 2.625 5.21575 2.80937 4.88756 3.13756C4.55937 3.46575 4.375 3.91087 4.375 4.375V23.625C4.375 24.0891 4.55937 24.5342 4.88756 24.8624C5.21575 25.1906 5.66087 25.375 6.125 25.375H21.875C22.3391 25.375 22.7842 25.1906 23.1124 24.8624C23.4406 24.5342 23.625 24.0891 23.625 23.625V9.625C23.6251 9.51006 23.6025 9.39623 23.5586 9.29001C23.5147 9.18379 23.4503 9.08726 23.3691 9.00594ZM17.5 5.61203L20.638 8.75H17.5V5.61203ZM21.875 23.625H6.125V4.375H15.75V9.625C15.75 9.85706 15.8422 10.0796 16.0063 10.2437C16.1704 10.4078 16.3929 10.5 16.625 10.5H21.875V23.625ZM16.8941 18.282C17.4105 17.4615 17.6064 16.4794 17.4445 15.5235C17.2826 14.5676 16.7742 13.7048 16.0164 13.1C15.2586 12.4953 14.3045 12.1909 13.3365 12.2451C12.3685 12.2993 11.4544 12.7083 10.7688 13.3938C10.0833 14.0794 9.67431 14.9935 9.62011 15.9615C9.56592 16.9295 9.87028 17.8836 10.475 18.6414C11.0798 19.3992 11.9426 19.9076 12.8985 20.0695C13.8544 20.2314 14.8365 20.0355 15.657 19.5191L16.8809 20.7441C16.9622 20.8254 17.0587 20.8898 17.165 20.9338C17.2712 20.9778 17.385 21.0005 17.5 21.0005C17.615 21.0005 17.7288 20.9778 17.835 20.9338C17.9413 20.8898 18.0378 20.8254 18.1191 20.7441C18.2004 20.6628 18.2648 20.5663 18.3088 20.46C18.3528 20.3538 18.3755 20.24 18.3755 20.125C18.3755 20.01 18.3528 19.8962 18.3088 19.79C18.2648 19.6837 18.2004 19.5872 18.1191 19.5059L16.8941 18.282ZM11.375 16.1875C11.375 15.7549 11.5033 15.3319 11.7437 14.9722C11.984 14.6125 12.3257 14.3321 12.7254 14.1665C13.1251 14.0009 13.5649 13.9576 13.9893 14.042C14.4136 14.1264 14.8034 14.3348 15.1093 14.6407C15.4152 14.9466 15.6236 15.3364 15.708 15.7607C15.7924 16.1851 15.7491 16.6249 15.5835 17.0246C15.4179 17.4243 15.1375 17.766 14.7778 18.0063C14.4181 18.2467 13.9951 18.375 13.5625 18.375C12.9823 18.375 12.4259 18.1445 12.0157 17.7343C11.6055 17.3241 11.375 16.7677 11.375 16.1875Z'
					fill='#713FFF'
				/>
			</g>
		</svg>
	)
}

export default FileSearch
