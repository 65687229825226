import Tooltip from 'components/elements/Tooltip/Tooltip'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import './RequestShareTagTooltip.scss'

const mockedData = {
	initialData: 'Día, Hora, Trayecto, Nº de personas, Origen, Destino, Precio',
	finalData:
		'Día, Hora, Trayecto, Nº de personas, Origen, Destino, Precio, Observaciones, Teléfono, Email, Extras, Pagado, Comisión',
	notifications:
		'Enviamos una notificación para que acepten o rechacen la solicitud. Una vez aceptada por una empresa, las demás solicitudes se rechazarán automáticamente.'
}

const RequestShareTagTooltip: FC = () => {
	const { t } = useTranslation()

	return (
		<Tooltip title={t('transfer.requests_sent.tooltip_title')}>
			<div className='request-share-tag-tooltip-content'>
				<div className='request-share-tag-tooltip-content-item'>
					<span className='request-share-tag-tooltip-content-item-title body1'>
						{t('transfer.requests_sent.tooltip_initial_data_title')}
					</span>
					<p className='request-share-tag-tooltip-content-item-description'>
						{mockedData.initialData}
					</p>
				</div>
				<div className='request-share-tag-tooltip-content-item'>
					<span className='request-share-tag-tooltip-content-item-title body1'>
						{t('transfer.requests_sent.tooltip_final_data_title')}
					</span>
					<p className='request-share-tag-tooltip-content-item-description'>
						{mockedData.finalData}
					</p>
				</div>
				<div className='request-share-tag-tooltip-content-item'>
					<span className='request-share-tag-tooltip-content-item-title body1'>
						{t('transfer.requests_sent.tooltip_notifications_title')}
					</span>
					<p className='request-share-tag-tooltip-content-item-description'>
						{mockedData.notifications}
					</p>
				</div>
			</div>
		</Tooltip>
	)
}

export default RequestShareTagTooltip
