import { BellRingingIcon, CloseIcon, WarningIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import { VEHICLES_LIST_URL, VEHICLES_NEW_TECHNICAL_DETAILS_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { Vehicle, VehicleForm, VehiclePost } from 'models/Vehicle'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { useConfigStore } from 'stores/useConfigStore'
import { useVehiclesStore } from 'stores/useVehiclesStore'
import { emptyString } from 'utils/common'
import * as Yup from 'yup'
import VehicleInputs from './VehicleInputs'

const VehicleNew: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const configStore = useConfigStore()
	const vehicleStore = useVehiclesStore()

	const [loading, setLoading] = useState(false)
	const [isModalCommunicationVisible, setIsModalCommunicationVisible] = useState(true)
	const [isModalNotificationsVisible, setIsModalNotificationsVisible] = useState(false)
	const [vehicleCreated, setVehicleCreated] = useState<Nullable<Vehicle>>(null)

	const closeModalCommunication = () => {
		setIsModalCommunicationVisible(false)
		navigate(VEHICLES_LIST_URL)
	}
	const closeModalNotifications = () => {
		setIsModalNotificationsVisible(false)
		navigate(VEHICLES_LIST_URL)
	}

	const formik = useFormik<VehicleForm>({
		initialValues: {
			plate: emptyString,
			brand: emptyString,
			model: emptyString,
			classId: null,
			isActive: true
		},
		validationSchema: Yup.object().shape({
			plate: Yup.string()
				.transform((value) => value.toUpperCase())
				.matches(/^\d{4}-[A-Z]{3}$/, t('errors.plate_format'))
				.required(t('errors.required_m', { field: t('vehicle.plate') })),
			brand: Yup.string().required(t('errors.required_m', { field: t('vehicle.brand') })),
			model: Yup.string().required(t('errors.required_m', { field: t('vehicle.model') })),
			classId: Yup.string().required(t('errors.required_m', { field: t('vehicle.class') })),
			isActive: Yup.boolean().required(t('errors.required_m', { field: t('vehicle.is_active') }))
		}),
		onSubmit: async (values) => {
			if (!values.classId) return
			const vehicle: VehiclePost = {
				plate: values.plate,
				brand: values.brand,
				model: values.model,
				class: configStore.vehicleClasses.byId[values.classId].name,
				isActive: values.isActive
			}
			try {
				setLoading(true)
				const vehicleCreated = await vehicleStore.createVehicle(vehicle)
				setVehicleCreated(vehicleCreated)
				toast.success(t('vehicle.success_create'))
				setIsModalNotificationsVisible(true)
			} catch (error) {
				toast.error(t('provider.error_create'))
			} finally {
				setLoading(false)
			}
		}
	})

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	return (
		<>
			<FormLayout
				title={t('vehicle.new_title')}
				rightButton={
					<ButtonComponent
						onClick={() => navigate(VEHICLES_LIST_URL)}
						variant='only-icon'
						icon={<CloseIcon />}
					/>
				}
				footer={
					<ButtonComponent type='submit' onClick={handleSubmit} fullWidth loading={loading}>
						{t('vehicle.save_new')}
					</ButtonComponent>
				}>
				<VehicleInputs formik={formik} setFieldRef={setFieldRef} />
			</FormLayout>
			<ModalActions
				type='error'
				icon={<WarningIcon />}
				title={t('vehicle.communication_modal.title')}
				description={t('vehicle.communication_modal.description')}
				primaryButton={{
					text: t('general.continue'),
					onClick: () => setIsModalCommunicationVisible(false)
				}}
				secondaryButton={{
					text: t('general.cancel'),
					onClick: closeModalCommunication
				}}
				isVisible={isModalCommunicationVisible}
				onClose={closeModalCommunication}
			/>
			<ModalActions
				type='warning'
				icon={<BellRingingIcon />}
				title={t('vehicle.notifications_modal.title')}
				description={t('vehicle.notifications_modal.description')}
				primaryButton={{
					text: t('vehicle.notifications_modal.primary_button'),
					onClick: () => navigate(`${VEHICLES_NEW_TECHNICAL_DETAILS_URL}/${vehicleCreated?.id}`)
				}}
				secondaryButton={{
					text: t('vehicle.notifications_modal.secondary_button'),
					onClick: closeModalNotifications
				}}
				isVisible={isModalNotificationsVisible}
				onClose={closeModalNotifications}
			/>
		</>
	)
}

export default VehicleNew
