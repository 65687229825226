import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CaretCircleRight: FC<Props> = (props) => {
	const { width = 19, height = 19 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M9.5 1.78125C7.97338 1.78125 6.48104 2.23395 5.2117 3.08209C3.94235 3.93024 2.95302 5.13574 2.36881 6.54616C1.78459 7.95658 1.63174 9.50856 1.92957 11.0059C2.2274 12.5031 2.96254 13.8785 4.04202 14.958C5.12151 16.0375 6.49686 16.7726 7.99415 17.0704C9.49144 17.3683 11.0434 17.2154 12.4538 16.6312C13.8643 16.047 15.0698 15.0576 15.9179 13.7883C16.7661 12.519 17.2188 11.0266 17.2188 9.5C17.2166 7.45352 16.4027 5.49148 14.9556 4.04441C13.5085 2.59733 11.5465 1.78341 9.5 1.78125ZM9.5 16.0312C8.20824 16.0312 6.94549 15.6482 5.87144 14.9305C4.79738 14.2129 3.96025 13.1928 3.46592 11.9994C2.97158 10.806 2.84224 9.49275 3.09425 8.22582C3.34626 6.95888 3.9683 5.79512 4.88171 4.88171C5.79512 3.9683 6.95888 3.34626 8.22582 3.09425C9.49276 2.84224 10.806 2.97158 11.9994 3.46591C13.1928 3.96025 14.2129 4.79737 14.9305 5.87143C15.6482 6.94549 16.0313 8.20824 16.0313 9.5C16.0293 11.2316 15.3405 12.8917 14.1161 14.1161C12.8917 15.3405 11.2316 16.0293 9.5 16.0312ZM11.7013 9.07992C11.7565 9.13507 11.8003 9.20055 11.8302 9.27263C11.8601 9.34471 11.8755 9.42197 11.8755 9.5C11.8755 9.57803 11.8601 9.65529 11.8302 9.72737C11.8003 9.79945 11.7565 9.86493 11.7013 9.92008L8.73258 12.8888C8.67742 12.944 8.61193 12.9878 8.53985 13.0176C8.46777 13.0475 8.39052 13.0628 8.3125 13.0628C8.23449 13.0628 8.15724 13.0475 8.08516 13.0176C8.01308 12.9878 7.94759 12.944 7.89243 12.8888C7.83726 12.8337 7.7935 12.7682 7.76364 12.6961C7.73379 12.624 7.71842 12.5468 7.71842 12.4688C7.71842 12.3907 7.73379 12.3135 7.76364 12.2414C7.7935 12.1693 7.83726 12.1038 7.89243 12.0487L10.4418 9.5L7.89243 6.95133C7.78101 6.83992 7.71842 6.68881 7.71842 6.53125C7.71842 6.37369 7.78101 6.22258 7.89243 6.11117C8.00384 5.99976 8.15494 5.93717 8.3125 5.93717C8.47006 5.93717 8.62117 5.99976 8.73258 6.11117L11.7013 9.07992Z'
				fill='#713FFF'
			/>
		</svg>
	)
}

export default CaretCircleRight
