import { FC } from 'react'

interface Props {
	'width'?: number
	'height'?: number
	'color'?: string
	'data-test'?: string
}

const UserFilledIcon: FC<Props> = (props) => {
	const {
		width = 16,
		height = 17,
		color = '#713FFF',
		'data-test': dataTest = 'user-filled-icon'
	} = props

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			data-testid={dataTest}>
			<g clipPath='url(#clip0_696_4378)'>
				<path
					d='M7.99967 1.83398C8.65895 1.83398 9.30341 2.02948 9.85158 2.39575C10.3997 2.76202 10.827 3.28262 11.0793 3.89171C11.3316 4.50079 11.3976 5.17102 11.269 5.81762C11.1403 6.46422 10.8229 7.05817 10.3567 7.52434C9.89052 7.99051 9.29658 8.30798 8.64998 8.4366C8.00337 8.56522 7.33315 8.49921 6.72406 8.24692C6.11498 7.99462 5.59438 7.56738 5.22811 7.01922C4.86184 6.47105 4.66634 5.82659 4.66634 5.16732L4.66967 5.02265C4.70695 4.16448 5.07408 3.35384 5.69449 2.75976C6.3149 2.16568 7.1407 1.83403 7.99967 1.83398ZM9.33301 9.83398C10.2171 9.83398 11.0649 10.1852 11.69 10.8103C12.3152 11.4354 12.6663 12.2833 12.6663 13.1673V13.834C12.6663 14.1876 12.5259 14.5267 12.2758 14.7768C12.0258 15.0268 11.6866 15.1673 11.333 15.1673H4.66634C4.31272 15.1673 3.97358 15.0268 3.72353 14.7768C3.47348 14.5267 3.33301 14.1876 3.33301 13.834V13.1673C3.33301 12.2833 3.6842 11.4354 4.30932 10.8103C4.93444 10.1852 5.78229 9.83398 6.66634 9.83398H9.33301Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_696_4378'>
					<rect width='16' height='16' fill='white' transform='translate(0 0.5)' />
				</clipPath>
			</defs>
		</svg>
	)
}

export default UserFilledIcon
