import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import './ReceiptCard.scss'

interface Props {
	concept: string
	reference: string
	total: string
	status: string
	date: string
	onClick: () => void
}

const ReceiptCard: FC<Props> = (props) => {
	const { concept, reference, total, status, date, onClick } = props

	const { t } = useTranslation()

	return (
		<button className='receipt-card' onClick={onClick}>
			<div className='receipt-card-item'>
				<span className='body2'>{t('general.date')}</span>
				<span className='receipt-card-value body1'>{date}</span>
			</div>
			<div className='receipt-card-item'></div>
			<div className='receipt-card-item'>
				<span className='body2'>{t('settings.my_account.subscription.period')}</span>
				<span className='receipt-card-value body1'>{concept}</span>
			</div>
			<div className='receipt-card-item'>
				<span className='body2'>{t('settings.my_account.subscription.total')}</span>
				<span className='receipt-card-value body1'>{total}</span>
			</div>
			<div className='receipt-card-item'>
				<span className='body2'>{t('settings.my_account.subscription.reference')}</span>
				<span className='receipt-card-value body1'>{reference}</span>
			</div>
			<div className='receipt-card-item'>
				<span className='body2'>{t('settings.my_account.subscription.status')}</span>
				<span className='receipt-card-value body1'>{status}</span>
			</div>
		</button>
	)
}

export default ReceiptCard
