import { RoadHorizon } from 'assets/svgs'
import DailyTransferCard from 'components/elements/DailyTransferCard/DailyTransferCard'
import HeaderWithNotificationBell from 'components/elements/HeaderWithNotificationBell/HeaderWithNotificationBell'
import Loading from 'components/elements/Loading/Loading'
import TabContainer from 'components/elements/TabContainer/TabContainer'
import { DAILY_URL } from 'constants/routes'
import useTransfers from 'hooks/useTransfers'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { emptyString } from 'utils/common'
import { dateIsToday, dateToSQL, isValidDate } from 'utils/dateUtils'
import './DailyComponent.scss'

import DailySearchBar from './DailySearchBar'
import { DayNavigationBlock } from './DayNavigationBlock'
import ShareTransferAlertComponent from './ShareTransferAlertComponent'

const DailyComponent = () => {
	const navigate = useNavigate()
	const { date } = useParams()
	const [currentDate, setCurrentDate] = useState(dateToSQL(date ? new Date(date) : new Date()))
	const [selectedTab, setSelectedTab] = useState(0)
	const { t } = useTranslation()

	const {
		transfers,
		transfersExcluded,
		allTransfers,
		loading,
		error,
		onSearch,
		filterTransfersByCurrentDateAndOrder,
		filterNextTransfers,
		pendingTransfers
	} = useTransfers(new Date(currentDate))

	useEffect(() => {
		if (date && isValidDate(date)) {
			setCurrentDate(date)
		} else {
			navigate(`${DAILY_URL}/${dateToSQL()}`)
		}

		return () => {
			setCurrentDate(dateToSQL())
			selectedTab === 1 && setSelectedTab(0)
		}
	}, [date, navigate])

	const handleTabChange = (tabId: number) => {
		setSelectedTab(tabId)
		tabs[tabId].onSelect()
	}

	const tabs = [
		{
			id: 0,
			label: t('daily.tabs.all'),
			onSelect: () => filterTransfersByCurrentDateAndOrder()
		},
		{
			id: 1,
			label: t('daily.tabs.next'),
			onSelect: () => filterNextTransfers()
		}
	]

	const hasTransfers = transfers.length > 0 || transfersExcluded.length > 0

	if (error) {
		return <p>{error}</p>
	}

	return (
		<DashboardLayout>
			<div className='daily-wrapper center-content'>
				<div className='daily-header-container'>
					<HeaderWithNotificationBell title={t('daily.h1')} />

					<ShareTransferAlertComponent pending={pendingTransfers} />
					<DayNavigationBlock date={new Date(currentDate)} />
				</div>
				{dateIsToday(new Date(currentDate)) && hasTransfers && !loading && (
					<div className='daily-tabs-container'>
						<TabContainer tabs={tabs} onTabChange={handleTabChange} selectedTab={selectedTab} />
					</div>
				)}
				<div className={`daily-search-container${!hasTransfers ? ' no-results' : emptyString}`}>
					{!!allTransfers.length && !loading && <DailySearchBar onSearch={onSearch} />}
				</div>
				{loading ? (
					<Loading />
				) : (
					<>
						{hasTransfers ? (
							<div className='daily-transfer-cards'>
								{transfers.map((transfer) => (
									<DailyTransferCard key={transfer.id} id={transfer.id} />
								))}
							</div>
						) : (
							<div className='no-transfers-day'>
								<div className='icon-road'>
									<RoadHorizon />
								</div>
								<p>{t('daily.no_items')}</p>
							</div>
						)}
						{transfersExcluded.length > 0 && transfers.length === 0 && (
							<div className='no-transfers-day'>
								<div className='icon-road'>
									<RoadHorizon />
								</div>
								<p>{t('daily.no_more_today')}</p>
							</div>
						)}
					</>
				)}
			</div>
		</DashboardLayout>
	)
}

export default DailyComponent
