import { secondaryTransferColors, transferColors } from 'constants/transfer'
import {
	DocumentType,
	DocumentTypesClass,
	FetchResponse,
	Municipality,
	Province,
	Role,
	RouteType,
	RouteTypeName,
	UserRoles,
	VehicleClass,
	VehicleClassType
} from 'models/index'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getConfigService = async () => {
	return Promise.resolve({
		data: {
			colors: {
				departure: transferColors.departure,
				arrival: transferColors.arrival,
				transfer: transferColors.transfer,
				disposition: transferColors.disposition,
				crane: transferColors.crane
			},
			secondaryColors: {
				departure: secondaryTransferColors.departure,
				arrival: secondaryTransferColors.arrival,
				transfer: secondaryTransferColors.transfer,
				disposition: secondaryTransferColors.disposition,
				crane: secondaryTransferColors.crane
			}
		}
	})
}

export const getVehicleClasses = async (): Promise<VehicleClassType[]> => {
	return Promise.resolve([
		{ id: 1, name: VehicleClass.VTC },
		{ id: 2, name: VehicleClass.Taxi },
		{ id: 3, name: VehicleClass.Limusina },
		{ id: 4, name: VehicleClass.Minibus },
		{ id: 5, name: VehicleClass.Autobus }
	])
}

export const getDocumentTypes = async (): Promise<DocumentTypesClass[]> => {
	return Promise.resolve([
		{ id: 1, name: DocumentType.DNI },
		{ id: 2, name: DocumentType.NIE },
		{ id: 4, name: DocumentType.NIF },
		{ id: 3, name: DocumentType.CIF }
	])
}

export const getAllProvinces = async (): Promise<Province[]> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Province[]>>(
			`${endpoints.settings}/provinces`,
			{
				method: 'GET',
				token,
				responseType: 'json'
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getAllMunicipalities = async (): Promise<Municipality[]> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Municipality[]>>(
			`${endpoints.settings}/municipalities`,
			{
				method: 'GET',
				token,
				responseType: 'json'
			}
		)

		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getRolesService = async (): Promise<Role[]> => {
	return Promise.resolve([
		{
			id: 1,
			name: 'Super Admin',
			type: UserRoles['super-admin'],
			description: 'Es el rol que tiene poder de todo el sistema.',
			showInUserList: true
		},
		{
			id: 2,
			name: 'Admin',
			type: UserRoles.admin,
			description: 'Es el rol que tiene poder de todo el sistema menos de los usuarios.',
			showInUserList: true
		},
		{
			id: 3,
			name: 'Revisor',
			type: UserRoles.revisor,
			description: 'Es el rol que tiene poder de revisar todo el sistema.',
			showInUserList: false
		},
		{
			id: 4,
			name: 'Proveedor',
			type: UserRoles.provider,
			description: 'Es el rol que tiene poder de proveedor.',
			showInUserList: false
		},
		{
			id: 5,
			name: 'Conductor',
			type: UserRoles.driver,
			description: 'Es el rol que tiene poder de Conductor.',
			showInUserList: false
		}
	])
}

export const getRouteTypesService = async (): Promise<RouteType[]> => {
	return Promise.resolve([
		{ id: 1, name: RouteTypeName.departure },
		{ id: 2, name: RouteTypeName.arrival },
		{ id: 3, name: RouteTypeName.transfer },
		{ id: 4, name: RouteTypeName.disposition },
		{ id: 5, name: RouteTypeName.crane }
	])
}
