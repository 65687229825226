import { Company, CompanyForm, MySubscription, SubscriptionType } from 'models/Company'
import { FetchResponse } from 'models/CustomFetch'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getCompanyService = async (): Promise<Company> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Company>>(`${endpoints.company}`, {
			method: 'GET',
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const updateCompanyService = async (company: Partial<CompanyForm>) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Company>>(`${endpoints.company}`, {
			method: 'PATCH',
			token,
			bodyReq: company
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getAllSubscriptionsService = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<SubscriptionType[]>>(
			`${endpoints.subscriptions}`,
			{
				method: 'GET',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getMySubscriptionService = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<MySubscription[]>>(
			`${endpoints.mySubscription}`,
			{
				method: 'GET',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}
