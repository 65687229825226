import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const MapPinLineIcon: FC<Props> = (props) => {
	const { width = 17, height = 16 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 17 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M13 14H9.90875C10.428 13.5363 10.9177 13.0406 11.375 12.5156C13.0906 10.5425 14 8.4625 14 6.5C14 5.04131 13.4205 3.64236 12.3891 2.61091C11.3576 1.57946 9.95869 1 8.5 1C7.04131 1 5.64236 1.57946 4.61091 2.61091C3.57946 3.64236 3 5.04131 3 6.5C3 8.4625 3.90688 10.5425 5.625 12.5156C6.08226 13.0406 6.57197 13.5363 7.09125 14H4C3.86739 14 3.74021 14.0527 3.64645 14.1464C3.55268 14.2402 3.5 14.3674 3.5 14.5C3.5 14.6326 3.55268 14.7598 3.64645 14.8536C3.74021 14.9473 3.86739 15 4 15H13C13.1326 15 13.2598 14.9473 13.3536 14.8536C13.4473 14.7598 13.5 14.6326 13.5 14.5C13.5 14.3674 13.4473 14.2402 13.3536 14.1464C13.2598 14.0527 13.1326 14 13 14ZM8.5 4.5C8.89556 4.5 9.28224 4.6173 9.61114 4.83706C9.94004 5.05682 10.1964 5.36918 10.3478 5.73463C10.4991 6.10009 10.5387 6.50222 10.4616 6.89018C10.3844 7.27814 10.1939 7.63451 9.91421 7.91421C9.63451 8.19392 9.27814 8.3844 8.89018 8.46157C8.50222 8.53874 8.10009 8.49913 7.73463 8.34776C7.36918 8.19638 7.05682 7.94004 6.83706 7.61114C6.6173 7.28224 6.5 6.89556 6.5 6.5C6.5 5.96957 6.71071 5.46086 7.08579 5.08579C7.46086 4.71071 7.96957 4.5 8.5 4.5Z'
				fill='#2D2D47'
			/>
		</svg>
	)
}

export default MapPinLineIcon
