import './ProgressBar.scss'

interface Props {
	percentile: number
	variant?: 'violet' | 'green'
}

const ProgressBar: React.FC<Props> = (props) => {
	const { variant = 'violet', percentile } = props

	const classNames = () => {
		let classes = 'progress-bar-container'
		if (variant) classes += ` ${variant}`
		return classes
	}

	return (
		<div className={classNames()}>
			<div className={'progress-bar-content'} style={{ width: `${percentile}%` }} />
		</div>
	)
}

export default ProgressBar
