import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const totalSteps = 4

interface Props {
	step: number
}

const FirstStepsCounter: FC<Props> = (props) => {
	const { step } = props

	const { t } = useTranslation()

	return (
		<span className='first-steps-counter'>
			{t('onboarding.first_steps.steps_counter', { step: step, total: totalSteps })}
		</span>
	)
}

export default FirstStepsCounter
