import './DetailsCardRow.scss'

interface Props {
	children: React.ReactNode
}

const DetailsCardRow: React.FC<Props> = (props) => {
	const { children } = props

	return <div className='details-card-row'>{children}</div>
}

export default DetailsCardRow
