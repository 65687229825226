import Search from 'components/elements/Search/Search'
import { debounce } from 'lodash'
import { ChangeEvent, useEffect, useState } from 'react'
import { emptyString } from 'utils/common'

interface Props {
	onSearch: (term: string) => void
}

const DailySearchBar = (props: Props) => {
	const { onSearch } = props
	const [searchTerm, setSearchTerm] = useState(emptyString)

	const debouncedSearch = debounce((term) => {
		onSearch(term)
	}, 300)

	useEffect(() => {
		debouncedSearch(searchTerm)

		return () => {
			debouncedSearch.cancel()
		}
	}, [searchTerm])

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.target.value)
	}

	return <Search onChange={handleSearchChange} />
}

export default DailySearchBar
