import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import Loading from 'components/elements/Loading/Loading'
import ProfileImageInput from 'components/elements/ProfileImageInput/ProfileImageInput'
import { MY_ACCOUNT_URL, MY_COMPANY_EDIT_URL } from 'constants/routes'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCompanyStore } from 'stores/useCompanyStore'
import { useConfigStore } from 'stores/useConfigStore'
import './MyCompany.scss'

const MyCompany: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const companyState = useCompanyStore()
	const configStore = useConfigStore()
	const [loading, setLoading] = useState(false)

	const getCompany = async () => {
		try {
			setLoading(true)
			await companyState.fetchCompany()
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getCompany()
	}, [])

	const detailsInfo = useMemo(() => {
		const province = configStore.getProvinceByCode(companyState.company.province)?.name
		const city = configStore.getMunicipalityByProvinceCodeAndMunicipalityCode(
			companyState.company.province,
			companyState.company.city
		)?.name

		return [
			{ title: t('settings.my_account.my_company.name_label'), value: companyState.company.name },
			{
				title: t('settings.my_account.my_company.nif_label'),
				value: companyState.company.document
			},
			{
				title: t('settings.my_account.my_company.document_type'),
				value: companyState.company.documentType
			},
			{
				title: t('settings.my_account.my_company.main_email_label'),
				value: companyState.company.mainEmail
			},
			{
				title: t('settings.my_account.my_company.website_label'),
				value: companyState.company.website
			},
			{
				title: t('settings.my_account.my_company.telephone_label'),
				value: companyState.company.phone
			},
			{
				title: t('settings.my_account.my_company.address_label'),
				value: companyState.company.address
			},
			{
				title: t('settings.my_account.my_company.province_label'),
				value: province
			},
			{ title: t('settings.my_account.my_company.city_label'), value: city },
			{
				title: t('settings.my_account.my_company.post_code_label'),
				value: companyState.company.zipCode
			}
		]
	}, [companyState])

	if (loading) return <Loading fullscreen />

	return (
		<FormLayout
			title={t('settings.my_account.my_company.title')}
			leftButton={<ButtonBack url={MY_ACCOUNT_URL} />}
			rightButton={
				<ButtonComponent onClick={() => navigate(MY_COMPANY_EDIT_URL)} variant='only-text'>
					{t('general.edit')}
				</ButtonComponent>
			}>
			<div className='my-company'>
				<div className='my-company-profile-image'>
					<ProfileImageInput />
				</div>
				<DetailsCard>
					{detailsInfo.map((item) => (
						<DetailsCardItem key={item.title} title={item.title}>
							{item.value}
						</DetailsCardItem>
					))}
				</DetailsCard>
			</div>
		</FormLayout>
	)
}

export default MyCompany
