import { ItemDropdown } from 'components/elements/DropdownSelector/DropdownSelector'
import InputButton from 'components/elements/InputButton/InputButton'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'
import { useModal } from 'hooks/useModal'
import { RefObject, forwardRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString, firstElement } from 'utils/common'

interface Props {
	onSelect: (id: number) => void
	selectedId?: number | null
	error?: string
	label: string
	placeholder?: string
	isLabelOptional?: boolean
	id?: string
}

const SelecteDocumentTypeOptions = forwardRef<HTMLElement, Props>((props, ref) => {
	const {
		onSelect,
		selectedId,
		error,
		label,
		placeholder = emptyString,
		isLabelOptional = false,
		id = 'document-type'
	} = props

	const { t } = useTranslation()
	const [modalIsVisible, toggleModalVisibility] = useModal()
	const documentState = useConfigStore()
	const [items, setItems] = useState<ItemDropdown[]>([])

	const dynamicPlaceholder = selectedId
		? documentState.documentTypes.byId[selectedId]?.name || t('general.unknown')
		: placeholder

	useEffect(() => {
		const itemsDropdownModal = documentState.documentTypes.allIds.map((id) => {
			const documentType = documentState.documentTypes.byId[id]
			return { id: documentType.id, name: documentType.name }
		})
		setItems(itemsDropdownModal)
	}, [documentState])

	const handleClick = (ids: number[]) => {
		onSelect(ids[firstElement])
		toggleModalVisibility()
	}

	const valueSelected = selectedId ? documentState.documentTypes.byId[selectedId]?.name : undefined

	return (
		<div className={`select-option${error ? ' error' : emptyString}`}>
			<label htmlFor={id}>
				{label}
				{isLabelOptional && <span className='helper-text'> ({t('general.optional')})</span>}
			</label>
			<InputButton
				ref={ref as RefObject<HTMLButtonElement>}
				type='select'
				id={id}
				onClick={toggleModalVisibility}
				placeholder={dynamicPlaceholder}
				text={valueSelected}
				error={error}
			/>

			<DropdownSelectorModal
				title={t('form.select_at', { field: t('general.document_type').toLocaleLowerCase() })}
				visible={modalIsVisible}
				items={items}
				toggleVisibility={toggleModalVisibility}
				handleClick={handleClick}
			/>

			{error && <span className='input-error-message'>{error}</span>}
		</div>
	)
})

export default SelecteDocumentTypeOptions
