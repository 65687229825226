import React from 'react'

interface Props {
	width?: number
	height?: number
	fill?: string
}

const CommunicatedFomento: React.FC<Props> = (props) => {
	const { width = 15, height = 15, fill = '#713FFF' } = props

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 17 17'
			fill='none'>
			<path
				d='M14.7289 11.6835C14.3603 11.0487 13.8125 9.25238 13.8125 6.90625C13.8125 5.49729 13.2528 4.14603 12.2565 3.14975C11.2602 2.15346 9.90893 1.59375 8.49996 1.59375C7.091 1.59375 5.73975 2.15346 4.74346 3.14975C3.74717 4.14603 3.18746 5.49729 3.18746 6.90625C3.18746 9.25305 2.63895 11.0487 2.27039 11.6835C2.17628 11.8449 2.12638 12.0283 2.12574 12.2151C2.1251 12.4019 2.17373 12.5856 2.26674 12.7477C2.35975 12.9097 2.49384 13.0444 2.65549 13.138C2.81714 13.2317 3.00063 13.2811 3.18746 13.2812H5.8975C6.02007 13.881 6.34603 14.42 6.82024 14.8071C7.29445 15.1942 7.88781 15.4057 8.49996 15.4057C9.11211 15.4057 9.70547 15.1942 10.1797 14.8071C10.6539 14.42 10.9799 13.881 11.1024 13.2812H13.8125C13.9992 13.281 14.1826 13.2315 14.3442 13.1378C14.5058 13.0441 14.6398 12.9094 14.7327 12.7474C14.8256 12.5854 14.8742 12.4017 14.8735 12.2149C14.8729 12.0282 14.823 11.8449 14.7289 11.6835ZM8.49996 14.3438C8.17047 14.3436 7.8491 14.2414 7.5801 14.0511C7.3111 13.8609 7.10768 13.5919 6.99785 13.2812H10.0021C9.89224 13.5919 9.68883 13.8609 9.41982 14.0511C9.15082 14.2414 8.82946 14.3436 8.49996 14.3438Z'
				fill={fill}
			/>
		</svg>
	)
}

export default CommunicatedFomento
