import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CaretDownIcon: FC<Props> = (props) => {
	const { width = 17, height = 17 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M8.5 1.59375C7.13408 1.59375 5.79882 1.99879 4.6631 2.75766C3.52737 3.51653 2.64218 4.59514 2.11946 5.85709C1.59674 7.11904 1.45998 8.50766 1.72645 9.84734C1.99293 11.187 2.65069 12.4176 3.61655 13.3835C4.5824 14.3493 5.81298 15.0071 7.15266 15.2735C8.49234 15.54 9.88096 15.4033 11.1429 14.8805C12.4049 14.3578 13.4835 13.4726 14.2423 12.3369C15.0012 11.2012 15.4063 9.86593 15.4063 8.5C15.4043 6.66894 14.6761 4.91343 13.3813 3.61868C12.0866 2.32393 10.3311 1.59568 8.5 1.59375ZM11.5321 7.81336L8.87586 10.4696C8.82652 10.519 8.76793 10.5582 8.70344 10.5849C8.63895 10.6117 8.56982 10.6254 8.5 10.6254C8.43019 10.6254 8.36106 10.6117 8.29657 10.5849C8.23207 10.5582 8.17348 10.519 8.12414 10.4696L5.46789 7.81336C5.36821 7.71368 5.31221 7.57847 5.31221 7.4375C5.31221 7.29653 5.36821 7.16132 5.46789 7.06164C5.56758 6.96196 5.70278 6.90595 5.84375 6.90595C5.98473 6.90595 6.11993 6.96196 6.21961 7.06164L8.5 9.3427L10.7804 7.06164C10.8298 7.01228 10.8883 6.97313 10.9528 6.94642C11.0173 6.9197 11.0864 6.90595 11.1563 6.90595C11.2261 6.90595 11.2952 6.9197 11.3597 6.94642C11.4242 6.97313 11.4828 7.01228 11.5321 7.06164C11.5815 7.111 11.6206 7.1696 11.6473 7.23409C11.674 7.29858 11.6878 7.3677 11.6878 7.4375C11.6878 7.5073 11.674 7.57642 11.6473 7.64091C11.6206 7.7054 11.5815 7.764 11.5321 7.81336Z'
				fill='#fff'
			/>
		</svg>
	)
}

export default CaretDownIcon
