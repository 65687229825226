import { FC, LegacyRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SwipeAction, SwipeableListItem, TrailingActions, Type } from 'react-swipeable-list'
import 'react-swipeable-list/dist/styles.css'
import './SwipeListItem.scss'

const trailingActionsWidth = 300

export const enum SwipeListItemMode {
	activate = 'activate',
	deactivate = 'deactivate'
}

interface Props {
	mode: SwipeListItemMode
	children: React.ReactNode
	onEdit: () => void
	onDelete: () => void
	onAction: () => void
	onClick: () => void
}

interface SwipeableListItemRef {
	trailingActionsWidth?: number
	trailingActionsOpened?: boolean
}

const SwipeListItem: FC<Props> = (props) => {
	const { mode, children, onEdit, onDelete, onAction, onClick } = props

	const { t } = useTranslation()
	const swipeableListItemRef = useRef<SwipeableListItemRef>()
	const [swipeableIsOpen, setSwipeableIsOpen] = useState(false)

	useEffect(() => {
		if (swipeableListItemRef.current)
			swipeableListItemRef.current.trailingActionsWidth = trailingActionsWidth
	}, [])

	const onSwipeEnd = () => {
		if (swipeableListItemRef.current && !swipeableIsOpen) {
			swipeableListItemRef.current.trailingActionsOpened = true
		}
		setSwipeableIsOpen((prev) => !prev)
	}

	return (
		<SwipeableListItem
			ref={swipeableListItemRef as LegacyRef<SwipeableListItem>}
			className={`swipe-list-item ${mode}`}
			listType={Type.IOS}
			onClick={onClick}
			onSwipeEnd={onSwipeEnd}
			trailingActions={
				<TrailingActions>
					<SwipeAction Tag='div' destructive onClick={onAction}>
						{mode === SwipeListItemMode.activate ? t('general.activate') : t('general.deactivate')}
					</SwipeAction>
					<SwipeAction Tag='div' destructive onClick={onDelete}>
						{t('general.remove')}
					</SwipeAction>
					<SwipeAction Tag='div' onClick={onEdit}>
						{t('general.edit')}
					</SwipeAction>
				</TrailingActions>
			}>
			{children}
		</SwipeableListItem>
	)
}

export default SwipeListItem
