import AssociationListButtons from 'components/elements/AssociationList/AssociationListButtons/AssociationListButtons'
import AssociationListModal from 'components/elements/AssociationList/AssociationListModal/AssociationListModal'
import { ItemDropdown } from 'components/elements/DropdownSelector/DropdownSelector'
import { TooltipProps } from 'components/elements/Tooltip/Tooltip'
import { useEffect, useState } from 'react'

export interface AssociationOption {
	id: number
	name: string
	isSelected: boolean
}

export interface AssociationModalContent {
	title: string
	subtitle?: string
	description?: string
}

interface Props {
	associations: AssociationOption[]
	title: string
	addButtonText: string
	modalContent: AssociationModalContent
	isMultiple?: boolean
	tooltip?: TooltipProps
	handleSelection: (selected: AssociationOption[]) => void
	isLoading: boolean
}

const AssociationList: React.FC<Props> = (props) => {
	const {
		associations,
		title,
		addButtonText,
		modalContent,
		isMultiple = true,
		tooltip,
		handleSelection,
		isLoading
	} = props

	const [showAssociationModal, setShowAssociationModal] = useState(false)
	const [associationOptions, setAssociationOptions] = useState<AssociationOption[]>(associations)

	useEffect(() => {
		setAssociationOptions(associations)
	}, [associations])

	const handleSelectedAssociation = (selected: ItemDropdown[]) => {
		const newAssociationOptions = associationOptions.map((association) => {
			const isSelected = selected.some(
				(selectedAssociation) => selectedAssociation.id === association.id
			)
			return { ...association, isSelected }
		})
		setAssociationOptions(newAssociationOptions)
		handleSelection(newAssociationOptions)
	}

	const onRemoveAssociation = (id: number) => {
		const newAssociationOptions = associationOptions.map((association) => {
			if (association.id === id) return { ...association, isSelected: false }
			return association
		})
		setAssociationOptions(newAssociationOptions)
		handleSelection(newAssociationOptions)
	}

	return (
		<>
			<AssociationListButtons
				associations={associationOptions.filter((association) => association.isSelected)}
				buttonsTitle={title}
				addButtonText={addButtonText}
				tooltip={tooltip}
				onAdd={() => setShowAssociationModal(true)}
				onRemove={onRemoveAssociation}
				isLoading={isLoading}
			/>
			<AssociationListModal
				title={modalContent.title}
				subtitle={modalContent.subtitle}
				description={modalContent.description}
				isVisible={showAssociationModal}
				toggleVisibility={() => setShowAssociationModal(false)}
				handleSelection={handleSelectedAssociation}
				associations={associationOptions}
				isMultiple={isMultiple}
			/>
		</>
	)
}

export default AssociationList
