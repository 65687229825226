import NumberAdjuster from 'components/elements/NumberAdjuster/NumberAdjuster'
import SelectDriverOptions from 'components/elements/SelectOptions/SelectDriverOptions'
import SelectProviderOptions from 'components/elements/SelectOptions/SelectProviderOptions'
import SelectVehicleOptions from 'components/elements/SelectOptions/SelectVehicleOptions'
import { FormikProps } from 'formik'
import { AllStepForms, StepDetailsForm } from 'models/Transfer'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormikError } from 'utils/formikUtils'

interface Props {
	formik: FormikProps<StepDetailsForm> | FormikProps<AllStepForms>
}

const StepDetailsInputs: FC<Props> = (props) => {
	const { formik } = props

	const { t } = useTranslation()

	return (
		<>
			<SelectProviderOptions
				onSelect={(providerId) => formik.setFieldValue('providerId', providerId)}
				selected={formik.values['providerId']}
				error={getFormikError(formik as FormikProps<StepDetailsForm>, 'providerId')}
				label={t('transfer.provider')}
			/>
			<SelectDriverOptions
				onSelect={(driverId) => formik.setFieldValue('driverId', driverId)}
				selectedId={formik.values['driverId']}
				error={getFormikError(formik as FormikProps<StepDetailsForm>, 'driverId')}
				label={t('transfer.driver')}
			/>
			<SelectVehicleOptions
				onSelect={(vehicleId) => formik.setFieldValue('vehicleId', vehicleId)}
				selectedId={formik.values['vehicleId']}
				error={getFormikError(formik as FormikProps<StepDetailsForm>, 'vehicleId')}
				label={t('transfer.vehicle')}
				isLabelOptional
			/>
			<NumberAdjuster
				dataTest='pax'
				style={{ width: '4ch' }}
				title={t('transfer.numberOfPassengers')}
				onChange={(passengers) => formik.setFieldValue('passengers', passengers)}
				max={60}
				valueSelected={formik.values['passengers']}
			/>
		</>
	)
}

export default StepDetailsInputs
