import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Loading from 'components/elements/Loading/Loading'
import PdfViewer from 'components/elements/PdfViewer/PdfViewer'
import { ERROR_URL, HOME_URL, TRANSFER_URL } from 'constants/routes'
import useFetchPdf from 'hooks/useFetchPdf'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { endpoints } from 'services/endpoints'
import './FomentoPdf.scss'

const FomentoCommunicationPdf = () => {
	const { transferId } = useParams()
	const { pdfBase64, isLoading, error } = useFetchPdf(
		endpoints.fomentoCommunicationPdf + '/' + transferId
	)
	const { t } = useTranslation()
	const navigate = useNavigate()

	if (isLoading) return <Loading fullscreen />
	if (error)
		navigate(ERROR_URL, {
			state: { description: error, link: `${TRANSFER_URL}/${transferId}` }
		})
	if (!pdfBase64) return null

	return (
		<FormLayout
			title={t('fomento.communicated_document')}
			leftButton={<ButtonBack url={HOME_URL} />}>
			<div className='fomento-pdf-container'>
				<PdfViewer pdf={pdfBase64} />
			</div>
		</FormLayout>
	)
}

export default FomentoCommunicationPdf
