import StepPricesInputs from 'components/Transfer/StepInputs/StepPricesInputs'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { StepPaymentForm, TransferPost } from 'models/Transfer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTransfersStore } from 'stores/useTransfersStore'
import { emptyString } from 'utils/common'
import * as Yup from 'yup'

const StepPrices = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const transferState = useTransfersStore()

	const formik = useFormik<StepPaymentForm>({
		initialValues: {
			price: null,
			commission: null,
			toll: null,
			isPaid: false
		},

		validationSchema: Yup.object().shape({
			price: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			commission: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			toll: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			isPaid: Yup.boolean()
		}),
		onSubmit: (values) => {
			const transferPost: Partial<TransferPost> = {
				price: values.price ? parseFloat(values.price.toString()) : 0,
				commission: values.commission ? parseFloat(values.commission.toString()) : 0,
				toll: values.toll ? parseFloat(values.toll.toString()) : 0,
				isPaid: values.isPaid
			}
			transferState.updateActualTransferCreation(transferPost)
			navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.CLIENT.toLocaleLowerCase()}`)
		}
	})
	return (
		<FormTransferLayout onSubmit={() => formik.handleSubmit()} step={5}>
			<FormSectionLayout title={t('transfer.create_steps.step_prices.h1')} noDivider>
				<StepPricesInputs formik={formik} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepPrices
