import { CaretCircleLeft, WarningIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { DAILY_URL } from 'constants/routes'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import './ErrorPage.scss'

const ErrorPage: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { state } = useLocation()

	return (
		<div className='error-page'>
			<div className='error-page-container'>
				<div className='error-page-icon-container'>
					<WarningIcon />
				</div>
				<h1>{state?.title || 'Error'}</h1>
				<h3 className='error-page-description'>
					{state?.description || t('errors.unexpected_error')}
				</h3>
			</div>
			<ButtonComponent
				className='error-page-return-button'
				variant='text'
				onClick={() => navigate(state?.link || DAILY_URL)}
				leftIcon={<CaretCircleLeft />}>
				{t('general.return')}
			</ButtonComponent>
		</div>
	)
}

export default ErrorPage
