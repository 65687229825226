import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CloseIcon: FC<Props> = (props) => {
	const { width = 30, height = 30 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z'
				fill='#767680'
				fillOpacity='0.12'
			/>
			<path
				d='M10.2891 19C9.90625 19.375 9.89844 20.0625 10.2969 20.4609C10.6953 20.8594 11.3828 20.8438 11.7578 20.4688L15.3828 16.8438L19.0078 20.4609C19.3984 20.8516 20.0703 20.8594 20.4688 20.4531C20.8672 20.0547 20.8672 19.3828 20.4766 18.9922L16.8594 15.375L20.4766 11.75C20.8672 11.3594 20.8672 10.6875 20.4688 10.2891C20.0703 9.88281 19.3984 9.89062 19.0078 10.2812L15.3828 13.8984L11.7578 10.2734C11.3828 9.89844 10.6953 9.88281 10.2969 10.2812C9.89844 10.6797 9.90625 11.3672 10.2891 11.75L13.9141 15.375L10.2891 19Z'
				fill='#3C3C43'
				fillOpacity='0.6'
			/>
		</svg>
	)
}

export default CloseIcon
