import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import GenericList from 'components/elements/GenericList/GenericList'
import ListItem from 'components/elements/List/ListItem/ListItem'
import {
	NOTIFICATIONS_URL,
	TRANSFER_RECEIVE_URL,
	TRANSFER_REQUESTS_URL,
	TRANSFER_URL
} from 'constants/routes'
import useSearch from 'hooks/useSearch'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { RequestTypeTab } from 'models/Elements'
import { SharedTransfer } from 'models/Transfer'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { useTransfersShareStore } from 'stores/useTransfersShareStore'
import { emptyString, zeroElements } from 'utils/common'
import { dateToSpanish } from 'utils/dateUtils'
import './TransferRequests.scss'

const enum searchKey {
	sendingCompanyName = 'sendingCompanyName',
	receivingCompanyName = 'receivingCompanyName'
}

const TransferRequests: FC = () => {
	const { requestType } = useParams()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const transferShareState = useTransfersShareStore()

	const [transferRequests, setTransferRequests] = useState<SharedTransfer[]>([])
	const [searchTerm, setSearchTerm] = useState(emptyString)
	const hasItems = transferRequests.length > zeroElements

	const [keySearch, setKeySearch] = useState(searchKey.sendingCompanyName)
	const searchResults = useSearch(transferRequests, [keySearch], searchTerm)

	const [requestTab, setRequestTab] = useState(requestType ?? RequestTypeTab.received)

	const handleClick = (transferRequest: SharedTransfer) => {
		const id =
			requestTab === RequestTypeTab.received ? transferRequest.id : transferRequest.bookingId
		const url = requestTab === RequestTypeTab.received ? TRANSFER_RECEIVE_URL : TRANSFER_URL
		navigate(`${url}/${id}`)
	}

	const cancelRequest = async (transferRequestId: number) => {
		try {
			await transferShareState.cancelTransferSharedRequest(transferRequestId)
			toast.success(t('transfer_requests.accepted'))
		} catch (error) {
			toast.error(t('transfer_requests.accepted_error'))
		}
	}

	useEffect(() => {
		if (requestTab === RequestTypeTab.received) {
			setKeySearch(searchKey.sendingCompanyName)
			const transferRequestsReceived = transferShareState.getAllTransferSharedRequestsReceived()
			navigate(`${TRANSFER_REQUESTS_URL}/${RequestTypeTab.received}`)
			return setTransferRequests(transferRequestsReceived)
		}
		setKeySearch(searchKey.receivingCompanyName)
		const transferRequestsSent = transferShareState.getAllTransferSharedRequestsSent()
		setTransferRequests(transferRequestsSent)
		navigate(`${TRANSFER_REQUESTS_URL}/${RequestTypeTab.sent}`)
	}, [requestTab, transferShareState.sharedTransfers])

	useEffect(() => {
		transferShareState.fetchAllTransferSharedRequests()
	}, [])

	return (
		<FormLayout
			title={t('transfer_requests.title')}
			leftButton={<ButtonBack url={NOTIFICATIONS_URL} />}>
			<GenericList
				hasItems={hasItems}
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				hasResults={!!searchResults.length}
				innerHeader={
					<div className='transfer-requests-tabs-header'>
						<ButtonComponent
							className={`transfer-requests-switch-button ${
								requestTab === RequestTypeTab.received ? ' active' : emptyString
							}`}
							onClick={() => setRequestTab(RequestTypeTab.received)}>
							{t('general.received')}
						</ButtonComponent>
						<ButtonComponent
							className={`transfer-requests-switch-button ${
								requestTab === RequestTypeTab.sent ? ' active' : emptyString
							}`}
							onClick={() => setRequestTab(RequestTypeTab.sent)}>
							{t('general.sent')}
						</ButtonComponent>
					</div>
				}
				noItemsText={
					<Trans
						i18nKey='transfer_requests.no_items_text'
						components={{
							span: <span />
						}}
					/>
				}
				noResultsText={
					<Trans
						i18nKey='transfer_requests.no_results_text'
						components={{
							span: <span />
						}}
						values={{ query: searchTerm }}
					/>
				}
				searchPlaceholder={t('transfer_requests.search_placeholder')}>
				<>
					{searchResults.map((transferRequest) => {
						return (
							<ListItem
								key={transferRequest.id}
								right={
									<div className='button-container'>
										<ButtonComponent
											className='transfer-requests-request-item-button'
											onClick={() => handleClick(transferRequest)}>
											{t('general.details')}
										</ButtonComponent>
										{requestTab === RequestTypeTab.sent && (
											<ButtonComponent
												className='transfer-requests-request-item-button'
												variant='cancel'
												onClick={() => cancelRequest(transferRequest.id)}>
												{t('general.cancel')}
											</ButtonComponent>
										)}
									</div>
								}>
								{requestTab === RequestTypeTab.received
									? transferRequest.sendingCompanyName
									: transferRequest.receivingCompanyName}
								<span>{dateToSpanish(new Date(transferRequest.bookingDate))}</span>
							</ListItem>
						)
					})}
				</>
			</GenericList>
		</FormLayout>
	)
}

export default TransferRequests
