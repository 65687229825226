import { IdentificationCardIcon, UserGear, UsersThree, WhatsappIcon } from 'assets/svgs'
import ActionButton from 'components/elements/ActionButton/ActionButton'
import ButtonComponent from 'components/elements/Button/Button'
import HeaderWithNotificationBell from 'components/elements/HeaderWithNotificationBell/HeaderWithNotificationBell'
import { LOGOUT_URL, MY_ACCOUNT_URL, MY_PROFILE_URL, USERS_URL } from 'constants/routes'
import useSupport from 'hooks/useSupport'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
import { Langs } from 'models/Settings'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLanguageStore } from 'stores/useLanguageStore'
import { currentUserIsAdmin } from 'utils/currentUser'
import './Settings.scss'

const SettingPage = () => {
	const languageStore = useLanguageStore()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const isAdmin = currentUserIsAdmin()
	const handleSupport = useSupport()

	const handleLanguageChange = (lang: keyof typeof Langs) => {
		languageStore.setLanguage(lang)
	}

	const options = useMemo(
		() => [
			{
				label: t('settings.account'),
				icon: <UserGear />,
				backGroundIcon: '#f0f2ff',
				onClick: () => navigate(MY_ACCOUNT_URL),
				skip: !isAdmin
			},
			{
				label: t('settings.users'),
				icon: <UsersThree />,
				backGroundIcon: '#f0f2ff',
				onClick: () => navigate(USERS_URL),
				skip: !isAdmin
			},
			{
				label: t('settings.my_account.my_profile'),
				icon: <IdentificationCardIcon />,
				backGroundIcon: '#f0f2ff',
				onClick: () => navigate(MY_PROFILE_URL),
				skip: isAdmin
			},
			{
				label: t('settings.support'),
				icon: <WhatsappIcon />,
				backGroundIcon: '#60D669',
				onClick: handleSupport,
				skip: false
			}
		],
		[t]
	)
	return (
		<DashboardLayout>
			<div className='settings-container'>
				<div className='settings-header-container'>
					<HeaderWithNotificationBell title={t('general.settings')} />
				</div>
				<div className='settings-options'>
					{options
						.filter((o) => !o.skip)
						.map((option, index) => (
							<ActionButton
								key={index}
								icon={option.icon}
								backGroundIcon={option.backGroundIcon}
								onClick={option.onClick}>
								{option.label}
							</ActionButton>
						))}
				</div>

				<ButtonComponent onClick={() => navigate(LOGOUT_URL)}>{t('iam.logout')}</ButtonComponent>

				<ButtonComponent variant='only-text' onClick={() => handleLanguageChange(Langs.ES)}>
					{t('general.spanish')}
				</ButtonComponent>

				<ButtonComponent variant='only-text' onClick={() => handleLanguageChange(Langs.EN)}>
					{t('general.english')}
				</ButtonComponent>
			</div>
		</DashboardLayout>
	)
}

export default SettingPage
