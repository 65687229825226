import { addMonths, format, isSameDay, isTomorrow } from 'date-fns'
import { enUS as en, es } from 'date-fns/locale'
import { Langs } from 'models/Settings'

import { useLanguageStore } from 'stores/index'
import { firstElement } from './common'

const getDateFNSLocaleFromStore = () => {
	const language = useLanguageStore.getState().language
	if (Langs.EN === language) {
		return en
	}
	return es
}

export const dateToSQL = (date?: Date) => {
	const dateToFormat = date ? date : new Date()
	return format(dateToFormat, 'yyyy-MM-dd')
}

export const dateToSpanish = (date: Date | undefined) => {
	const dateToFormat = date ? date : new Date()
	return format(dateToFormat, 'dd/MM/yyyy')
}

export const monthAndYear = (date: Date) => {
	const locale = getDateFNSLocaleFromStore()
	return format(date, 'MMMM yyyy', { locale })
}

export const weekName = (date: Date) => {
	const locale = getDateFNSLocaleFromStore()
	return format(date, 'eee', { locale })
}

export const isValidDate = (date?: string) => {
	if (!date) return false
	const dateRegex = /^\d{4}-\d{2}-\d{2}$/
	return dateRegex.test(date)
}

export const nextDateSqlFormat = (date: Date) => {
	const newDate = new Date(date)
	newDate.setDate(newDate.getDate() + 1)
	return format(newDate, 'yyyy-MM-dd')
}

export const previousDateSqlFormat = (date: Date) => {
	const newDate = new Date(date)
	newDate.setDate(newDate.getDate() - 1)
	return format(newDate, 'yyyy-MM-dd')
}

export const dateToSpanishToTimestamp = (date: Date) => {
	return date.getTime()
}

export const timeToSpanish = (time: string) => {
	return time.slice(0, 5)
}

export const dateIsToday = (date: Date) => {
	const today = new Date()
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	)
}

export const dateToHoursAndMinutes = (date: Date) => {
	return format(date, 'HH:mm')
}

export const formatDateToIsoString = (date: Date) => {
	return date.toISOString().split('T')[firstElement]
}

export const getDateRange = (optionId: number) => {
	const last15days = 15
	const last30days = 30
	const last90days = 90
	const last365days = 365

	const today = new Date()
	let startDate, endDate

	switch (optionId) {
		case 0: // Últimos 15 días
			startDate = new Date()
			startDate.setDate(today.getDate() - last15days)
			endDate = today
			break

		case 1: // Últimos 30 días
			startDate = new Date()
			startDate.setDate(today.getDate() - last30days)
			endDate = today
			break

		case 2: // Últimos 90 días
			startDate = new Date()
			startDate.setDate(today.getDate() - last90days)
			endDate = today
			break

		case 3: // Últimos 365 días
			startDate = new Date()
			startDate.setDate(today.getDate() - last365days)
			endDate = today
			break

		default:
			throw new Error('Not valid option')
	}

	return {
		startDate: formatDateToIsoString(startDate),
		endDate: formatDateToIsoString(endDate)
	}
}

export const formatCustomRangeDate = (fecha: Date) => {
	const opciones: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' }
	return fecha.toLocaleDateString('es-ES', opciones)
}

export const checkIfDateIsTomorrow = (date: Date) => {
	return isTomorrow(date)
}

export const checkIfDateIsOneMonthLaterFromToday = (date: Date) => {
	const NUM_MONTHS_TO_ADD = 1
	const today = new Date()
	const nextMonth = addMonths(today, NUM_MONTHS_TO_ADD)
	return isSameDay(nextMonth, date)
}
