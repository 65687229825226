import { ArrowLeft } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { Modal } from 'components/elements/Modal/Modal'
import FormLayout from 'layouts/FormLayout/FormLayout'
import React from 'react'
import './ModalFormLayout.scss'

interface Props {
	isVisible: boolean
	toggleVisibility: () => void
	title: string
	children: React.ReactNode
	footer?: React.ReactNode
	right?: React.ReactNode
}

const ModalFormLayout: React.FC<Props> = (props) => {
	const { isVisible, toggleVisibility, title, children, footer, right } = props

	return (
		<Modal
			customClassNames='modal-form-layout'
			isVisible={isVisible}
			toggleVisibility={toggleVisibility}
			isFull
			showCloseButton={false}>
			<FormLayout
				title={title}
				leftButton={
					<ButtonComponent variant='only-icon' onClick={toggleVisibility} icon={<ArrowLeft />} />
				}
				rightButton={right && right}
				footer={footer && <div className='modal-form-footer-layout'>{footer}</div>}>
				{children}
			</FormLayout>
		</Modal>
	)
}

export default ModalFormLayout
