import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const RoadHorizon: FC<Props> = (props) => {
	const { width = 32, height = 32 } = props

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'>
			<path
				d='M29.4899 24.875C29.3751 24.9397 29.2486 24.9811 29.1178 24.9968C28.9869 25.0125 28.8542 25.0021 28.7274 24.9663C28.6005 24.9306 28.482 24.8701 28.3786 24.7883C28.2752 24.7066 28.189 24.6052 28.1249 24.49L19.4149 9H16.9999V10C16.9999 10.2652 16.8946 10.5196 16.707 10.7071C16.5195 10.8946 16.2651 11 15.9999 11C15.7347 11 15.4804 10.8946 15.2928 10.7071C15.1053 10.5196 14.9999 10.2652 14.9999 10V9H12.5849L3.87493 24.49C3.74497 24.7221 3.52815 24.893 3.27217 24.9652C3.01618 25.0374 2.742 25.005 2.50993 24.875C2.27787 24.745 2.10693 24.5282 2.03473 24.2722C1.96253 24.0162 1.99497 23.7421 2.12493 23.51L10.2899 9H2.99993C2.73471 9 2.48036 8.89464 2.29282 8.70711C2.10529 8.51957 1.99993 8.26522 1.99993 8C1.99993 7.73478 2.10529 7.48043 2.29282 7.29289C2.48036 7.10536 2.73471 7 2.99993 7H28.9999C29.2651 7 29.5195 7.10536 29.707 7.29289C29.8946 7.48043 29.9999 7.73478 29.9999 8C29.9999 8.26522 29.8946 8.51957 29.707 8.70711C29.5195 8.89464 29.2651 9 28.9999 9H21.7099L29.8749 23.51C29.9397 23.6248 29.9811 23.7513 29.9967 23.8822C30.0124 24.013 30.0021 24.1457 29.9663 24.2726C29.9305 24.3994 29.87 24.5179 29.7882 24.6213C29.7065 24.7247 29.6051 24.8109 29.4899 24.875ZM15.9999 14C15.7347 14 15.4804 14.1054 15.2928 14.2929C15.1053 14.4804 14.9999 14.7348 14.9999 15V17C14.9999 17.2652 15.1053 17.5196 15.2928 17.7071C15.4804 17.8946 15.7347 18 15.9999 18C16.2651 18 16.5195 17.8946 16.707 17.7071C16.8946 17.5196 16.9999 17.2652 16.9999 17V15C16.9999 14.7348 16.8946 14.4804 16.707 14.2929C16.5195 14.1054 16.2651 14 15.9999 14ZM15.9999 21C15.7347 21 15.4804 21.1054 15.2928 21.2929C15.1053 21.4804 14.9999 21.7348 14.9999 22V24C14.9999 24.2652 15.1053 24.5196 15.2928 24.7071C15.4804 24.8946 15.7347 25 15.9999 25C16.2651 25 16.5195 24.8946 16.707 24.7071C16.8946 24.5196 16.9999 24.2652 16.9999 24V22C16.9999 21.7348 16.8946 21.4804 16.707 21.2929C16.5195 21.1054 16.2651 21 15.9999 21Z'
				fill='#2D2D47'
			/>
		</svg>
	)
}

export default RoadHorizon
