import TextArea from 'components/elements/TextArea/TextArea'
import { FormikProps } from 'formik'
import { AllStepForms, StepObservationsForm } from 'models/Transfer'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	formik: FormikProps<StepObservationsForm> | FormikProps<AllStepForms>
}

const StepObservationsInputs: FC<Props> = (props) => {
	const { formik } = props

	const { t } = useTranslation()

	return (
		<TextArea
			valueSelected={formik.values['observations']}
			id='observations'
			label={t('transfer.create_steps.step_observations.inputs.observations.label')}
			onChange={formik.handleChange}
			rows={5}
			placeholder={t('transfer.create_steps.step_observations.inputs.observations.placeholder')}
		/>
	)
}

export default StepObservationsInputs
