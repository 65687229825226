import { BellRingingIcon } from 'assets/svgs'
import { FC } from 'react'
import './Alert.scss'

interface Props {
	type: 'warning' | 'error' | 'success' | 'info'
	message: string
	onClick?: () => void
}

const Alert: FC<Props> = (props) => {
	const {
		type,
		message,
		onClick = () => {
			return
		}
	} = props

	const classNames = () => {
		let classes = 'alert'
		if (type) classes += ` ${type}`

		return classes
	}

	const icon = () => {
		switch (type) {
			case 'warning':
				return <BellRingingIcon color='#ff8e3c' />
			case 'error':
				return <BellRingingIcon color='#e83642' />
			case 'success':
				return <BellRingingIcon color='#34ae89' />
			case 'info':
				return <BellRingingIcon color='#713fff' />
			default:
				return null
		}
	}

	return (
		<div
			role='button'
			className={classNames()}
			onClick={onClick}
			onKeyDown={(e) => {
				if (e.key === 'Enter' || e.key === ' ') {
					onClick()
				}
			}}>
			{icon() ? <span className='icon-container'>{icon()}</span> : null}
			<span className='message'>{message}</span>
		</div>
	)
}

export default Alert
