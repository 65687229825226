import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const FileConfirmedIcon: FC<Props> = (props) => {
	const { width = 22, height = 22 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 32 33'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M26.7075 9.2925L19.7075 2.2925C19.6146 2.19967 19.5042 2.12605 19.3829 2.07586C19.2615 2.02568 19.1314 1.9999 19 2H7C6.46957 2 5.96086 2.21071 5.58579 2.58579C5.21071 2.96086 5 3.46957 5 4V15C5 15.2652 5.10536 15.5196 5.29289 15.7071C5.48043 15.8946 5.73478 16 6 16C6.26522 16 6.51957 15.8946 6.70711 15.7071C6.89464 15.5196 7 15.2652 7 15V4H18V10C18 10.2652 18.1054 10.5196 18.2929 10.7071C18.4804 10.8946 18.7348 11 19 11H25V26H22C21.7348 26 21.4804 26.1054 21.2929 26.2929C21.1054 26.4804 21 26.7348 21 27C21 27.2652 21.1054 27.5196 21.2929 27.7071C21.4804 27.8946 21.7348 28 22 28H25C25.5304 28 26.0391 27.7893 26.4142 27.4142C26.7893 27.0391 27 26.5304 27 26V10C27.0001 9.86864 26.9743 9.73855 26.9241 9.61715C26.8739 9.49576 26.8003 9.38544 26.7075 9.2925ZM20 5.41375L23.5863 9H20V5.41375Z'
				fill='#713FFF'
			/>
			<path
				d='M11 16.6875C9.55373 16.6875 8.13993 17.1164 6.9374 17.9199C5.73486 18.7234 4.7976 19.8654 4.24413 21.2016C3.69067 22.5378 3.54586 24.0081 3.82801 25.4266C4.11017 26.8451 4.80661 28.148 5.82928 29.1707C6.85196 30.1934 8.15492 30.8898 9.57341 31.172C10.9919 31.4541 12.4622 31.3093 13.7984 30.7559C15.1346 30.2024 16.2766 29.2651 17.0801 28.0626C17.8836 26.8601 18.3125 25.4463 18.3125 24C18.3105 22.0612 17.5394 20.2025 16.1685 18.8315C14.7975 17.4606 12.9388 16.6895 11 16.6875ZM14.2105 22.7105L10.273 26.648C10.2207 26.7003 10.1587 26.7418 10.0904 26.7701C10.0221 26.7984 9.94892 26.8129 9.875 26.8129C9.80108 26.8129 9.72789 26.7984 9.6596 26.7701C9.59131 26.7418 9.52928 26.7003 9.47703 26.648L7.78953 24.9605C7.68399 24.8549 7.62469 24.7118 7.62469 24.5625C7.62469 24.4132 7.68399 24.2701 7.78953 24.1645C7.89508 24.059 8.03824 23.9997 8.1875 23.9997C8.33677 23.9997 8.47992 24.059 8.58547 24.1645L9.875 25.4548L13.4145 21.9145C13.4668 21.8623 13.5288 21.8208 13.5971 21.7925C13.6654 21.7642 13.7386 21.7497 13.8125 21.7497C13.8864 21.7497 13.9596 21.7642 14.0279 21.7925C14.0962 21.8208 14.1582 21.8623 14.2105 21.9145C14.2627 21.9668 14.3042 22.0288 14.3325 22.0971C14.3608 22.1654 14.3753 22.2386 14.3753 22.3125C14.3753 22.3864 14.3608 22.4596 14.3325 22.5279C14.3042 22.5962 14.2627 22.6582 14.2105 22.7105Z'
				fill='#45D553'
			/>
		</svg>
	)
}

export default FileConfirmedIcon
