import { FetchResponse, StartEndTransfer } from 'models/index'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getStartEndTransfersByDateService = async (date: string) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<StartEndTransfer[]>>(
			`${endpoints.startEnd}/date/${date}`,
			{
				method: 'GET',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getStartEndTransfersByTransferIdService = async (transferId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<StartEndTransfer[]>>(
			`${endpoints.startEnd}/transfer/${transferId}`,
			{
				method: 'GET',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const startTransferService = async (transferId: number) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<StartEndTransfer>>(
			`${endpoints.startEnd}/start/${transferId}`,
			{
				method: 'POST',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const endTransferService = async (transferId: number) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<StartEndTransfer>>(
			`${endpoints.startEnd}/end/${transferId}`,
			{
				method: 'POST',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}
