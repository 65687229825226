import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CheckIcon: FC<Props> = (props) => {
	const { width = 18, height = 18 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M16.3452 5.66021L7.34521 14.6602C7.26683 14.7389 7.17368 14.8013 7.07112 14.8439C6.96856 14.8865 6.85861 14.9084 6.74756 14.9084C6.63651 14.9084 6.52655 14.8865 6.42399 14.8439C6.32143 14.8013 6.22829 14.7389 6.1499 14.6602L2.2124 10.7227C2.13392 10.6442 2.07166 10.5511 2.02918 10.4485C1.98671 10.346 1.96484 10.2361 1.96484 10.1251C1.96484 10.0141 1.98671 9.90415 2.02918 9.80161C2.07166 9.69906 2.13392 9.60589 2.2124 9.5274C2.29089 9.44892 2.38406 9.38666 2.48661 9.34418C2.58915 9.30171 2.69906 9.27984 2.81006 9.27984C2.92105 9.27984 3.03096 9.30171 3.13351 9.34418C3.23605 9.38666 3.32923 9.44892 3.40771 9.5274L6.74826 12.8679L15.1513 4.46631C15.3098 4.3078 15.5248 4.21875 15.749 4.21875C15.9731 4.21875 16.1881 4.3078 16.3466 4.46631C16.5051 4.62482 16.5942 4.8398 16.5942 5.06396C16.5942 5.28813 16.5051 5.50311 16.3466 5.66162L16.3452 5.66021Z'
				fill='#009951'
			/>
		</svg>
	)
}

export default CheckIcon
