import { createTheme } from '@mui/material/styles'

export const materialTheme = createTheme({
	palette: {
		primary: {
			light: '#ff7d7d',
			main: '#00b5dd',
			dark: '#0099c1',
			contrastText: '#fff'
		},
		secondary: {
			light: '#707479',
			main: '#292F36',
			dark: '#1C2024',
			contrastText: '#fff'
		},
		error: {
			light: '#F8C7C7',
			main: '#F28F8F',
			dark: '#EB5757'
		},
		warning: {
			light: '#FFD9BE',
			main: '#FFB47D',
			dark: '#FF8E3C'
		},
		info: {
			light: '#D4EDFF',
			main: '#AADBFF',
			dark: '#7FC9FF'
		},
		success: {
			light: '#BBE4D8',
			main: '#78C9B0',
			dark: '#34AE89'
		}
	}
})
