import React, { FC } from 'react'
import { handleGeneralKeyboardEvent } from 'utils/functions'
import './SimpleColouredCard.scss'

interface Props {
	icon: React.ReactNode
	title: string
	description?: string
	mainColor: string
	secondaryColor?: string
	onClick?: () => void
}

const SimpleColouredCard: FC<Props> = (props) => {
	const { icon, title, description, mainColor, secondaryColor, onClick } = props
	return (
		<div
			role='button'
			className='simple-card'
			style={{
				backgroundColor: secondaryColor,
				border: `1px solid ${mainColor}`,
				borderLeft: `0.5rem solid ${mainColor}`
			}}
			onClick={onClick}
			onKeyDown={handleGeneralKeyboardEvent(() => onClick?.())}>
			{icon}
			<h3>{title}</h3>
			{description && <p>{description}</p>}
		</div>
	)
}

export default SimpleColouredCard
