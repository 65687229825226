import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const StoreFrontIcon: FC<Props> = (props) => {
	const { width = 20, height = 20 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M18.125 7.5C18.1253 7.44189 18.1174 7.38403 18.1016 7.32812L16.9805 3.40625C16.9051 3.14603 16.7476 2.91716 16.5315 2.75382C16.3153 2.59049 16.0522 2.50145 15.7812 2.5H4.21875C3.94784 2.50145 3.68467 2.59049 3.46853 2.75382C3.25239 2.91716 3.09488 3.14603 3.01953 3.40625L1.89922 7.32812C1.88308 7.38399 1.87493 7.44185 1.875 7.5V8.75C1.875 9.23514 1.98795 9.71362 2.20492 10.1475C2.42188 10.5815 2.73689 10.9589 3.125 11.25V16.25C3.125 16.5815 3.2567 16.8995 3.49112 17.1339C3.72554 17.3683 4.04348 17.5 4.375 17.5H15.625C15.9565 17.5 16.2745 17.3683 16.5089 17.1339C16.7433 16.8995 16.875 16.5815 16.875 16.25V11.25C17.2631 10.9589 17.5781 10.5815 17.7951 10.1475C18.012 9.71362 18.125 9.23514 18.125 8.75V7.5ZM4.21875 3.75H15.7812L16.6734 6.875H3.32891L4.21875 3.75ZM8.125 8.125H11.875V8.75C11.875 9.24728 11.6775 9.72419 11.3258 10.0758C10.9742 10.4275 10.4973 10.625 10 10.625C9.50272 10.625 9.0258 10.4275 8.67417 10.0758C8.32254 9.72419 8.125 9.24728 8.125 8.75V8.125ZM6.875 8.125V8.75C6.875 9.24728 6.67746 9.72419 6.32583 10.0758C5.9742 10.4275 5.49728 10.625 5 10.625C4.50272 10.625 4.02581 10.4275 3.67418 10.0758C3.32254 9.72419 3.125 9.24728 3.125 8.75V8.125H6.875ZM15.625 16.25H4.375V11.8125C4.58075 11.854 4.79011 11.8749 5 11.875C5.48514 11.875 5.96362 11.762 6.39754 11.5451C6.83147 11.3281 7.20892 11.0131 7.5 10.625C7.79108 11.0131 8.16853 11.3281 8.60246 11.5451C9.03638 11.762 9.51486 11.875 10 11.875C10.4851 11.875 10.9636 11.762 11.3975 11.5451C11.8315 11.3281 12.2089 11.0131 12.5 10.625C12.7911 11.0131 13.1685 11.3281 13.6025 11.5451C14.0364 11.762 14.5149 11.875 15 11.875C15.2099 11.8749 15.4192 11.854 15.625 11.8125V16.25ZM15 10.625C14.5027 10.625 14.0258 10.4275 13.6742 10.0758C13.3225 9.72419 13.125 9.24728 13.125 8.75V8.125H16.875V8.75C16.875 9.24728 16.6775 9.72419 16.3258 10.0758C15.9742 10.4275 15.4973 10.625 15 10.625Z'
				fill='#130D2A'
			/>
		</svg>
	)
}

export default StoreFrontIcon
