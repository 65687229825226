import ButtonComponent from 'components/elements/Button/Button'
import { DAILY_URL } from 'constants/routes'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './NotFound.scss'

const NotFound: FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<div className='not-found'>
			<div className='not-found-container'>
				<h1>{t('not_found.title')}</h1>
				<h3>{t('not_found.subtitle')}</h3>
				<p className='body1 not-found-description'>{t('not_found.description')}</p>
			</div>
			<ButtonComponent variant='text' onClick={() => navigate(DAILY_URL)}>
				{t('not_found.action')}
			</ButtonComponent>
		</div>
	)
}
export default NotFound
