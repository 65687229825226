import { ENVIRONMENT } from 'constants/environment'
import { VehiclePost, VehicleTechinicalPost, type Vehicle } from 'models/Vehicle'
import { normalize } from 'normalizr'
import { vehicleSchema } from 'schemas/index'
import {
	createVehicleService,
	deleteVehicleById,
	deleteVehicleService,
	getAllVehiclesService,
	getVehicleById,
	updateVehicleService,
	updateVehicleTechnicalService
} from 'services/vehicles'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface VehiclesState {
	vehicles: {
		byId: { [id: number]: Vehicle }
		allIds: number[]
	}
	addVehicle: (vehicle: Vehicle) => void
	removeVehicle: (vehicleId: number) => Promise<void>
	fetchVehicles: () => Promise<Vehicle[]>
	fetchVehicleById: (id: number) => Promise<Vehicle>
	resetVehicles: () => void
	createVehicle: (vehicle: VehiclePost) => Promise<Vehicle>
	updateVehicle: (vehicle: Partial<VehiclePost>, vehicleId: number) => Promise<Vehicle>
	updateVehicleTechnical: (vehicle: VehicleTechinicalPost, vehicleId: number) => Promise<Vehicle>
	deleteVehicle: (vehicleId: number) => Promise<void>
}

export const useVehiclesStore = create<VehiclesState>()(
	devtools(
		(set) => ({
			vehicles: {
				byId: {},
				allIds: [] as number[]
			},
			addVehicle: (vehicle) => {
				const normalizedVehicle = normalize(vehicle, vehicleSchema)
				set((state) => ({
					vehicles: {
						byId: { ...state.vehicles.byId, ...normalizedVehicle.entities.vehicles },
						allIds: [...state.vehicles.allIds, normalizedVehicle.result]
					}
				}))
			},
			removeVehicle: async (vehicleId) => {
				await deleteVehicleById(vehicleId)

				set((state) => {
					const newVehicles: { [key: string]: Vehicle } = {
						...state.vehicles.byId
					}
					Object.keys(newVehicles).forEach((key) => {
						if (newVehicles[key].id === vehicleId) {
							delete newVehicles[key]
						}
					})
					return {
						vehicles: {
							byId: newVehicles,
							allIds: Object.keys(newVehicles).map((key) => parseInt(key))
						}
					}
				})
			},
			fetchVehicles: async () => {
				const vehicles = await getAllVehiclesService()
				const normalizedData = normalize(vehicles, [vehicleSchema])
				set({
					vehicles: {
						byId: { ...normalizedData.entities.vehicles },
						allIds: normalizedData.result
					}
				})
				return vehicles
			},
			fetchVehicleById: async (id) => {
				const vehicle = await getVehicleById(id)
				const normalizedData = normalize(vehicle, vehicleSchema)
				set({
					vehicles: {
						byId: { ...normalizedData.entities.vehicles },
						allIds: normalizedData.result
					}
				})
				return vehicle
			},
			resetVehicles: () => {
				set({ vehicles: { byId: {}, allIds: [] } })
			},
			createVehicle: async (vehicle) => {
				const newVehicle = await createVehicleService(vehicle)
				const normalizedData = normalize(newVehicle, vehicleSchema)
				set((state) => ({
					vehicles: {
						...state.vehicles,
						byId: { ...state.vehicles.byId, ...normalizedData.entities.vehicles },
						allIds: [...state.vehicles.allIds, normalizedData.result]
					}
				}))
				return newVehicle
			},
			updateVehicle: async (vehicle, vehicleId) => {
				const updatedVehicle = await updateVehicleService(vehicle, vehicleId)
				const normalizedData = normalize(updatedVehicle, vehicleSchema)
				set((state) => {
					const idExists = state.vehicles.allIds.includes(normalizedData.result)
					return {
						vehicles: {
							byId: { ...state.vehicles.byId, ...normalizedData.entities.vehicles },
							allIds: idExists
								? state.vehicles.allIds
								: [...state.vehicles.allIds, normalizedData.result]
						}
					}
				})
				return updatedVehicle
			},
			updateVehicleTechnical: async (vehicle, vehicleId) => {
				const updatedVehicle = await updateVehicleTechnicalService(vehicle, vehicleId)
				const normalizedData = normalize(updatedVehicle, vehicleSchema)
				set((state) => ({
					vehicles: {
						...state.vehicles,
						byId: { ...state.vehicles.byId, ...normalizedData.entities.vehicles },
						allIds: [...state.vehicles.allIds, normalizedData.result]
					}
				}))
				return updatedVehicle
			},
			deleteVehicle: async (vehicleId) => {
				await deleteVehicleService(vehicleId)
				set((state) => {
					const { [vehicleId]: _, ...rest } = state.vehicles.byId
					return {
						vehicles: {
							byId: rest,
							allIds: state.vehicles.allIds.filter((id) => id !== vehicleId)
						}
					}
				})
			}
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'vehicles store'
		}
	)
)
