import { useEffect, useState } from 'react'
import { emptyString } from 'utils/common'

const useSearch = <
	T extends { [key in K]: string | number | boolean | Nullable<T> | Date },
	K extends keyof T
>(
	elements: T[],
	keys: K[],
	searchTerm: string
) => {
	const [searchResults, setSearchResults] = useState<T[]>(elements)

	useEffect(() => {
		if (searchTerm) {
			const normalizedSearchTerm = searchTerm
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, emptyString)
				.toLowerCase()
			const searchWords = normalizedSearchTerm.split(' ')

			const filteredElements = elements.filter((element) => {
				return searchWords.every((word) => {
					return keys.some((key) => {
						const value = element[key]

						if (value !== null && value !== undefined) {
							const normalizedValue = value
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, emptyString)
								.toLowerCase()
							return normalizedValue.includes(word)
						}
						return false
					})
				})
			})

			setSearchResults((prevResults) => {
				const prevResultsString = JSON.stringify(prevResults)
				const newResultsString = JSON.stringify(filteredElements)
				return prevResultsString !== newResultsString ? filteredElements : prevResults
			})
		} else {
			setSearchResults((prevResults) => {
				const prevResultsString = JSON.stringify(prevResults)
				const newResultsString = JSON.stringify(elements)
				return prevResultsString !== newResultsString ? elements : prevResults
			})
		}
	}, [elements, searchTerm, keys])

	return searchResults
}

export default useSearch
