import React from 'react'

export function handleGeneralKeyboardEvent(
	preConfiguredFunction: () => void
): (event: React.KeyboardEvent) => void {
	return function (event: React.KeyboardEvent): void {
		if (event.key === 'Enter' || event.key === ' ') {
			preConfiguredFunction()
		}
	}
}

export function formatNumberSpanishStyle(value: number): string {
	const numberStr = value.toString()
	const parts = numberStr.split('.')
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
	return parts.join(',')
}
