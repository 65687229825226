import ButtonComponent from 'components/elements/Button/Button'
import { Modal } from 'components/elements/Modal/Modal'
import { emptyString } from 'utils/common'
import './ModalActions.scss'

interface Props {
	isVisible: boolean
	onClose: () => void
	type?: 'warning' | 'error'
	icon?: React.ReactNode
	title: string
	description: string
	primaryButton?: {
		className?: string
		text: string
		onClick: () => void
	}
	secondaryButton?: {
		className?: string
		text: string
		onClick: () => void
	}
}

const ModalActions: React.FC<Props> = (props) => {
	const { isVisible, onClose, type, icon, title, description, primaryButton, secondaryButton } =
		props

	return (
		<Modal
			customClassNames={`modal-actions${type ? ` ${type}` : emptyString}`}
			isVisible={isVisible}
			toggleVisibility={onClose}
			isFull>
			{icon && <div className='modal-actions-icon'>{icon}</div>}
			<h3 className='modal-actions-title'>{title}</h3>
			<p className='body1 modal-actions-description'>{description}</p>
			<div className='modal-actions-buttons'>
				{primaryButton && (
					<ButtonComponent
						className={primaryButton.className || emptyString}
						onClick={primaryButton.onClick}
						fullWidth>
						{primaryButton.text}
					</ButtonComponent>
				)}
				{secondaryButton && (
					<ButtonComponent
						className={secondaryButton.className || emptyString}
						variant='secondary'
						onClick={secondaryButton.onClick}
						fullWidth>
						{secondaryButton.text}
					</ButtonComponent>
				)}
			</div>
		</Modal>
	)
}

export default ModalActions
