import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const Booster: FC<Props> = (props) => {
	const { width = 32, height = 19 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			data-testid='booster-seat-icon'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M11.1697 6.85043C11.1449 6.85734 11.1236 6.8677 11.0988 6.87461C10.4541 7.08534 9.87666 7.01625 9.4551 6.88843C9.02645 6.76061 8.75721 6.57407 8.74304 6.5637C9.41967 6.2977 9.60034 5.44443 9.49761 4.67752C9.40904 4.00388 9.10793 3.39934 8.74304 3.31643C8.74304 3.31643 10.8473 2.5737 11.3893 3.31643C11.9314 4.05916 12.5159 6.3737 11.1697 6.85043Z'
				fill='#232343'
			/>
			<path
				d='M28.9428 9.77677C28.1776 11.5455 25.9883 12.5231 25.6412 12.6682C25.6093 12.682 25.5951 12.6889 25.5951 12.6889C25.5951 12.6889 25.5703 12.6682 25.5278 12.6302C25.2019 12.34 23.8486 11.0411 24.667 10.3088C25.3117 9.73186 25.8466 8.75422 25.9458 7.81804C25.9883 7.4104 25.9458 7.01313 25.797 6.65731C25.797 6.65731 28.4327 4.60531 29.1306 7.81804C29.29 8.55386 29.1908 9.20677 28.9464 9.77677H28.9428Z'
				fill='#232343'
			/>
			<path
				d='M11.0989 6.87552C10.8721 7.39025 10.5994 7.77716 10.5994 7.77716L8.39943 6.99989L7.34375 6.6268C7.34375 6.6268 7.34729 6.57498 7.35438 6.49552C8.0133 6.50589 8.52342 6.54389 8.73952 6.56461C9.41615 6.29861 9.59682 5.44534 9.49409 4.67843C10.1424 4.19825 11.1237 4.36407 11.3575 5.36934C11.4673 5.84607 11.3008 6.41261 11.0953 6.87898L11.0989 6.87552Z'
				fill='#E12D2F'
			/>
			<path
				d='M0.251587 11.8184C0.446428 13.2382 0.85028 14.6856 3.36196 15.836C5.87364 16.9864 11.9952 18.6065 15.4279 18.952C18.2159 19.2318 19.9305 18.1851 22.297 17.0347C24.6634 15.8844 30.4095 12.8513 30.4095 12.8513L0.251587 11.8184Z'
				fill='#232343'
			/>
			<path
				d='M11.9669 0.849968C10.5357 -0.0447592 9.64295 -0.15185 6.65657 0.155604C3.66664 0.463059 1.67572 1.11597 1.59778 2.53233C1.51985 3.95215 2.73495 4.3736 4.51686 4.0247C6.85849 3.56524 11.9669 0.846513 11.9669 0.846513V0.849968Z'
				fill='#6F619F'
			/>
			<path
				d='M1.60128 7.9043C1.60128 7.9043 -0.761609 9.24812 0.248022 11.8183C1.25765 14.3885 10.2239 16.7687 12.9765 17.1521C15.7326 17.5356 18.8748 17.1141 20.3911 16.0398C21.9037 14.9654 23.962 14.3505 26.2115 13.891C28.461 13.4316 29.2014 13.5836 30.4023 12.8547L25.5136 11.3968L1.60128 7.9043Z'
				fill='#414B6E'
			/>
			<path
				d='M15.9699 5.68022C15.9699 5.68022 13.8337 1.11677 11.2725 0.615861C10.1388 0.39477 8.49508 0.553679 6.94698 0.729861C4.99857 0.950951 3.2202 1.31713 3.00411 2.34313C2.61442 4.18441 3.97477 4.18441 5.87713 3.87695C7.77949 3.5695 9.87314 2.72659 10.7269 3.80095C11.5807 4.87531 11.4248 5.94968 11.1166 6.60259C10.8048 7.2555 13.0579 6.83404 14.1844 6.60259C15.311 6.37113 15.9699 5.68022 15.9699 5.68022Z'
				fill='#D7CDF9'
			/>
			<path
				d='M25.8785 8.21121C25.8785 8.21121 25.7722 10.0732 24.9503 10.8643C24.4543 11.3445 19.7852 9.61375 16.186 8.17666C15.977 8.09375 15.7715 8.01084 15.5696 7.92793C13.5291 7.10575 11.9703 6.43557 11.9703 6.43557C12.5584 6.42175 13.2102 5.94848 14.7123 5.60648C15.6794 5.38884 16.8697 5.76539 18.393 6.20757C18.5347 6.24902 18.6764 6.29048 18.8252 6.33193C19.3814 6.49084 19.9836 6.64975 20.6284 6.78448C23.2782 7.34412 26.0697 7.70339 25.882 8.20775L25.8785 8.21121Z'
				fill='#C2B9EF'
			/>
			<path
				d='M8.51992 6.9862C9.52955 6.49565 10.8828 6.10874 12.1652 6.49565C13.4476 6.88256 16.6572 7.81183 20.2777 8.39911C23.8982 8.98983 24.5536 8.98983 24.9326 10.2473C25.3117 11.5047 18.3753 12.7829 15.5094 12.9591C12.6435 13.1353 5.1899 10.8967 4.28655 9.35256C3.38674 7.80838 5.88425 7.04493 6.86908 6.95165C7.85391 6.85838 8.51637 6.9862 8.51637 6.9862H8.51992Z'
				fill='#D7CDF9'
			/>
			<path
				d='M8.32158 7.01001C9.16826 6.91673 10.2665 7.06182 13.1288 8.12582C15.9948 9.18982 18.262 9.30382 20.3238 9.59746C22.3856 9.8911 23.3208 10.2296 23.1649 10.8411C23.0091 11.4526 18.7899 13.0244 16.1967 12.9553C13.6071 12.8827 5.8595 11.0035 4.55584 9.66655C3.24863 8.32964 4.48853 7.37964 5.10493 7.22073C5.72488 7.06182 8.31804 7.01001 8.31804 7.01001H8.32158Z'
				fill='#C2B9EF'
			/>
			<path
				d='M18.8217 6.33485C18.8217 6.33485 18.4249 7.39194 16.186 8.17958C13.9471 8.96721 11.1556 9.69267 10.6383 11.3094C10.1211 12.9261 9.22485 10.9467 9.22485 10.9467C9.22485 10.9467 9.90503 9.68576 11.9951 8.93958C13.1748 8.51812 14.3616 8.35921 15.5696 7.9274C16.4977 7.59576 17.4365 7.10176 18.393 6.20703C18.5347 6.24849 18.6764 6.28994 18.8252 6.3314L18.8217 6.33485Z'
				fill='#E12D2F'
			/>
			<path
				d='M28.9428 9.77561C28.5531 10.6807 27.7879 11.3785 27.09 11.8622L25.5171 11.3923L24.4756 11.2403C24.3587 10.9052 24.3693 10.5667 24.6634 10.3042C25.3081 9.72724 25.8431 8.74961 25.9422 7.81342C25.9848 7.80652 28.6417 7.32979 28.9393 9.77215L28.9428 9.77561Z'
				fill='#E12D2F'
			/>
			<path
				d='M25.5172 11.3973C25.5172 11.3973 21.5955 13.508 20.1218 14.0054C18.6481 14.5029 17.9467 14.8863 14.8434 14.6203C11.7401 14.3509 5.91614 12.8551 3.74455 11.9742C1.56941 11.0933 0.212611 9.63544 1.60484 7.90817C2.6251 6.6438 5.55126 6.47108 7.35797 6.49871C8.01688 6.50908 8.52701 6.54708 8.74311 6.5678L9.10799 6.73362C8.90252 6.81308 8.70768 6.89944 8.51993 6.98926C8.48096 6.99271 8.44199 6.99962 8.39948 7.00308C7.20564 7.14817 5.36704 7.42799 4.90651 7.83217C4.20863 8.44708 4.40347 9.05853 6.41919 10.0189C8.43845 10.9793 15.8495 12.6271 17.9857 12.4371C20.1218 12.2436 24.2348 10.5613 24.798 10.1847C25.3648 10.5198 25.5172 11.4007 25.5172 11.4007V11.3973Z'
				fill='#6F619F'
			/>
			<path
				d='M30.4059 12.8542C26.4489 14.7334 25.5172 11.3963 25.5172 11.3963C25.5172 11.3963 27.6498 10.436 27.9616 8.7087C28.2733 6.98143 27.5719 6.49089 27.5719 6.49089C25.4959 6.33543 22.5804 7.74489 20.547 7.94525C20.1467 7.98671 19.7782 7.9798 19.463 7.9038C17.9893 7.55489 18.1416 5.25761 19.2681 4.41125C20.253 3.67198 26.1372 1.76507 27.9616 2.30052C28.16 2.35925 28.3513 2.45943 28.539 2.59416C28.6949 2.70471 28.8472 2.84289 28.9925 2.99834C30.3174 4.39052 31.3306 7.35452 31.7663 8.7087C32.3083 10.398 31.9222 11.7763 30.4095 12.8507L30.4059 12.8542Z'
				fill='#D7CDF9'
			/>
			<path
				d='M20.547 7.94916C20.1467 7.99061 19.7782 7.9837 19.463 7.9077C17.9893 7.55879 18.1416 5.26152 19.2681 4.41516C20.253 3.67588 26.1372 1.76898 27.9616 2.30443C28.16 2.36316 28.3513 2.46334 28.539 2.59807C27.1326 2.61879 21.7656 3.80025 20.2494 4.99552C19.2256 5.80388 19.757 7.59679 20.547 7.9457V7.94916Z'
				fill='#6F619F'
			/>
		</svg>
	)
}

export default Booster
