import InputButton from 'components/elements/InputButton/InputButton'
import SelectRoleModal from 'components/elements/SelectOptions/SelectRoleOptions/SelectRoleModal/SelectRoleModal'
import { useModal } from 'hooks/useModal'
import { NonAdminRoleKeys, UserRoles } from 'models/User'
import { RefObject, forwardRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	id: string
	handleRoleSelected: (role: NonAdminRoleKeys) => void
	selectedId: NonAdminRoleKeys | null
	error?: string
}

const SelectRoleOptions = forwardRef<HTMLElement, Props>((props, ref) => {
	const { id, handleRoleSelected, selectedId, error } = props

	const { t } = useTranslation()
	const [roleModalIsVisible, toggleRoleModalVisibility] = useModal()

	const formatRoleText = useMemo(
		() => ({
			[UserRoles.provider]: t('general.provider'),
			[UserRoles.driver]: t('general.driver'),
			[UserRoles.revisor]: t('general.revisor')
		}),
		[]
	)

	const handleSelectRole = (role: NonAdminRoleKeys) => {
		toggleRoleModalVisibility()
		handleRoleSelected(role)
	}

	const placeholder = selectedId ? formatRoleText[selectedId] : t('user.select_role')
	const valueSelected = selectedId ? formatRoleText[selectedId] : undefined

	return (
		<>
			<InputButton
				ref={ref as RefObject<HTMLButtonElement>}
				id={id}
				type='select'
				text={valueSelected}
				placeholder={placeholder}
				onClick={toggleRoleModalVisibility}
				error={error}
			/>
			<SelectRoleModal
				isVisible={roleModalIsVisible}
				toggleVisibility={toggleRoleModalVisibility}
				handleSelectRole={handleSelectRole}
			/>

			{error && <span className='input-error-message'>{error}</span>}
		</>
	)
})

export default SelectRoleOptions
