import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const ArrowSquareOutIcon: FC<Props> = (props) => {
	const { width = 27, height = 27 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='ArrowSquareOut'>
				<path
					id='Vector'
					d='M23.625 10.9688C23.625 11.1925 23.5361 11.4071 23.3779 11.5654C23.2196 11.7236 23.005 11.8125 22.7812 11.8125C22.5575 11.8125 22.3429 11.7236 22.1846 11.5654C22.0264 11.4071 21.9375 11.1925 21.9375 10.9688V6.25641L14.9418 13.2532C14.7834 13.4115 14.5687 13.5005 14.3448 13.5005C14.1209 13.5005 13.9062 13.4115 13.7479 13.2532C13.5895 13.0949 13.5006 12.8802 13.5006 12.6562C13.5006 12.4323 13.5895 12.2176 13.7479 12.0593L20.7436 5.0625H16.0312C15.8075 5.0625 15.5929 4.97361 15.4346 4.81537C15.2764 4.65714 15.1875 4.44253 15.1875 4.21875C15.1875 3.99497 15.2764 3.78036 15.4346 3.62213C15.5929 3.4639 15.8075 3.375 16.0312 3.375H22.7812C23.005 3.375 23.2196 3.4639 23.3779 3.62213C23.5361 3.78036 23.625 3.99497 23.625 4.21875V10.9688ZM19.4062 13.5C19.1825 13.5 18.9679 13.5889 18.8096 13.7471C18.6514 13.9054 18.5625 14.12 18.5625 14.3438V21.9375H5.0625V8.4375H12.6562C12.88 8.4375 13.0946 8.34861 13.2529 8.19037C13.4111 8.03214 13.5 7.81753 13.5 7.59375C13.5 7.36997 13.4111 7.15536 13.2529 6.99713C13.0946 6.8389 12.88 6.75 12.6562 6.75H5.0625C4.61495 6.75 4.18572 6.92779 3.86926 7.24426C3.55279 7.56073 3.375 7.98995 3.375 8.4375V21.9375C3.375 22.3851 3.55279 22.8143 3.86926 23.1307C4.18572 23.4472 4.61495 23.625 5.0625 23.625H18.5625C19.0101 23.625 19.4393 23.4472 19.7557 23.1307C20.0722 22.8143 20.25 22.3851 20.25 21.9375V14.3438C20.25 14.12 20.1611 13.9054 20.0029 13.7471C19.8446 13.5889 19.63 13.5 19.4062 13.5Z'
					fill='#713FFF'
				/>
			</g>
		</svg>
	)
}

export default ArrowSquareOutIcon
