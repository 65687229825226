import React from 'react'
import './DetailsCardItem.scss'

interface Props {
	title: string
	children: React.ReactNode
}

const DetailsCardItem: React.FC<Props> = (props) => {
	const { title, children } = props
	return (
		<div className='details-card-item'>
			<div className='body2'>{title}</div>
			<div className='details-card-item-children'>{children}</div>
		</div>
	)
}

export default DetailsCardItem
