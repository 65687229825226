import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import { NOT_ALLOWED_URL, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import { useTransferDetails } from 'hooks/useTransferDetails'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { AllStepForms } from 'models/Transfer'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { useConfigStore } from 'stores/useConfigStore'
import { useTransfersStore } from 'stores/useTransfersStore'
import { emptyString } from 'utils/common'
import { currentUserIsAdmin } from 'utils/currentUser'
import { dateToSQL } from 'utils/dateUtils'
import * as Yup from 'yup'
import StepAddressesInputs from './StepInputs/StepAddressesInputs'
import StepBookingDateInputs from './StepInputs/StepBookingDateInputs'
import StepClientInputs from './StepInputs/StepClientInputs'
import StepDetailsInputs from './StepInputs/StepDetailsInputs'
import StepExtrasInputs from './StepInputs/StepExtrasInputs'
import StepObservationsInputs from './StepInputs/StepObservationsInputs'
import StepPricesInputs from './StepInputs/StepPricesInputs'
import StepRoutesInputs from './StepInputs/StepRoutesInputs'

const TransferEdit: FC = () => {
	const { transferId } = useParams() as { transferId: string }
	const transferIdNumber = parseInt(transferId)
	const navigate = useNavigate()
	const { t } = useTranslation()
	const configState = useConfigStore()
	const transferState = useTransfersStore()
	const { transfer } = useTransferDetails(transferIdNumber, true)
	const [loading, setLoading] = useState(false)

	const isAdmin = currentUserIsAdmin()

	const formik = useFormik<AllStepForms>({
		initialValues: {
			routeTypeId: null,
			driverId: null,
			providerId: null,
			vehicleId: null,
			passengers: 1,
			date: undefined,
			time: emptyString,
			flight: emptyString,
			originAddress: emptyString,
			destinationAddress: emptyString,
			price: null,
			commission: null,
			toll: null,
			isPaid: false,
			clientName: emptyString,
			email: emptyString,
			phone: emptyString,
			babySeats: 0,
			boosterSeats: 0,
			maxicosiSeats: 0,
			observations: emptyString
		},
		validationSchema: Yup.object().shape({
			routeTypeId: Yup.number().required(
				t('errors.required_m', { field: t('transfer.transfer_type') })
			),
			driverId: Yup.number().required(t('errors.required_m', { field: t('transfer.driver') })),
			providerId: Yup.number().required(t('errors.required_m', { field: t('transfer.provider') })),
			vehicleId: Yup.number().nullable(),
			passengers: Yup.number().required(
				t('errors.required_m', { field: t('transfer.passengers') })
			),
			date: Yup.string().required(t('errors.required_m', { field: t('transfer.date') })),
			time: Yup.string().required(t('errors.required_m', { field: t('transfer.time') })),
			originAddress: Yup.string().required(t('errors.required_m', { field: t('transfer.origin') })),
			destinationAddress: Yup.string().required(
				t('errors.required_m', { field: t('transfer.destination') })
			),
			price: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			commission: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			toll: Yup.number()
				.typeError(t('errors.number'))
				.min(0, t('errors.not_negative'))
				.nullable()
				.transform((_, val) => (val !== emptyString ? Number(val) : null)),
			isPaid: Yup.boolean(),
			clientName: Yup.string().required(
				t('errors.required_m', { field: t('transfer.clientName') })
			),
			email: Yup.string().email(t('errors.mail_not_valid')).nullable()
		}),
		onSubmit: async (values) => {
			setLoading(true)
			if (!values.providerId || !values.driverId || !values.routeTypeId) return
			try {
				const transfer = {
					...values,
					route: configState.routeTypes.byId[values.routeTypeId].name,
					providerId: values.providerId,
					driverId: values.driverId,
					date: dateToSQL(values.date),
					time: values.time,
					price: values.price ?? 0,
					commission: values.commission ?? 0,
					toll: values.toll ?? 0
				}
				await transferState.updateTransfer(transferIdNumber, transfer)
				toast.success(t('toast.success.description'))
				navigate(`${TRANSFER_URL}/${transferId}`)
			} catch (error) {
				console.error(error)
				toast.error(t('toast.error.description'))
			} finally {
				setLoading(false)
			}
		}
	})

	useEffect(() => {
		if (!transfer) return

		formik.setValues({
			routeTypeId: configState.getRouteTypeByName(transfer.route).id,
			driverId: transfer.driverId,
			providerId: transfer.providerId,
			vehicleId: transfer.vehicleId,
			passengers: transfer.passengers,
			date: new Date(transfer.date),
			time: transfer.time,
			flight: transfer.flight,
			originAddress: transfer.originAddress,
			destinationAddress: transfer.destinationAddress,
			price: transfer.price,
			commission: transfer.commission,
			toll: transfer.toll,
			isPaid: transfer.isPaid,
			clientName: transfer.clientName,
			email: transfer.email,
			phone: transfer.phone,
			babySeats: transfer.babySeats,
			boosterSeats: transfer.boosterSeats,
			maxicosiSeats: transfer.maxicosiSeats,
			observations: transfer.observations
		})
	}, [transfer])

	useEffect(() => {
		if (!isAdmin) {
			navigate(NOT_ALLOWED_URL)
		}
	}, [isAdmin])

	if (!transfer) {
		return null
	}

	return (
		<FormLayout
			title={t('transfer_details.title')}
			leftButton={<ButtonBack url={`${TRANSFER_URL}/${transferId}`} />}
			footer={
				<ButtonComponent onClick={formik.handleSubmit} fullWidth loading={loading}>
					{t('general.save_changes')}
				</ButtonComponent>
			}>
			<FormSectionLayout title={t('transfer.create_steps.step_route.h1')}>
				<StepRoutesInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_details.h1')}>
				<StepDetailsInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_addresses.h1_v1')}>
				<StepBookingDateInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_addresses.h1_v2')}>
				<StepAddressesInputs
					formik={formik}
					route={configState.routeTypes.byId[formik.values.routeTypeId ?? 0]?.name}
				/>
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_prices.h1')}>
				<StepPricesInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_client.h1')}>
				<StepClientInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_extras.h1')}>
				<StepExtrasInputs formik={formik} />
			</FormSectionLayout>
			<FormSectionLayout title={t('transfer.create_steps.step_observations.h1')}>
				<StepObservationsInputs formik={formik} />
			</FormSectionLayout>
		</FormLayout>
	)
}

export default TransferEdit
