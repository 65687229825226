import React from 'react'

interface Props {
	width?: number
	height?: number
}

const ConfirmedFomento: React.FC<Props> = (props) => {
	const { width = 15, height = 15 } = props

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 14 14'
			fill='none'>
			<path
				d='M7 1.3125C5.87512 1.3125 4.7755 1.64607 3.8402 2.27102C2.90489 2.89597 2.17591 3.78423 1.74544 4.82349C1.31496 5.86274 1.20233 7.00631 1.42179 8.10958C1.64124 9.21284 2.18292 10.2263 2.97833 11.0217C3.77374 11.8171 4.78716 12.3588 5.89043 12.5782C6.99369 12.7977 8.13726 12.685 9.17651 12.2546C10.2158 11.8241 11.104 11.0951 11.729 10.1598C12.3539 9.2245 12.6875 8.12488 12.6875 7C12.6859 5.49207 12.0862 4.04636 11.0199 2.98009C9.95365 1.91382 8.50793 1.31409 7 1.3125ZM9.49703 5.99703L6.43453 9.05953C6.3939 9.10021 6.34565 9.13248 6.29254 9.1545C6.23943 9.17651 6.1825 9.18784 6.125 9.18784C6.06751 9.18784 6.01058 9.17651 5.95747 9.1545C5.90435 9.13248 5.8561 9.10021 5.81547 9.05953L4.50297 7.74703C4.42088 7.66494 4.37476 7.5536 4.37476 7.4375C4.37476 7.3214 4.42088 7.21006 4.50297 7.12797C4.58506 7.04588 4.69641 6.99976 4.8125 6.99976C4.9286 6.99976 5.03994 7.04588 5.12203 7.12797L6.125 8.13148L8.87797 5.37797C8.91862 5.33732 8.96688 5.30508 9.01999 5.28308C9.07309 5.26108 9.13002 5.24976 9.1875 5.24976C9.24499 5.24976 9.30191 5.26108 9.35502 5.28308C9.40813 5.30508 9.45639 5.33732 9.49703 5.37797C9.53768 5.41862 9.56993 5.46687 9.59192 5.51998C9.61392 5.57309 9.62525 5.63001 9.62525 5.6875C9.62525 5.74499 9.61392 5.80191 9.59192 5.85502C9.56993 5.90813 9.53768 5.95638 9.49703 5.99703Z'
				fill='#55E38E'
			/>
		</svg>
	)
}

export default ConfirmedFomento
