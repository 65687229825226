import Loading from 'components/elements/Loading/Loading'
import { LOGIN_URL, NOT_ALLOWED_URL, ONBOARDING_URL, TOUR_URL } from 'constants/routes'
import _ from 'lodash'
import { AllUserRoles, UserRoles } from 'models/User'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useDriversStore, useProvidersStore, useUserStore, useVehiclesStore } from 'stores/index'
import { useCompanyStore } from 'stores/useCompanyStore'
import { useLoadingStore } from 'stores/useLoadingStore'

type PrivateRouteProps = {
	component: React.ComponentType
	roles?: Array<AllUserRoles>
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
	const { component: Component, roles, ...rest } = props

	const location = useLocation()
	const userStore = useUserStore()
	const vehicleState = useVehiclesStore()
	const driverState = useDriversStore()
	const providerState = useProvidersStore()
	const loadingStore = useLoadingStore()
	const companyState = useCompanyStore()

	const currentUser = userStore.currentUser
	const isLogged = !_.isEmpty(currentUser)

	if (!isLogged) {
		return <Navigate to={LOGIN_URL} />
	}

	if (loadingStore.isLoading) {
		return <Loading fullscreen />
	}

	let roleMatch = roles ? roles?.includes(currentUser.userTypeId) : true

	if (currentUser.userTypeId === UserRoles['super-admin']) {
		roleMatch = true
	}

	if (!roleMatch) {
		return <Navigate to={NOT_ALLOWED_URL} />
	}

	const onTourPage = location.pathname === TOUR_URL
	const firstSteps = location.pathname.includes(ONBOARDING_URL)
	if (!onTourPage && currentUser.showTutorial) {
		return <Navigate to={TOUR_URL} />
	}

	if (
		!onTourPage &&
		!firstSteps &&
		(vehicleState.vehicles.allIds.length === 0 ||
			driverState.drivers.allIds.length === 0 ||
			providerState.providers.allIds.length === 0) &&
		companyState.company.hasTransfers === false
	) {
		return <Navigate to={ONBOARDING_URL} />
	}

	return <Component {...rest} />
}

export default PrivateRoute
