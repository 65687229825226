import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const BarricadeIcon: FC<Props> = (props) => {
	const { width = 32, height = 32 } = props

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 32 32'
			fill='none'>
			<path
				d='M28 8H4C3.46957 8 2.96086 8.21071 2.58579 8.58579C2.21071 8.96086 2 9.46957 2 10V19C2 19.5304 2.21071 20.0391 2.58579 20.4142C2.96086 20.7893 3.46957 21 4 21H7V25C7 25.2652 7.10536 25.5196 7.29289 25.7071C7.48043 25.8946 7.73478 26 8 26C8.26522 26 8.51957 25.8946 8.70711 25.7071C8.89464 25.5196 9 25.2652 9 25V21H23V25C23 25.2652 23.1054 25.5196 23.2929 25.7071C23.4804 25.8946 23.7348 26 24 26C24.2652 26 24.5196 25.8946 24.7071 25.7071C24.8946 25.5196 25 25.2652 25 25V21H28C28.5304 21 29.0391 20.7893 29.4142 20.4142C29.7893 20.0391 30 19.5304 30 19V10C30 9.46957 29.7893 8.96086 29.4142 8.58579C29.0391 8.21071 28.5304 8 28 8ZM28 16.0863L21.9137 10H28V16.0863ZM10.0863 10L19.0863 19H12.9137L4 10.0863V10H10.0863ZM4 12.9137L10.0863 19H4V12.9137ZM28 19H21.9137L12.9137 10H19.0863L28 18.915V19Z'
				fill='#FF9567'
			/>
		</svg>
	)
}

export default BarricadeIcon
