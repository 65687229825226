import './ColorIndicator.scss'

interface Props {
	color: string
}

const ColorIndicator: React.FC<Props> = (props) => {
	const { color } = props

	return <div className='color-indicator' style={{ backgroundColor: color }} />
}

export default ColorIndicator
