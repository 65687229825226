import Toast from 'components/elements/Toast/Toast'
import { useTranslation } from 'react-i18next'
import { handleErrorResponse } from 'services/customFetch'
import { toast } from 'sonner'

interface ToastButton {
	text: string
	onClick: () => void
}
interface ToastParams {
	title?: string
	description?: string
	primaryButton?: ToastButton
	secondaryButton?: ToastButton
	duration?: number
	onAutoClose?: () => void
}

const useToast = () => {
	const { t } = useTranslation()

	const showSuccessToast = ({
		title = t('toast.success.title'),
		description = t('toast.success.description'),
		primaryButton,
		secondaryButton,
		duration,
		onAutoClose
	}: ToastParams) => {
		toast(
			<Toast
				type='success'
				title={title}
				description={description}
				primaryButton={primaryButton}
				secondaryButton={secondaryButton}
				onClose={toast.dismiss}
			/>,
			{
				duration,
				onAutoClose
			}
		)
	}

	const showErrorToast = ({
		title = t('toast.error.title'),
		description = t('toast.error.description'),
		primaryButton,
		secondaryButton,
		duration,
		onAutoClose
	}: ToastParams) => {
		toast(
			<Toast
				type='error'
				title={title}
				description={description}
				primaryButton={primaryButton}
				secondaryButton={secondaryButton}
				onClose={toast.dismiss}
			/>,
			{
				duration,
				onAutoClose
			}
		)
	}

	const showBackendErrorToast = (error: unknown) => {
		const errorResponse = handleErrorResponse(error)
		let messagesText = t('errors.unexpected_error')

		if (errorResponse) {
			messagesText = Object.values(errorResponse.messages).join('\n')
		}

		showErrorToast({
			description: messagesText,
			duration: 5000
		})
	}

	return { showSuccessToast, showErrorToast, showBackendErrorToast }
}

export default useToast
