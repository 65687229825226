import FomentoCategoryTag from 'components/elements/CategoryTag/FomentoCategoryTag'
import { Fomento } from 'models/Fomento'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { dateToHoursAndMinutes, dateToSpanish } from 'utils/dateUtils'
import './TransferInfoStatus.scss'

interface Props {
	fomento?: Fomento
}

const TransferInfoStatus: FC<Props> = (props) => {
	const { fomento } = props

	const [statusInfo, setStatusInfo] = useState<string | ReactNode>()
	const { t } = useTranslation()

	const getStatus = () => {
		if (!fomento)
			return (
				<Trans
					i18nKey='fomento.without_communicating_description'
					components={{
						strong: <strong />
					}}
				/>
			)
		if (fomento.confirmedAt)
			return t('fomento.confirmed_description', {
				date: dateToSpanish(new Date(fomento.confirmedAt)),
				time: dateToHoursAndMinutes(new Date(fomento.confirmedAt))
			})
		else if (fomento.cancelledAt)
			return t('fomento.cancelled_description', {
				date: dateToSpanish(new Date(fomento.cancelledAt)),
				time: dateToHoursAndMinutes(new Date(fomento.cancelledAt))
			})
		else if (fomento.createdAt)
			return t('fomento.communicated_description', {
				date: dateToSpanish(new Date(fomento.createdAt)),
				time: dateToHoursAndMinutes(new Date(fomento.createdAt))
			})
		else
			return (
				<Trans
					i18nKey='fomento.without_communicating_description'
					components={{
						strong: <strong />
					}}
				/>
			)
	}

	useEffect(() => {
		const newStatusInfo = getStatus()
		setStatusInfo(newStatusInfo)
	}, [fomento])

	return (
		<div className='transfer-info-status'>
			<span className='transfer-info-status-label body1'>{t('transfer.status')}</span>
			<FomentoCategoryTag fomento={fomento} />
			<p className='transfer-info-status-description'>{statusInfo}</p>
		</div>
	)
}

export default TransferInfoStatus
