import { CaretRight } from 'assets/svgs'
import './ActionButton.scss'

export interface Props {
	children: React.ReactNode
	onClick: () => void
	icon: React.ReactElement
	backGroundIcon?: string
	id?: string
	mode?: 'dark' | 'light'
	noRightArrow?: boolean
}

const ActionButton: React.FC<Props> = (props) => {
	const {
		children,
		onClick,
		id,
		icon,
		backGroundIcon,
		mode = 'light',
		noRightArrow = false
	} = props

	const classNames = () => {
		let classes = 'action-button'
		if (mode) classes += ` ${mode}`

		return classes
	}

	return (
		<button id={id} className={classNames()} onClick={() => onClick && onClick()}>
			<span className='action-button-icon-container' style={{ backgroundColor: backGroundIcon }}>
				{icon}
			</span>
			<span className='action-button-children'>{children}</span>
			{!noRightArrow && (
				<div className='action-button-arrow'>
					<CaretRight />
				</div>
			)}
		</button>
	)
}

export default ActionButton
