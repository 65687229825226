import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const Maxicosi: FC<Props> = (props) => {
	const { width = 25, height = 31 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			data-testid='maxicosi-seat-icon'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.08945 16.9676C5.08945 18.3995 4.17612 19.5568 3.04863 19.5568C1.92114 19.5568 1.00781 18.3966 1.00781 16.9676C1.00781 16.817 1.02041 16.6694 1.03931 16.5336C1.05505 16.4421 1.07395 16.3506 1.096 16.2649C1.23772 15.7335 1.54636 15.3261 1.94004 15.0604C2.17624 14.9009 2.44394 14.7946 2.72739 14.7415C2.85966 14.7179 2.99824 14.7031 3.13681 14.7031C4.2643 14.7031 5.09259 15.5357 5.09259 16.9646L5.08945 16.9676Z'
				fill='#232343'
			/>
			<path
				d='M1.96841 15.7006C1.53694 16.0017 0.985791 15.5529 0.970044 15.5618L0.88501 12.6419L1.86133 12.3496C1.86133 12.3703 1.86133 12.3909 1.86133 12.4116C1.90542 13.3741 1.981 14.6525 2.72426 14.741C3.15888 14.7942 2.55735 15.2872 1.96526 15.6976L1.96841 15.7006Z'
				fill='#232343'
			/>
			<path
				d='M20.7609 5.21451C20.7609 6.38366 18.2257 7.33137 15.0983 7.33137C11.9709 7.33137 9.43567 6.38366 9.43567 5.21451C9.43567 4.04537 11.9709 3.09766 15.0983 3.09766C18.2257 3.09766 20.7609 4.04537 20.7609 5.21451Z'
				fill='#D3447F'
			/>
			<path
				d='M23.9292 17.582L20.3987 28.3405C19.8759 29.9318 18.4272 31.0006 16.7989 31.0006H12.7803C12.4969 31.0006 12.248 30.8264 12.163 30.5696L11.9866 30.0322C11.8922 29.7429 11.6087 29.548 11.2906 29.548H7.86722C7.54913 29.548 7.26569 29.7458 7.1712 30.0322L7.02633 30.4721C6.9224 30.7851 6.57597 30.9711 6.24213 30.8796C5.24062 30.6109 4.38713 29.9023 3.92101 28.9162L1.84241 25.0457L0.771606 18.4707L23.9261 17.582H23.9292Z'
				fill='#232343'
			/>
			<path
				d='M21.205 16.6317C21.205 22.126 16.6194 29.0582 9.54271 29.0582C9.3065 29.0582 9.07344 29.0494 8.84039 29.0317C8.31759 28.9933 7.80738 28.9077 7.31293 28.7866C4.17612 28.0042 1.64084 25.6069 0.560595 22.4833C0.198413 21.4322 0 20.2985 0 19.1146C0 13.6202 4.2643 17.8835 9.53326 17.8835C13.3503 17.8835 17.7627 14.3465 19.9043 14.4823C20.72 14.5355 21.205 15.12 21.205 16.6346V16.6317Z'
				fill='#6765C7'
			/>
			<path
				d='M19.9043 14.4805C19.7027 19.91 15.1675 26.5351 8.2483 26.5351C5.09574 26.5351 2.29907 24.9408 0.560595 22.4844C0.251953 21.5928 0.0629882 20.6421 0.0125976 19.6531C0.00314941 19.476 0 19.2959 0 19.1128C0 13.6184 4.2643 17.8817 9.53326 17.8817C12.0496 17.8817 14.8274 16.3435 17.0383 15.3338C18.1815 14.8141 19.1736 14.4333 19.9011 14.4805H19.9043Z'
				fill='#D3447F'
			/>
			<path
				d='M17.0415 15.334C16.2604 19.7803 12.6008 24.5454 7.2909 24.5454C4.0974 24.5454 1.33537 22.5496 0.0157715 19.6533C0.00632324 19.4762 0.00317383 19.2961 0.00317383 19.113C0.00317383 13.6187 4.26747 17.8819 9.53643 17.8819C12.0528 17.8819 14.8306 16.3437 17.0415 15.334Z'
				fill='#E56395'
			/>
			<path
				d='M23.4946 13.9491C23.4411 14.1144 23.3812 14.2827 23.3214 14.4481C22.9057 15.6024 22.3892 16.6801 21.8034 17.6543C21.7246 17.7872 21.6428 17.9201 21.5577 18.047C21.501 18.1385 21.4412 18.2271 21.3814 18.3157C21.2428 18.5223 21.1011 18.7231 20.9562 18.9209C19.772 20.527 18.3863 21.7611 16.969 22.4874C16.7832 22.5819 16.5974 22.6675 16.4116 22.7443C15.6557 23.0572 13.7063 24.6279 11.1174 24.3681C10.9474 24.3503 10.7742 24.3976 10.6009 24.3976C10.3175 24.3976 10.0403 24.371 9.7632 24.3179C9.66871 24.3002 9.57738 24.2795 9.4829 24.2559C9.31283 24.2145 9.14591 24.1614 8.98214 24.0994C8.44359 23.8986 7.93024 23.6004 7.44838 23.2166C6.76811 22.6734 6.14768 21.9619 5.61543 21.1116C5.09262 20.282 4.65171 19.3254 4.30842 18.2714C4.1478 17.7783 4.00923 17.2617 3.89585 16.7273C3.6502 15.5729 3.51477 14.3359 3.51477 13.0457C3.51477 11.0705 3.82971 9.21349 4.38401 7.59558C4.39975 7.5513 4.4155 7.50701 4.43125 7.46273C4.77768 6.47958 5.2123 5.59092 5.71936 4.81739C6.37128 3.93758 8.58532 2.91016 11.8355 4.2092C14.8117 5.39901 18.0493 4.59892 19.9704 4.07635C20.1468 4.02911 20.7956 3.86968 20.953 3.86082C22.5309 3.7693 23.5796 4.52511 24.0048 5.85073C24.5906 8.1152 24.4583 10.9967 23.4883 13.9432L23.4946 13.9491Z'
				fill='#E56395'
			/>
			<path
				d='M17.8351 15.9842C17.6997 16.8167 17.4572 18.8096 16.0274 20.6696C15.7187 21.0741 15.0668 21.4313 14.3771 21.8299C14.2637 21.8948 14.1472 21.9627 14.0338 22.0336C11.8733 24.4132 9.40103 25.9042 8.36802 23.0138C8.14442 22.3908 7.6846 21.759 7.23739 21.1449C6.65475 20.3537 6.091 19.5979 6.07525 18.9425C6.04061 17.4869 6.28941 15.869 6.57916 14.9656C6.83111 14.1803 7.84207 13.8998 8.44991 13.8024C8.72391 13.7581 8.91602 13.7522 8.91602 13.7522C8.91602 13.7522 10.2734 11.8331 14.248 12.3616C16.0841 12.6066 17.4068 13.3005 17.7816 14.5877C17.7816 14.5936 17.7848 14.5966 17.7848 14.6025C17.7879 14.6143 17.7911 14.6261 17.7942 14.6408C17.8981 15.0335 17.917 15.4793 17.8351 15.9842Z'
				fill='#6765C7'
			/>
			<path
				d='M19.4791 7.22088V13.4238C19.1705 13.9671 18.6729 14.3509 18.1218 14.4719L17.2336 14.4424L10.4183 14.221C8.78061 14.1678 7.53029 12.8097 7.69721 11.2597C7.77595 10.5276 7.87988 9.80717 8.0153 9.29936C8.33339 8.1066 9.18688 7.07031 10.4844 7.07031H19.0634C19.1674 7.07917 19.3185 7.1146 19.4791 7.22088Z'
				fill='#88B5EA'
			/>
			<path
				d='M20.172 9.23248L19.7878 12.5214C19.7405 12.8521 19.6334 13.1592 19.4791 13.4249C19.1705 13.9681 18.6729 14.3519 18.1217 14.473C18.0556 14.4877 17.9548 14.4907 17.8446 14.4877C17.495 14.4818 17.4572 13.2271 17.4635 12.8964C17.4761 12.2587 17.4698 11.1988 17.9076 9.70782C18.443 7.89506 18.9248 7.06839 18.9248 7.06839C18.9248 7.06839 18.9784 7.05953 19.0634 7.06839C19.1673 7.07725 19.3185 7.11268 19.4791 7.21896C19.6838 7.35182 19.9011 7.59982 20.0523 8.05153C20.1814 8.43239 20.2161 8.83391 20.172 9.22953V9.23248Z'
				fill='#6765C7'
			/>
			<path
				d='M18.3548 7.07031C17.8635 7.58698 16.5911 7.95603 15.0983 7.95603C13.6055 7.95603 12.3331 7.58698 11.8418 7.07031H18.3579H18.3548Z'
				fill='#6765C7'
			/>
			<path
				d='M21.9135 15.6949L21.1293 15.7244L20.9498 15.7303C20.9656 15.6388 20.9845 15.5472 21.0065 15.4616C21.0065 15.4616 21.0034 15.3937 20.9971 15.2727C20.9687 14.7383 20.8837 13.1765 20.7734 11.9867V11.9778L20.5246 6.14686C20.4932 5.41467 20.2979 4.71495 19.9735 4.08314C19.4255 3.02029 18.5122 2.14048 17.391 1.58248C16.5564 1.16914 15.6084 0.932952 14.6101 0.932952H7.90812C7.49239 0.932952 7.08612 0.974286 6.69559 1.05105C4.11308 1.55886 2.10061 3.64914 1.99353 6.14686L1.8644 9.19667V9.27638L1.83606 15.748L1.00461 16.4477L0.828247 10.4898L0.834546 10.3245L1.01406 6.10848C1.08335 4.4581 1.84236 2.91695 3.14621 1.76848C4.20756 0.835524 5.53031 0.245048 6.9507 0.062C7.26564 0.0206667 7.58688 0 7.91127 0H14.6164C16.1407 0 17.602 0.463524 18.8051 1.31676C19.0035 1.45848 19.1924 1.60905 19.3783 1.76848C20.0648 2.37371 20.6002 3.08524 20.9624 3.86467C21.29 4.56733 21.4758 5.3261 21.5104 6.10552L21.7403 11.5025C21.7403 11.5231 21.7403 11.5438 21.7403 11.5645C21.7403 11.5792 21.7403 11.594 21.7403 11.6088L21.8537 14.2511L21.882 14.8918L21.9167 15.686L21.9135 15.6949Z'
				fill='#232343'
			/>
			<path
				d='M25 16.1649C25 17.5968 24.0866 18.7541 22.9592 18.7541C21.8317 18.7541 20.9183 17.5938 20.9183 16.1649C20.9183 16.0143 20.9309 15.8667 20.9498 15.7309C20.9656 15.6393 20.9845 15.5478 21.0065 15.4622C21.1482 14.9308 21.4569 14.5233 21.8506 14.2576C22.0868 14.0982 22.3545 13.9919 22.6379 13.9388C22.7702 13.9152 22.9088 13.9004 23.0473 13.9004C24.1748 13.9004 25.0031 14.733 25.0031 16.1619L25 16.1649Z'
				fill='#232343'
			/>
			<path
				d='M22.9403 18.4238V18.2939C23.8442 18.2939 24.5812 17.3521 24.5812 16.1918C24.5812 15.0315 23.967 14.3643 23.0127 14.3643V14.2344C24.0489 14.2344 24.7166 15.002 24.7166 16.1918C24.7166 17.3816 23.9198 18.4238 22.9403 18.4238Z'
				fill='#414B6E'
			/>
			<path
				d='M21.8789 14.8981C21.4474 15.1993 21.0222 15.4532 21.0065 15.462C21.0065 15.462 20.9089 13.4426 20.7734 11.9871C20.7734 11.9871 20.7734 11.9812 20.7734 11.9782L21.734 11.5059C21.734 11.5265 21.734 11.5472 21.734 11.5679C21.7781 12.5303 21.8915 13.85 22.6347 13.9386C23.0694 13.9918 22.4678 14.4848 21.8757 14.8952L21.8789 14.8981Z'
				fill='#232343'
			/>
			<path
				d='M21.5576 18.0501C21.5576 18.0501 19.0791 16.6684 17.6839 18.4752C16.2918 20.2821 16.1816 22.9008 13.1393 25.0679C13.1393 25.0679 16.7611 25.93 18.8555 23.0337C20.9467 20.1404 21.5576 18.0471 21.5576 18.0471V18.0501Z'
				fill='#D3447F'
			/>
			<path
				d='M21.5577 18.0514C21.5577 18.0514 19.6082 16.9974 18.1501 18.757C16.6919 20.5166 16.4903 23.1324 13.3693 25.1991C13.3693 25.1991 16.9596 26.1763 19.1547 23.3539C21.3499 20.5284 21.5577 18.0543 21.5577 18.0543V18.0514Z'
				fill='#6765C7'
			/>
			<path
				d='M14.0274 22.0339C12.7676 22.5713 9.5206 21.6708 7.76638 21.287C6.00901 20.9061 4.11307 22.276 4.81539 23.8349C5.44527 25.2284 10.5946 25.3406 11.9645 24.9243C14.7455 24.0829 14.0243 22.0339 14.0243 22.0339H14.0274Z'
				fill='#88B5EA'
			/>
			<path
				d='M7.8168 20.6812C7.33179 17.8351 8.66399 14.1682 8.67974 14.1328L8.80886 14.1741C8.79627 14.2096 7.47351 17.8469 7.95222 20.6605L7.8168 20.6812Z'
				fill='#88B5EA'
			/>
			<path
				d='M14.4968 21.4101L14.396 21.3245C16.2258 19.3789 16.9659 14.7289 16.9754 14.6816L17.1108 14.6994C17.1045 14.7466 16.3549 19.435 14.4999 21.4072L14.4968 21.4101Z'
				fill='#88B5EA'
			/>
			<path
				d='M6.67987 15.7188C6.53814 16.8024 6.38697 18.7214 6.42162 19.117C6.48775 19.8433 7.22786 21.1453 7.22786 21.1453C6.64522 20.3541 6.08148 19.5983 6.06573 18.9428C6.03109 17.4873 6.27989 15.8694 6.56964 14.966C6.82159 14.1806 7.83255 13.9002 8.44039 13.8027C7.34439 14.1423 6.82159 14.6383 6.67987 15.7188Z'
				fill='#88B5EA'
			/>
			<path
				d='M17.8351 15.9834C17.6997 16.816 17.4572 18.8088 16.0274 20.6688C15.7187 21.0733 15.0668 21.4306 14.3771 21.8291C14.4023 21.8085 15.7471 21.1412 16.3675 19.8776C16.8966 18.8 17.7501 15.2867 17.7123 14.457C17.9989 14.7139 17.9202 15.4786 17.8414 15.9834H17.8351Z'
				fill='#88B5EA'
			/>
			<path
				d='M10.4245 14.3122L10.2104 18.3215L10.16 19.2751L9.55844 19.2456C8.9632 19.2161 8.50654 18.7349 8.53803 18.1709L8.62307 16.5944L8.69551 15.254C8.727 14.6901 9.23405 14.2531 9.82614 14.2827L10.4277 14.3122H10.4245Z'
				fill='#E56395'
			/>
			<path
				d='M9.79463 21.4223L8.96948 21.381L9.12065 18.5703L9.9458 18.6087L9.79463 21.4223Z'
				fill='#232343'
			/>
			<path
				d='M15.0069 15.9487L14.6479 16.8492L13.8385 18.8716C13.618 19.4237 12.9693 19.7012 12.3866 19.4916L11.8229 19.2879L12.163 18.4317L13.7881 14.3633L14.3519 14.567C14.9313 14.7766 15.2242 15.3937 15.0038 15.9458L15.0069 15.9487Z'
				fill='#E56395'
			/>
			<path
				d='M11.5647 21.8437L10.7899 21.5072L12.1001 18.7969L12.8748 19.1334L11.5647 21.8437Z'
				fill='#232343'
			/>
			<path
				d='M8.85928 23.3539C8.85928 23.3539 8.41836 21.2725 8.95691 21.057C9.53955 20.8237 9.64978 21.2371 10.2261 21.305C10.8277 21.3729 11.741 21.2075 11.7347 21.7449C11.7284 22.2468 9.90803 23.6196 9.90803 23.6196C9.90803 23.6196 9.68758 23.6816 9.35059 23.6373C8.93487 23.5812 8.86243 23.3539 8.86243 23.3539H8.85928Z'
				fill='#232343'
			/>
			<path
				d='M8.7239 23.309C8.7239 23.309 8.28299 21.2276 8.82154 21.012C9.40418 20.7788 9.51441 21.1921 10.0907 21.26C10.6923 21.3279 11.6056 21.1626 11.5993 21.6999C11.593 22.2019 9.81045 23.6397 9.81045 23.6397C9.81045 23.6397 9.68447 23.7135 9.21836 23.6397C8.80264 23.5747 8.72705 23.309 8.72705 23.309H8.7239Z'
				fill='#414B6E'
			/>
			<path
				d='M10.8088 22.0639C10.7333 22.1791 10.0435 22.1702 9.83252 22.1348C9.62466 22.1023 9.08296 21.9192 9.12705 21.8041C9.17114 21.686 9.82307 21.6771 10.0435 21.7096C10.2671 21.7421 10.8844 21.9429 10.8057 22.0639H10.8088Z'
				fill='#E12D2F'
			/>
			<path
				d='M8.84356 29.0339C8.32076 28.9955 7.81055 28.9099 7.31609 28.7889C7.11768 28.3371 6.96966 27.8175 6.93502 27.2448C6.82479 25.4851 7.62789 24.6703 7.8452 24.4961C7.97432 24.4961 8.72703 24.5286 8.83726 24.5315C8.04361 25.9044 8.27352 27.3245 8.53807 28.2899C8.60735 28.5379 8.72073 28.7918 8.84671 29.0369L8.84356 29.0339Z'
				fill='#232343'
			/>
			<path
				d='M7.73185 24.4625C7.73185 24.4625 7.51769 24.3592 7.54918 24.2617C7.59012 24.1348 8.78375 24.2913 9.13019 24.3267C9.47662 24.3621 9.0357 24.5777 9.0357 24.5777C9.0357 24.5777 8.91917 24.5717 8.44361 24.5334C7.85153 24.4861 7.7287 24.4625 7.7287 24.4625H7.73185Z'
				fill='#414B6E'
			/>
			<path
				d='M14.6479 16.8526L13.8385 18.8749C13.618 19.427 12.9693 19.7046 12.3866 19.4949L11.8229 19.2912L12.163 18.435C13.3535 19.055 14.6133 16.8998 14.6447 16.8496L14.6479 16.8526Z'
				fill='#D3447F'
			/>
			<path
				d='M10.2104 18.3228L10.16 19.2765L9.55844 19.2469C8.9632 19.2174 8.50654 18.7362 8.53803 18.1723L8.62307 16.5957C8.84353 18.435 10.2135 18.3228 10.2135 18.3228H10.2104Z'
				fill='#D3447F'
			/>
			<path
				d='M13.0102 29.5177L12.9661 29.3967C16.1627 28.3427 18.213 26.3203 19.372 24.8116C20.6286 23.173 21.1356 21.7648 21.1419 21.75L21.2711 21.7913C21.2711 21.7913 20.7546 23.2291 19.4853 24.8825C18.3138 26.4089 16.2415 28.4519 13.0102 29.5177Z'
				fill='#414B6E'
			/>
			<path
				opacity='0.3'
				d='M10.4026 8.67135C10.7049 8.34659 10.812 8.25802 11.6182 8.37612C13.7913 8.69497 16.336 8.44697 16.9502 8.47354C18.0619 8.52373 16.821 11.051 16.9313 12.5242C17.0195 13.7081 10.9726 13.4129 10.2073 12.6807C9.59001 12.0902 9.47033 9.67221 10.3994 8.67135H10.4026Z'
				fill='white'
			/>
			<path
				d='M9.51755 23.7247C8.63257 23.7247 8.22944 23.3085 8.2074 23.2848L8.30818 23.1992C8.30818 23.1992 8.89712 23.7986 10.1947 23.5181L10.2262 23.645C9.96162 23.7011 9.72856 23.7247 9.51755 23.7247Z'
				fill='#6765C7'
			/>
		</svg>
	)
}

export default Maxicosi
