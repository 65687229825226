import { ENVIRONMENT } from 'constants/environment'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

interface LoadingState {
	isDataLoaded: boolean
	isInitialized: boolean
	isLoading: boolean
	setDataLoaded: (loaded: boolean) => void
	setInitialized: (initialized: boolean) => void
	setLoading: (loading: boolean) => void
}

export const useLoadingStore = create<LoadingState>()(
	devtools(
		(set) => ({
			isDataLoaded: false,
			isInitialized: false,
			isLoading: false,
			setDataLoaded: (loaded) => set({ isDataLoaded: loaded }),
			setInitialized: (initialized) => set({ isInitialized: initialized }),
			setLoading: (loading) => set({ isLoading: loading })
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'loading store'
		}
	)
)
