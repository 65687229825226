import ButtonComponent from 'components/elements/Button/Button'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	startHour: Date | null
	endHour: Date | null
	onStartHour: () => void
	onEndHour: () => void
}

const StartEndHour: FC<Props> = (props) => {
	const { startHour, endHour, onStartHour, onEndHour } = props

	const { t } = useTranslation()

	return (
		<>
			{startHour && !endHour && (
				<ButtonComponent onClick={onEndHour} fullWidth>
					{t('transfer.end_transfer')}
				</ButtonComponent>
			)}
			{!startHour && !endHour && (
				<ButtonComponent onClick={onStartHour} fullWidth>
					{t('transfer.start_transfer')}
				</ButtonComponent>
			)}
		</>
	)
}

export default StartEndHour
