import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './Loading.scss'

interface Props {
	fullscreen?: boolean
}

const Loading: React.FC<Props> = (props) => {
	const { fullscreen } = props

	const { t } = useTranslation()
	return (
		<div className={`loading ${fullscreen ? ' fullscreen' : emptyString}`}>
			<div className='spinner'>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<span>{t('general.loading')}</span>
		</div>
	)
}

export default Loading
