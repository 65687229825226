export interface Province {
	id: number
	code: string
	name: string
	stripeId: string
}
export interface Municipality {
	id: number
	provinceCode: string
	municipalityCode: string
	name: string
}

export enum Langs {
	EN = 'EN',
	ES = 'ES'
}
