import { MinusCircleIcon, PlusCircleIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { Modal } from 'components/elements/Modal/Modal'
import { FC, useState } from 'react'
import { useTransfersStore } from 'stores/useTransfersStore'
import './TransferPoster.scss'

const defaultFontSize = 5

interface Props {
	transferId: number
	isVisible: boolean
	toggleVisibility: () => void
}

const TransferPoster: FC<Props> = (props) => {
	const { transferId, isVisible, toggleVisibility } = props
	const transferState = useTransfersStore()
	const [fontSize, setFontSize] = useState(defaultFontSize)

	const text = transferState.transfers.byId[transferId]?.clientName

	if (!isVisible) return null

	return (
		<Modal
			customClassNames='transfer-poster-modal'
			isFull
			isVisible={isVisible}
			toggleVisibility={toggleVisibility}>
			<div className='transfer-poster-modal-content'>
				<div className='transfer-poster-text' style={{ fontSize: `${fontSize}rem` }}>
					{text}
				</div>
				<div className='transfer-poster-modal-content-footer'>
					<ButtonComponent variant='only-icon' onClick={() => setFontSize(fontSize - 0.5)}>
						<MinusCircleIcon />
					</ButtonComponent>
					<ButtonComponent variant='only-icon' onClick={() => setFontSize(fontSize + 0.5)}>
						<PlusCircleIcon />
					</ButtonComponent>
				</div>
			</div>
		</Modal>
	)
}

export default TransferPoster
