import { PHONE_NUMBER } from 'constants/common'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

const useSupport = () => {
	const { t } = useTranslation()

	const handleSupport = useCallback(() => {
		const message = t('general.support_message')
		const whatsappUrl = `https://api.whatsapp.com/send/?phone=${PHONE_NUMBER}&text=${encodeURIComponent(
			message
		)}&type=phone_number&app_absent=0`
		window.open(whatsappUrl)
	}, [t])

	return handleSupport
}

export default useSupport
