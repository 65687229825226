import { ENVIRONMENT } from 'constants/environment'
import { Invoice } from 'models/Invoice'
import { normalize } from 'normalizr'
import { invoiceSchema } from 'schemas/invoiceSchema'
import { getMyInvoicesService } from 'services/invoices'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface InvoicesState {
	invoices: {
		byId: { [id: number]: Invoice }
		allIds: number[]
	}
	resetInvoices: () => void
	fetchMyInvoices: () => Promise<Invoice[]>
}

export const useInvoicesStore = create<InvoicesState>()(
	devtools(
		(set) => ({
			invoices: {
				byId: {},
				allIds: [] as number[]
			},
			resetInvoices: () => {
				set({
					invoices: {
						byId: {},
						allIds: []
					}
				})
			},
			fetchMyInvoices: async () => {
				const invoices = await getMyInvoicesService()
				const normalizedData = normalize(invoices, [invoiceSchema])
				set((state) => ({
					invoices: {
						byId: { ...state.invoices.byId, ...normalizedData.entities.invoices },
						allIds: normalizedData.result
					}
				}))
				return invoices
			}
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'inovices store'
		}
	)
)
