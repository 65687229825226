import { ENVIRONMENT } from 'constants/environment'
import { Company, CompanyForm } from 'models/Company'
import { getCompanyService, updateCompanyService } from 'services/company'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface CompanyState {
	company: Company
	fetchCompany: () => Promise<Company>
	updateCompany: (company: Partial<CompanyForm>) => Promise<void>
	resetCompany: () => void
}

export const useCompanyStore = create<CompanyState>()(
	devtools(
		(set) => ({
			company: {} as Company,
			fetchCompany: async () => {
				const company = await getCompanyService()
				set({
					company
				})
				return company
			},
			updateCompany: async (company) => {
				const newCompany = await updateCompanyService(company)
				set({
					company: newCompany
				})
			},
			resetCompany: () => {
				set({
					company: {} as Company
				})
			}
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'my company store'
		}
	)
)
