import {
	BellIcon,
	BellRingingIcon,
	CommunicatedFomento,
	Eye,
	PercentageIcon,
	UserCirclePlusIcon,
	WarningIcon
} from 'assets/svgs'
import SubscriptionCard, {
	SubscriptionCardMode
} from 'components/Settings/MyAccount/MySubscription/SubscriptionCard/SubscriptionCard'
import SubscriptionCardContent from 'components/Settings/MyAccount/MySubscription/SubscriptionCard/SubscriptionCardContent/SubscriptionCardContent'
import SubscriptionPlanCardContent from 'components/Settings/MyAccount/MySubscription/SubscriptionCard/SubscriptionCardContent/SubscriptionPlanCardContent'
import SubscriptionTrialCardContent from 'components/Settings/MyAccount/MySubscription/SubscriptionCard/SubscriptionCardContent/SubscriptionTrialCardContent'
import Accordion from 'components/elements/Accordion/Accordion'
import AccordionItem from 'components/elements/Accordion/AccordionItem'
import ActionButton from 'components/elements/ActionButton/ActionButton'
import AssociationList, {
	AssociationOption
} from 'components/elements/AssociationList/AssociationList'
import { default as Button, default as ButtonComponent } from 'components/elements/Button/Button'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import Input from 'components/elements/Input/Input'
import InputSwitch from 'components/elements/Input/InputSwitch'
import InputButton from 'components/elements/InputButton/InputButton'
import List from 'components/elements/List/List'
import ListItem from 'components/elements/List/ListItem/ListItem'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import Search from 'components/elements/Search/Search'
import SuccessMenu from 'components/elements/SuccessMenu/SuccessMenu'
import Toast from 'components/elements/Toast/Toast'
import Tooltip from 'components/elements/Tooltip/Tooltip'
import { TOAST_DURATION } from 'constants/toast'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { SubscriptionFrequency } from 'models/Company'
import { useState } from 'react'
import { toast } from 'sonner'
import { emptyString } from 'utils/common'
import { dateToSpanish } from 'utils/dateUtils'
import './Samples.scss'

const associations: AssociationOption[] = [
	{ id: 0, name: 'Javier Delgado', isSelected: true },
	{ id: 1, name: 'Rubén Cisneros', isSelected: false }
]

const Samples: React.FC = () => {
	const [modalAction, setModalAction] = useState<'default' | 'warning' | 'error' | ''>(emptyString)
	const [successMenu, setSuccessMenu] = useState(false)
	return (
		<div className='samples'>
			<div className='samples-container'>
				<h1>Components</h1>
			</div>
			<div className='samples-container'>
				<h2>Subscription cards</h2>
				<SubscriptionCard>
					<SubscriptionCardContent
						title='Suscripción dos matrículas'
						price={220}
						details={['Suscripción anual', 'Incluye 2 matrículas']}
					/>
				</SubscriptionCard>
				<SubscriptionCard mode={SubscriptionCardMode.DARK}>
					<SubscriptionTrialCardContent title='Prueba gratuita' detail='Te quedan 7 días' />
				</SubscriptionCard>
				<SubscriptionCard mode={SubscriptionCardMode.LIGHT}>
					<SubscriptionPlanCardContent
						subscription={{
							id: 1,
							name: 'Suscripción dos matrículas',
							color: 'default',
							plan: 'anual',
							amount: 220,
							taxPercent: 21,
							extraCompanies: 2,
							maxEnrollments: 2,
							order: 1,
							isActive: true,
							amountVatIncluded: 266.2,
							type: SubscriptionFrequency.year
						}}
						onSubscribe={() => null}
					/>
				</SubscriptionCard>
				<SubscriptionCard mode={SubscriptionCardMode.LIGHT}>
					<SubscriptionPlanCardContent
						subscription={{
							id: 2,
							name: 'Suscripción dos matrículas',
							color: 'violet',
							plan: 'anual',
							amount: 220,
							taxPercent: 21,
							extraCompanies: 2,
							maxEnrollments: 2,
							order: 1,
							isActive: true,
							amountVatIncluded: 266.2,
							type: SubscriptionFrequency.year
						}}
						onSubscribe={() => null}
					/>
				</SubscriptionCard>
				<SubscriptionCard mode={SubscriptionCardMode.LIGHT}>
					<SubscriptionPlanCardContent
						subscription={{
							id: 3,
							name: 'Suscripción dos matrículas',
							color: 'gradient',
							plan: 'month',
							amount: 220,
							taxPercent: 21,
							extraCompanies: 2,
							maxEnrollments: 2,
							order: 1,
							isActive: true,
							amountVatIncluded: 266.2,
							type: SubscriptionFrequency.month
						}}
						onSubscribe={() => null}
					/>
				</SubscriptionCard>
			</div>
			<div className='samples-container'>
				<h2>Toast</h2>
				<ButtonComponent
					onClick={() =>
						toast(
							<Toast
								type='success'
								title='Acción exitosa'
								description='Esto es un toast de éxito. Puedes customizar el icono, el título, la descripción y los botones.'
								primaryButton={{
									text: 'Botón principal',
									onClick: () => {
										toast.dismiss()
									}
								}}
								secondaryButton={{
									text: 'Botón secundario',
									onClick: () => {
										toast.dismiss()
									}
								}}
							/>,
							{ duration: TOAST_DURATION }
						)
					}>
					Toast success
				</ButtonComponent>
				<ButtonComponent
					onClick={() =>
						toast(
							<Toast
								type='error'
								title='Acción errónea'
								description='Esto es un toast de error. Puedes customizar el icono, el título, la descripción y los botones.'
								primaryButton={{
									text: 'Botón principal',
									onClick: () => {
										toast.dismiss()
									}
								}}
								secondaryButton={{
									text: 'Botón secundario',
									onClick: () => {
										toast.dismiss()
									}
								}}
							/>,
							{ duration: TOAST_DURATION }
						)
					}>
					Toast error
				</ButtonComponent>
			</div>
			<div className='samples-container'>
				<h2>SuccessMenu</h2>
				<ButtonComponent onClick={() => setSuccessMenu(true)}>Open success menu</ButtonComponent>
			</div>
			{successMenu && (
				<SuccessMenu title='Este es un título de ejemplo'>
					<ActionButton
						icon={<Eye />}
						backGroundIcon='#f0f2ff'
						onClick={() => console.info('clicked')}>
						Botón de acción
					</ActionButton>
					<ActionButton
						icon={<CommunicatedFomento width={30} height={30} fill='#fff' />}
						backGroundIcon='#713FFF'
						onClick={() => setSuccessMenu(false)}>
						Cerrar SuccessMenu
					</ActionButton>
				</SuccessMenu>
			)}
			<div className='samples-container'>
				<h2>DeatilsCard</h2>
				<DetailsCard>
					<DetailsCardItem title='Título detalle'>Ejemplo de detalle</DetailsCardItem>
					<DetailsCardItem title='Título detalle 2'>Ejemplo de detalle 2</DetailsCardItem>
				</DetailsCard>
			</div>
			<div className='samples-container'>
				<h2>Accordion</h2>
				<Accordion>
					<AccordionItem title='Item 1'>
						<div>
							<p>Contenido 1</p>
						</div>
					</AccordionItem>
					<AccordionItem title='Item 2'>
						<div>
							<p>Contenido 2</p>
						</div>
					</AccordionItem>
				</Accordion>
			</div>
			<div className='samples-container'>
				<div className='samples-container'>
					<h2>Tooltip</h2>
					<Tooltip
						title='Esto es un tooltip'
						description='Este campo permite establecer el porcentaje de beneficio asociado a este conductor. Este porcentaje se utilizará en cálculos de cobro. Es importante configurarlo correctamente para un seguimiento financiero preciso.'
					/>
					<InputSwitch
						id='tooltip-switch'
						label='This is a switch with tooltip'
						value
						tooltip={{
							title: 'Esto es un tooltip',
							description:
								'Este campo permite establecer el porcentaje de beneficio asociado a este conductor. Este porcentaje se utilizará en cálculos de cobro. Es importante configurarlo correctamente para un seguimiento financiero preciso.'
						}}
					/>
					<FormSectionLayout
						title='Form section with Tooltip'
						tooltip={{
							title: 'Esto es un tooltip',
							description:
								'Este campo permite establecer el porcentaje de beneficio asociado a este conductor. Este porcentaje se utilizará en cálculos de cobro. Es importante configurarlo correctamente para un seguimiento financiero preciso.'
						}}>
						<Input
							id='input-tooltip'
							label='Input with tooltip'
							placeholder='Input with tooltip'
							type='text'
							tooltip={{
								title: 'Esto es un tooltip',
								description:
									'Este campo permite establecer el porcentaje de beneficio asociado a este conductor. Este porcentaje se utilizará en cálculos de cobro. Es importante configurarlo correctamente para un seguimiento financiero preciso.'
							}}
						/>
					</FormSectionLayout>
				</div>
				<h2>Modals</h2>
				<div className='samples-row'>
					<Button onClick={() => setModalAction('default')}>Modal Actions Default type</Button>
					<ModalActions
						icon={<UserCirclePlusIcon />}
						title='Modal Action Default type'
						description='This is a modal action  default type. It has a primay and a secondary button.'
						isVisible={modalAction === 'default'}
						onClose={() => setModalAction(emptyString)}
						primaryButton={{
							text: 'Primary button',
							onClick: () => setModalAction(emptyString)
						}}
						secondaryButton={{
							text: 'Secondary button',
							onClick: () => setModalAction(emptyString)
						}}
					/>
					<Button onClick={() => setModalAction('warning')}>Modal Actions Warning type</Button>
					<ModalActions
						type='warning'
						icon={<BellRingingIcon />}
						title='Modal Action Warning type'
						description='This is a modal action warning type. It has a primay and a secondary button.'
						isVisible={modalAction === 'warning'}
						onClose={() => setModalAction(emptyString)}
						primaryButton={{
							text: 'Primary button',
							onClick: () => setModalAction(emptyString)
						}}
						secondaryButton={{
							text: 'Secondary button',
							onClick: () => setModalAction(emptyString)
						}}
					/>
					<Button onClick={() => setModalAction('error')}>Modal Actions Error type</Button>
					<ModalActions
						type='error'
						icon={<WarningIcon />}
						title='Modal Action Error type'
						description='This is a modal action error type. It has a primay and a secondary button.'
						isVisible={modalAction === 'error'}
						onClose={() => setModalAction(emptyString)}
						primaryButton={{
							text: 'Primary button',
							onClick: () => setModalAction(emptyString)
						}}
						secondaryButton={{
							text: 'Secondary button',
							onClick: () => setModalAction(emptyString)
						}}
					/>
				</div>
			</div>
			<div className='samples-container'>
				<h2>AssociationListButtons</h2>
				<AssociationList
					isLoading={false}
					associations={associations}
					title='Usuarios asociados'
					addButtonText='Asociar usuarios'
					modalContent={{
						title: 'Asociar a un usuario',
						subtitle: 'Usuarios',
						description: 'Selecciona los usuarios que deseas asociar al proveedor.'
					}}
					handleSelection={(selected) => console.info(selected)}
				/>
			</div>
			<div className='samples-container'>
				<div className='samples-container'>
					<h2>List</h2>
					<List>
						<ListItem
							left={<BellIcon />}
							right={
								<ButtonComponent variant='only-text' onClick={() => null}>
									Button
								</ButtonComponent>
							}
							onClick={() => null}>
							List item
						</ListItem>
					</List>
				</div>
				<div className='samples-container'>
					<h2>Search</h2>
					<Search onChange={() => null} />
				</div>
				<div className='samples-container'>
					<h2>Input</h2>
					<Input id='input' label='This is an input' icon={<PercentageIcon />} labelHelper />
				</div>
				<h2>Button</h2>
				<div className='samples-row'>
					<Button onClick={() => null}>Primary button</Button>
					<Button disabled onClick={() => null}>
						Primary button disabled
					</Button>
					<Button onClick={() => null} rightIcon={<BellIcon />}>
						Primary button with right icon
					</Button>
					<Button onClick={() => null} leftIcon={<BellIcon />}>
						Primary button with left icon
					</Button>
					<Button onClick={() => null} leftIcon={<BellIcon />} rightIcon={<BellIcon />}>
						Primary button with both icons
					</Button>
					<Button onClick={() => null} icon={<BellIcon />} />
					<Button onClick={() => null} fullWidth>
						Primary button full width
					</Button>
				</div>
				<div className='samples-row'>
					<Button variant='secondary' onClick={() => null}>
						Secondary button
					</Button>
					<Button variant='secondary' disabled onClick={() => null}>
						Secondary button disabled
					</Button>
					<Button variant='secondary' onClick={() => null} rightIcon={<BellIcon />}>
						Secondary button with right icon
					</Button>
					<Button variant='secondary' onClick={() => null} leftIcon={<BellIcon />}>
						Secondary button with left icon
					</Button>
					<Button
						variant='secondary'
						onClick={() => null}
						leftIcon={<BellIcon />}
						rightIcon={<BellIcon />}>
						Secondary button with both icons
					</Button>
					<Button variant='secondary' onClick={() => null} icon={<BellIcon />} />
					<Button variant='secondary' onClick={() => null} fullWidth>
						Secondary button full width
					</Button>
				</div>
				<div className='samples-row'>
					<Button variant='text' onClick={() => null}>
						Text button
					</Button>
					<Button variant='text' disabled onClick={() => null}>
						Text button disabled
					</Button>
					<Button variant='text' onClick={() => null} rightIcon={<BellIcon />}>
						Text button with right icon
					</Button>
					<Button variant='text' onClick={() => null} leftIcon={<BellIcon />}>
						Text button with left icon
					</Button>
					<Button
						variant='text'
						onClick={() => null}
						leftIcon={<BellIcon />}
						rightIcon={<BellIcon />}>
						Text button with both icons
					</Button>
				</div>
				<div className='samples-row'>
					<Button variant='only-text' onClick={() => null}>
						Only text without paddings
					</Button>
				</div>
				<div className='samples-row'>
					<Button variant='only-icon' icon={<BellIcon />} onClick={() => null} />
				</div>
			</div>
			<div className='samples-container'>
				<h2>InputButton</h2>
				<InputButton type='select' id='test' onClick={() => null} placeholder='Seleccionar' />
				<InputButton type='date' id='test' onClick={() => null} placeholder='Seleccionar fecha' />
				<InputButton type='time' id='test' onClick={() => null} placeholder='Seleccionar hora' />
				<InputButton type='select' id='test' onClick={() => null} text='Test' />
				<InputButton type='date' id='test' onClick={() => null} text={dateToSpanish(new Date())} />
				<InputButton type='time' id='test' onClick={() => null} text={'12:00'} />
			</div>
		</div>
	)
}

export default Samples
