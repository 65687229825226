import { FormikProps } from 'formik'
import { emptyString } from './common'

export const getFormikError = <T>(formik: FormikProps<T>, field: keyof T) => {
	if (!formik.touched[field] || !formik.errors[field]) {
		return emptyString
	}
	return formik.errors[field]
}

export const handleCheckboxChange =
	<T>(formik: FormikProps<T>, fieldName: Extract<keyof T, string>, value: boolean | undefined) =>
	(e: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target
		if (!checked) {
			formik.setFieldValue(fieldName, undefined)
		} else {
			formik.setFieldValue(fieldName, value)
		}
	}
