import React from 'react'

interface Props {
	width?: number
	height?: number
}

const RequestShare: React.FC<Props> = (props) => {
	const { width = 15, height = 15 } = props

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 20 19'
			fill='none'>
			<path
				d='M17.5807 1.9193C17.3942 1.7327 17.1613 1.59919 16.906 1.53253C16.6507 1.46587 16.3822 1.46846 16.1283 1.54004L16.1119 1.54524L1.86863 5.86328C1.57932 5.94716 1.32238 6.11708 1.13194 6.35046C0.94149 6.58383 0.826553 6.86963 0.802391 7.16988C0.77823 7.47013 0.845987 7.77062 0.996662 8.03145C1.14734 8.29228 1.3738 8.50109 1.64597 8.63016L7.9041 11.5989L10.8728 17.857C10.9913 18.1112 11.1801 18.3263 11.4168 18.4767C11.6535 18.6271 11.9283 18.7067 12.2088 18.7061C12.2511 18.7061 12.2941 18.7061 12.3372 18.7009C12.6372 18.677 12.9226 18.5616 13.155 18.3704C13.3874 18.1791 13.5556 17.9212 13.6368 17.6314L17.9548 3.38809C17.957 3.38279 17.9587 3.37733 17.96 3.37176C18.0316 3.1178 18.0342 2.84935 17.9675 2.59405C17.9008 2.33876 17.7673 2.10584 17.5807 1.9193ZM12.1524 16.3964L9.64972 11.1128L13.0081 7.75438C13.1754 7.58706 13.2694 7.36014 13.2694 7.12352C13.2694 6.8869 13.1754 6.65997 13.0081 6.49266C12.8408 6.32534 12.6139 6.23135 12.3773 6.23135C12.1406 6.23135 11.9137 6.32534 11.7464 6.49266L8.388 9.85106L3.10363 7.34766L16.086 3.41406L12.1524 16.3964Z'
				fill='#F4516C'
			/>
		</svg>
	)
}

export default RequestShare
