import { Fomento, SharedTransfer, StartEndTransfer, TypeStartEndTransfer } from 'models/index'
import { useEffect, useState } from 'react'
import {
	useDriversStore,
	useFomentoStore,
	useProvidersStore,
	useTransfersStore,
	useUserStore,
	useVehiclesStore
} from 'stores/index'
import { useTransfersShareStore } from 'stores/useTransfersShareStore'
import { firstElement } from 'utils/common'
import { currentUserIsAdmin } from 'utils/currentUser'

export const useTransferDetails = (id: number, hasFetchdata?: boolean) => {
	const transferState = useTransfersStore()
	const transferShareState = useTransfersShareStore()
	const transfer = transferState.transfers.byId[id]
	const fomentoState = useFomentoStore()

	const provider = useProvidersStore((state) => state.providers.byId[transfer?.providerId])
	const driver = useDriversStore((state) => state.drivers.byId[transfer?.driverId])
	const vehicle = useVehiclesStore((state) => state.vehicles.byId[transfer?.vehicleId])
	const userState = useUserStore()
	const user = userState.users.byId[transfer?.employeeId]
	const currentUser = userState.currentUser
	const isAdmin = currentUserIsAdmin()

	const [fomento, setFomento] = useState<Fomento>()
	const [accepted, setAccepted] = useState<SharedTransfer>()
	const [pendings, setPendings] = useState<SharedTransfer[]>([])
	const [hasStart, setHasStart] = useState<boolean>(false)
	const [hasEnd, setHasEnd] = useState<boolean>(false)
	const [startEndDetails, setStartEndDetails] = useState<StartEndTransfer | null>(null)

	const fetchAll = () => {
		fetchFomentoByBookingId(id)
		fetchSharedTransfersByBookingId(id)
		fetchDetails(id)
		fetchStartEndTransfersByBookingId(id)
	}

	useEffect(() => {
		if (hasFetchdata) {
			fetchAll()
		}
		getStartEndInfo(id)
		getSharedInfo(id)
	}, [id])

	useEffect(() => {
		getSharedInfo(id)
	}, [transferShareState.sharedTransfers])

	useEffect(() => {
		getStartEndInfo(id)
	}, [transferState.startEndTransfers])

	useEffect(() => {
		const fomento = fomentoState.fomento.allIds
			.filter((fomentoId) => fomentoState.fomento.byId[fomentoId].bookingId === id)
			.map((fomentoId) => fomentoState.fomento.byId[fomentoId])[firstElement]

		if (fomento) {
			setFomento(fomento)
		}
	}, [fomentoState.fomento])

	const fetchDetails = async (id: number) => {
		transferState.fetchTransferDetailsById(id)
	}

	const fetchFomentoByBookingId = async (bookingId: number) => {
		const fomento = await fomentoState.fetchFomentoByBookingIdService(bookingId)
		if (!fomento) return
		setFomento(fomento)
	}

	const fetchSharedTransfersByBookingId = async (bookingId: number) => {
		await transferShareState.fetchShareTransferByBookingId(bookingId)
	}

	const fetchStartEndTransfersByBookingId = async (bookingId: number) => {
		await transferState.fetchStartEndTransfersByBookingId(bookingId)
	}

	const getSharedInfo = (id: number) => {
		const shared = transferShareState.getSharedTransfersByBookingId(id)

		const accepted = shared.find((transfer) => transfer.acceptedTime !== null)
		if (accepted) {
			setAccepted(accepted)
		}

		const pendings = shared.filter(
			(transfer) => transfer.acceptedTime === null && transfer.rejectedTime === null
		)
		if (pendings) {
			setPendings(pendings)
		}
	}

	const getStartEndInfo = (id: number) => {
		const startEnd = transferState.getStartEndTransfersByBookingId(id)
		const startEndByDriverId = startEnd.filter((transfer) => {
			if (isAdmin) {
				return transfer.driverId === driver?.id
			}
			return transfer.userId === currentUser.id && transfer.driverId === driver?.id
		})
		if (startEndByDriverId.length) {
			setStartEndDetails(startEndByDriverId[firstElement])
			setHasStart(startEndByDriverId[firstElement].type === TypeStartEndTransfer.start)
			setHasEnd(startEndByDriverId[firstElement].type === TypeStartEndTransfer.end)
		}
	}

	return {
		transfer,
		provider,
		driver,
		vehicle,
		user,
		fomento,
		accepted,
		pendings,
		hasStart,
		hasEnd,
		startEndDetails,
		onRefresh: fetchAll
	}
}
