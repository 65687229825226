import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import './PaidTag.scss'

interface Props {
	paid: boolean
}

const PaidTag: FC<Props> = (props) => {
	const { paid } = props

	const { t } = useTranslation()

	return <div className='paid-tag'>{paid ? t('general.paid') : t('general.not_paid')}</div>
}

export default PaidTag
