import Input from 'components/elements/Input/Input'
import { FormikProps } from 'formik'
import { AllStepForms, RouteTypeName, StepAddressesForm } from 'models/Transfer'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormikError } from 'utils/formikUtils'

interface Props {
	formik: FormikProps<StepAddressesForm> | FormikProps<AllStepForms>
	route?: keyof typeof RouteTypeName
}

const StepAddressesInputs: FC<Props> = (props) => {
	const { formik, route } = props
	const [showFlight, setShowFlight] = useState(route === RouteTypeName.arrival)

	const { t } = useTranslation()

	useEffect(() => {
		if (route === RouteTypeName.arrival) {
			setShowFlight(true)
		} else {
			setShowFlight(false)
		}
	}, [route])

	return (
		<>
			<Input
				type='text'
				label={t('transfer.create_steps.step_addresses.label.origin')}
				id='originAddress'
				placeholder={t('transfer.create_steps.step_addresses.placeholders.origin')}
				onChange={(e) => formik.setFieldValue('originAddress', e.target.value)}
				className={formik.values['originAddress'] ? 'selected' : 'not-selected'}
				valueSelected={formik.values['originAddress']}
				error={getFormikError(formik as FormikProps<StepAddressesForm>, 'originAddress')}
			/>
			<Input
				type='text'
				label={t('transfer.destination')}
				placeholder={t('transfer.create_steps.step_addresses.placeholders.destination')}
				id='destinationAddress'
				onChange={(e) => formik.setFieldValue('destinationAddress', e.target.value)}
				className={formik.values['destinationAddress'] ? 'selected' : 'not-selected'}
				valueSelected={formik.values['destinationAddress']}
				error={getFormikError(formik as FormikProps<StepAddressesForm>, 'destinationAddress')}
			/>
			{showFlight && (
				<Input
					type='text'
					label={t('transfer.flight')}
					placeholder={t('transfer.create_steps.step_addresses.placeholders.flight')}
					id='flight'
					onChange={(e) => formik.setFieldValue('flight', e.target.value)}
					className={formik.values['flight'] ? 'selected' : 'not-selected'}
					valueSelected={formik.values['flight']}
					error={getFormikError(formik as FormikProps<StepAddressesForm>, 'flight')}
				/>
			)}
		</>
	)
}

export default StepAddressesInputs
