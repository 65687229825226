import { FetchResponse, Fomento, FomentoInfo, FomentoPost } from 'models/index'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getFomentoByDateService = async (date: string) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Fomento[]>>(
			`${endpoints.fomentoDaily}/${date}`,
			{
				method: 'GET',
				token,
				responseType: 'json'
			}
		)
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getFomentoByBookingIdService = async (bookingId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Fomento>>(
			`${endpoints.fomentoNotifications}/${bookingId}`,
			{
				method: 'GET',
				token,
				responseType: 'json'
			}
		)
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const createFomentoService = async (fomento: FomentoPost, bookingId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Fomento>>(endpoints.fomento, {
			method: 'POST',
			token,
			responseType: 'json',
			bodyReq: {
				bookingId,
				...fomento
			}
		})
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const updateFomentoService = async (fomento: FomentoPost, bookingId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Fomento>>(
			`${endpoints.fomento}/${bookingId}`,
			{
				method: 'PUT',
				token,
				responseType: 'json',
				bodyReq: fomento
			}
		)
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getFomentoInfoByBookingIdService = async (bookingId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Nullable<FomentoInfo>>>(
			`${endpoints.fomento}/${bookingId}`,
			{
				method: 'GET',
				token,
				responseType: 'json'
			}
		)
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const communicateFomentoService = async (bookingId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Fomento>>(
			`${endpoints.communicate}/${bookingId}`,
			{
				method: 'GET',
				token,
				responseType: 'json'
			}
		)
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const confirmFomentoService = async (bookingId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Fomento>>(
			`${endpoints.confirm}/${bookingId}`,
			{
				method: 'GET',
				token
			}
		)
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const cancelFomentoService = async (bookingId: number): Promise<Fomento> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Fomento>>(`${endpoints.cancel}/${bookingId}`, {
			method: 'GET',
			token
		})
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getCommunicationPdfService = async (bookingId: number): Promise<string> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<string>>(
			`${endpoints.fomentoCommunicationPdf}/${bookingId}`,
			{
				method: 'GET',
				token
			}
		)
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}

export const getConfirmationPdfService = async (bookingId: number): Promise<string> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<string>>(
			`${endpoints.fomentoConfirmationPdf}/${bookingId}`,
			{
				method: 'GET',
				token
			}
		)
		if ('error' in response) throw response
		return response.data
	} catch (err) {
		throw err
	}
}
