import ButtonComponent from 'components/elements/Button/Button'
import DragAndDropColumnList, {
	ColumnItem
} from 'components/elements/DragAndDropColumnList/DragAndDropColumnList'
import ModalFormLayout from 'components/elements/Modal/ModalFormLayout/ModalFormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './Historical.scss'

interface Props {
	isVisible: boolean
	loading: boolean
	toggleModalVisibility: () => void
	exportData: (columns: ColumnItem[]) => void
}

const GenerateDocumentModal: React.FC<Props> = (props) => {
	const { isVisible, loading, toggleModalVisibility, exportData } = props
	const [columns, setColumns] = useState<ColumnItem[]>([])

	const { t } = useTranslation()

	return (
		<ModalFormLayout
			title={t('historical.export_modal.title')}
			isVisible={isVisible}
			toggleVisibility={toggleModalVisibility}
			footer={
				<>
					<ButtonComponent variant='secondary' onClick={toggleModalVisibility} fullWidth>
						{t('general.cancel')}
					</ButtonComponent>
					<ButtonComponent
						variant='primary'
						onClick={() => exportData(columns)}
						fullWidth
						loading={loading}>
						{t('historical.export_modal.generate_document')}
					</ButtonComponent>
				</>
			}>
			<FormSectionLayout className='generate-document-modal-description' noDivider>
				<p>{t('historical.export_modal.description')}</p>
			</FormSectionLayout>
			<DragAndDropColumnList onSort={setColumns} />
		</ModalFormLayout>
	)
}

export default GenerateDocumentModal
