import { Vehicle } from 'models/Vehicle'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useVehiclesStore } from 'stores/useVehiclesStore'

const useVehicles = (vehicleId?: number) => {
	const vehicleState = useVehiclesStore()
	const [vehicle, setVehicle] = useState<Vehicle | null>(null)
	const [vehicles, setVehicles] = useState<Vehicle[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<Nullable<string>>(null)
	const { t } = useTranslation()

	useEffect(() => {
		if (vehicleId !== undefined) {
			fetchVehicle(vehicleId)
		} else {
			fetchVehicles()
		}
	}, [vehicleId])

	useEffect(() => {
		setVehicles(vehicleState.vehicles.allIds.map((id) => vehicleState.vehicles.byId[id]))
	}, [vehicleState.vehicles])

	const fetchVehicle = async (vehicleId: number) => {
		setLoading(true)
		setError(null)

		try {
			let fetchedVehicle = vehicleState.vehicles.byId[vehicleId]
			if (!fetchedVehicle) {
				fetchedVehicle = await vehicleState.fetchVehicleById(vehicleId)
			}
			setVehicle(fetchedVehicle)
			setVehicles([])
		} catch (e) {
			setError(t('vehicle.vehicle_error'))
			console.error(e)
		} finally {
			setLoading(false)
		}
	}

	const fetchVehicles = async () => {
		setLoading(true)
		setError(null)

		try {
			let fetchedVehicles = vehicleState.vehicles.allIds.map((id) => vehicleState.vehicles.byId[id])
			if (fetchedVehicles.length === 0) {
				fetchedVehicles = await vehicleState.fetchVehicles()
			}
			setVehicle(null)
		} catch (e) {
			setError(t('vehicle.vehicles_error'))
			console.error(e)
		} finally {
			setLoading(false)
		}
	}

	const removeVehicle = async (vehicleId: number) => {
		setLoading(true)
		setError(null)

		try {
			await vehicleState.removeVehicle(vehicleId)
		} catch (e) {
			setError(t('vehicle.delete_vehicle_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const activateVehicle = async (vehicleId: number) => {
		try {
			await vehicleState.updateVehicle({ isActive: true }, vehicleId)
		} catch (e) {
			setError(t('vehicle.activate_vehicle_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const deactivateVehicle = async (vehicleId: number) => {
		try {
			await vehicleState.updateVehicle({ isActive: false }, vehicleId)
		} catch (e) {
			setError(t('vehicle.deactivate_vehicle_error'))
			console.error(e)
		}

		setLoading(false)
	}

	return {
		vehicle,
		vehicles,
		loading,
		error,
		removeVehicle,
		activateVehicle,
		deactivateVehicle
	}
}

export default useVehicles
