import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Loading from 'components/elements/Loading/Loading'
import PdfViewer from 'components/elements/PdfViewer/PdfViewer'
import { ERROR_URL, RECEIPTS_URL } from 'constants/routes'
import useFetchPdf from 'hooks/useFetchPdf'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { endpoints } from 'services/endpoints'

const MyReceiptPdf: FC = () => {
	const { invoiceId } = useParams()

	const { t } = useTranslation()
	const { pdfBase64, isLoading, error } = useFetchPdf(endpoints.ownInvoicePdf + '/' + invoiceId)
	const navigate = useNavigate()

	if (isLoading) return <Loading fullscreen />
	if (error)
		navigate(ERROR_URL, {
			state: { description: error, link: RECEIPTS_URL }
		})
	if (!pdfBase64) return null

	return (
		<FormLayout
			title={t('settings.my_account.subscription.invoice')}
			leftButton={<ButtonBack url={RECEIPTS_URL} />}>
			<PdfViewer pdf={pdfBase64} />
		</FormLayout>
	)
}

export default MyReceiptPdf
