import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const ImageSquareIcon: FC<Props> = (props) => {
	const { width = 38, height = 38 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 38 38'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M30.6313 5.03906H7.36208C6.74494 5.03906 6.15308 5.28422 5.7167 5.7206C5.28031 6.15699 5.03516 6.74885 5.03516 7.36599V30.6352C5.03516 31.2524 5.28031 31.8442 5.7167 32.2806C6.15308 32.717 6.74494 32.9621 7.36208 32.9621H30.6313C31.2484 32.9621 31.8403 32.717 32.2767 32.2806C32.7131 31.8442 32.9582 31.2524 32.9582 30.6352V7.36599C32.9582 6.74885 32.7131 6.15699 32.2767 5.7206C31.8403 5.28422 31.2484 5.03906 30.6313 5.03906ZM7.36208 7.36599H30.6313V18.6196L27.0406 15.0274C26.6042 14.5913 26.0126 14.3464 25.3957 14.3464C24.7789 14.3464 24.1872 14.5913 23.7509 15.0274L8.14305 30.6352H7.36208V7.36599ZM30.6313 30.6352H11.4342L25.3957 16.6737L30.6313 21.9093V30.6352ZM14.3428 17.8371C15.0332 17.8371 15.708 17.6324 16.282 17.2489C16.856 16.8654 17.3034 16.3203 17.5675 15.6825C17.8317 15.0447 17.9008 14.3429 17.7662 13.6658C17.6315 12.9887 17.2991 12.3668 16.8109 11.8787C16.3228 11.3905 15.7009 11.0581 15.0238 10.9234C14.3467 10.7888 13.6449 10.8579 13.0071 11.1221C12.3694 11.3862 11.8242 11.8336 11.4407 12.4076C11.0572 12.9816 10.8525 13.6564 10.8525 14.3468C10.8525 15.2725 11.2202 16.1603 11.8748 16.8148C12.5293 17.4694 13.4171 17.8371 14.3428 17.8371ZM14.3428 13.1833C14.573 13.1833 14.7979 13.2515 14.9892 13.3794C15.1806 13.5072 15.3297 13.6889 15.4177 13.9015C15.5058 14.1141 15.5288 14.348 15.484 14.5737C15.4391 14.7994 15.3283 15.0067 15.1655 15.1694C15.0028 15.3322 14.7955 15.443 14.5698 15.4879C14.3441 15.5328 14.1102 15.5097 13.8976 15.4217C13.685 15.3336 13.5033 15.1845 13.3755 14.9931C13.2476 14.8018 13.1794 14.5769 13.1794 14.3468C13.1794 14.0382 13.302 13.7423 13.5202 13.5241C13.7383 13.3059 14.0343 13.1833 14.3428 13.1833Z'
				fill='#130D2A'
				fillOpacity='0.5'
			/>
		</svg>
	)
}

export default ImageSquareIcon
