import { CarAddIcon, Eye, PencilSimpleLineIcon, PlusCircleIcon } from 'assets/svgs'
import ActionButton from 'components/elements/ActionButton/ActionButton'
import SuccessMenu from 'components/elements/SuccessMenu/SuccessMenu'
import {
	TRANSFER_STEPS,
	TRANSFER_URL,
	VEHICLES_EDIT_URL,
	VEHICLES_NEW_URL,
	VEHICLES_VIEW_URL
} from 'constants/routes'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface Props {
	vehicleId: number
}

const VehicleCreationSuccess: React.FC<Props> = (props) => {
	const { vehicleId } = props
	const { t } = useTranslation()
	const navigate = useNavigate()

	return (
		<SuccessMenu title={t('vehicle.success_menu.title')}>
			<ActionButton
				icon={<Eye />}
				backGroundIcon='#f0f2ff'
				onClick={() => navigate(`${VEHICLES_VIEW_URL}/${vehicleId}`)}>
				{t('vehicle.success_menu.view_vehicle')}
			</ActionButton>
			<ActionButton
				icon={<PencilSimpleLineIcon />}
				backGroundIcon='#713fff'
				onClick={() => navigate(`${VEHICLES_EDIT_URL}/${vehicleId}`)}>
				{t('vehicle.success_menu.edit_vehicle')}
			</ActionButton>
			<ActionButton
				icon={<PlusCircleIcon color='#fff' />}
				backGroundIcon='#130d2a'
				onClick={() => navigate(`${TRANSFER_URL}/create/${TRANSFER_STEPS.ROUTE}`)}>
				{t('vehicle.success_menu.add_transfer')}
			</ActionButton>
			<ActionButton
				icon={<CarAddIcon />}
				backGroundIcon='#130d2a'
				onClick={() => navigate(`${VEHICLES_NEW_URL}`)}>
				{t('vehicle.success_menu.add_vehicle')}
			</ActionButton>
		</SuccessMenu>
	)
}

export default VehicleCreationSuccess
