export interface User {
	id: number
	userTypeId: AllUserRoles
	companyId: number
	name: string
	lastName: string
	username: string
	password: string
	email: string
	isActive: boolean
	isVisible: boolean
	lastConnection: Date
	restart: boolean
	showTutorial: boolean
	allowedProviders: number[]
	allowedDrivers: number[]
	token?: string
}

export interface UserPost {
	name: string
	lastName: string
	username: string
	password: string
	email: string
	userTypeId: Nullable<NonAdminRoleKeys>
	sendRecoveryPass?: boolean
}

export interface UserForm {
	name: string
	lastName: string
	email: string
	isActive: boolean
	userTypeId: AllUserRoles
}

export interface ChangeMyPasswordForm {
	newPassword: string
}

export enum UserRoles {
	'super-admin' = 'super-admin',
	admin = 'admin',
	driver = 'driver',
	provider = 'provider',
	revisor = 'revisor'
}

export type AllUserRoles = keyof typeof UserRoles

export type NonAdminRoleKeys = Exclude<AllUserRoles, 'super-admin' | 'admin'>

export interface Role {
	id: number
	name: string
	type: AllUserRoles
	description: string
	showInUserList: boolean
}

export interface UserRegister {
	name: string
	email: string
	password: string
	confirmPassword: string
}
