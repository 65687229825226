import EuroIcon from 'assets/svgs/EuroIcon'
import CheckboxInput from 'components/elements/Input/CheckboxInput'
import Input from 'components/elements/Input/Input'
import { FormikProps } from 'formik'
import { AllStepForms, StepPaymentForm } from 'models/Transfer'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	formik: FormikProps<StepPaymentForm> | FormikProps<AllStepForms>
}

const StepPricesInputs: FC<Props> = (props) => {
	const { formik } = props

	const { t } = useTranslation()

	return (
		<>
			<Input
				type='number'
				valueSelected={formik.values['price']?.toString()}
				id='price'
				styles={{ marginTop: '1rem' }}
				label={t('transfer.create_steps.step_prices.inputs.price.label')}
				labelHelper
				placeholder={t('transfer.price')}
				icon={<EuroIcon />}
				onChange={formik.handleChange}
				error={formik.errors['price']}
			/>
			<CheckboxInput
				label={t('transfer.is_paid')}
				id='isPaid'
				styles={{ margin: '1rem 0 2rem 0' }}
				onChange={(e) => formik.setFieldValue('isPaid', e.target.checked)}
				checked={formik.values['isPaid']}
			/>
			<Input
				type='number'
				valueSelected={formik.values['commission']?.toString()}
				icon={<EuroIcon />}
				id='commission'
				label={t('transfer.create_steps.step_prices.inputs.commission.label')}
				placeholder={t('transfer.create_steps.step_prices.inputs.commission.placeholder')}
				labelHelper
				onChange={formik.handleChange}
				error={formik.errors['commission']}
			/>
			<Input
				type='number'
				valueSelected={formik.values['toll']?.toString()}
				icon={<EuroIcon />}
				id='toll'
				label={t('transfer.create_steps.step_prices.inputs.toll.label')}
				placeholder={t('transfer.create_steps.step_prices.inputs.toll.placeholder')}
				labelHelper
				onChange={formik.handleChange}
				error={formik.errors['toll']}
			/>
		</>
	)
}

export default StepPricesInputs
