import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CaretDownIcon: FC<Props> = (props) => {
	const { width = 20, height = 20 } = props

	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width={width}
			height={height}
			viewBox='0 0 20 20'
			fill='none'>
			<path
				d='M16.9132 8.16256L10.6632 14.4126C10.5762 14.5 10.4727 14.5693 10.3587 14.6166C10.2447 14.6639 10.1226 14.6883 9.99918 14.6883C9.8758 14.6883 9.75362 14.6639 9.63967 14.6166C9.52571 14.5693 9.42222 14.5 9.33512 14.4126L3.08512 8.16256C2.909 7.98644 2.81006 7.74757 2.81006 7.4985C2.81006 7.24943 2.909 7.01056 3.08512 6.83444C3.26124 6.65832 3.50011 6.55938 3.74918 6.55938C3.99826 6.55938 4.23713 6.65832 4.41325 6.83444L9.99997 12.4212L15.5867 6.83366C15.7628 6.65754 16.0017 6.55859 16.2507 6.55859C16.4998 6.55859 16.7387 6.65754 16.9148 6.83366C17.0909 7.00978 17.1899 7.24865 17.1899 7.49772C17.1899 7.74679 17.0909 7.98566 16.9148 8.16178L16.9132 8.16256Z'
				fill='#343330'
			/>
		</svg>
	)
}

export default CaretDownIcon
