import { emptyString } from 'utils/common'
import './Card.scss'

interface Props {
	children: React.ReactNode
	className?: string
	style?: React.CSSProperties
	onClick?: () => void
	role?: string
}

const CardComponent = (props: Props) => {
	const {
		children,
		className,
		style,
		role,
		onClick = () => {
			return
		}
	} = props

	return (
		<div
			className={`card-component ${className ?? emptyString}`}
			style={style}
			onClick={onClick}
			role={role}
			onKeyDown={(e) => {
				if (e.key === 'Enter' || e.key === ' ') {
					onClick()
				}
			}}>
			{children}
		</div>
	)
}

export default CardComponent
