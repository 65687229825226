import InputButton from 'components/elements/InputButton/InputButton'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'
import { useModal } from 'hooks/useModal'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDriversStore } from 'stores/useDriversStore'
import { emptyString, firstElement } from 'utils/common'
import './SelectOptions.scss'

const id = 'select-driver'

interface Props {
	onSelect: (id: number | number[]) => void
	selectedId?: number | number[] | null
	error?: string
	label: string
	isLabelOptional?: boolean
	isMultiple?: boolean
}

const SelectDriverOptions: React.FC<Props> = (props) => {
	const { onSelect, selectedId, error, label, isLabelOptional = false, isMultiple = false } = props
	const { t } = useTranslation()
	const [modalIsVisible, toggleModalVisibility] = useModal()
	const driversState = useDriversStore()

	const titleAndPlaceholder = t('form.select_at', { field: t('transfer.driver') })
	const isMultipleAndArray = isMultiple && Array.isArray(selectedId)

	useEffect(() => {
		fetchAssociations()
	}, [])

	const fetchAssociations = async () => {
		await driversState.fetchAllDriverAssociations()
	}

	const handleClick = (ids: number[]) => {
		const selected = isMultiple ? ids : ids[firstElement]
		onSelect(selected)
		toggleModalVisibility()
	}

	const getMultipleValueSelected = () => {
		if (isMultipleAndArray && selectedId.length) {
			const drivers = selectedId.map((id) => driversState.drivers.byId[id]?.name)
			return drivers.join(', ')
		}
	}

	const getValueSelected = () => {
		if (selectedId && !Array.isArray(selectedId)) {
			const driver = driversState.drivers.byId[selectedId]
			return driver?.name
		}
	}

	const itemsDropdownModal = driversState.drivers.allIds
		.map((id) => driversState.drivers.byId[id])
		.filter((driver) => {
			if (driver.id === driversState.drivers.byId[selectedId as number]?.id) return true
			return driver.isActive
		})
		.map((driver) => {
			const associationsByDriver = driversState.getAssociationsByDriverId(driver.id)
			return {
				id: driver.id,
				name: driver.name,
				hasUser: !!associationsByDriver.length,
				isSelected: isMultipleAndArray ? selectedId?.includes(driver.id) : undefined,
				isDisabled:
					driver.id === driversState.drivers.byId[selectedId as number]?.id && !driver?.isActive
			}
		})

	return (
		<div className={`select-option${error ? ' error' : emptyString}`}>
			<label htmlFor={id}>
				{label}
				{isLabelOptional && <span className='helper-text'> ({t('general.optional')})</span>}
			</label>
			<InputButton
				id={id}
				type='select'
				text={isMultiple ? getMultipleValueSelected() : getValueSelected()}
				placeholder={titleAndPlaceholder}
				onClick={toggleModalVisibility}
				error={error}
			/>

			<DropdownSelectorModal
				title={titleAndPlaceholder}
				visible={modalIsVisible}
				items={itemsDropdownModal}
				toggleVisibility={toggleModalVisibility}
				handleClick={handleClick}
				isMultiple={isMultiple}
			/>

			{error && <span className='input-error-message'>{error}</span>}
		</div>
	)
}

export default SelectDriverOptions
