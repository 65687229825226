import { ENVIRONMENT } from 'constants/environment'
import { CompanyAvailable, Friend } from 'models/index'
import { normalize } from 'normalizr'
import { friendSchema, friendsAvailablesSchema } from 'schemas/index'
import {
	acceptFriendRequestService,
	getAllCompaniesAvailableService,
	getAllFriendsService,
	getFriendRequestsReceivedService,
	getFriendRequestsSentService,
	rejectReceivedRequestService,
	rejectSentRequestService,
	sendFriendRequestService
} from 'services/friends'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface FriendsState {
	friends: {
		byId: { [id: number]: Friend }
		allIds: number[]
	}
	requestsSent: {
		byId: { [id: number]: Friend }
		allIds: number[]
	}
	requestReceived: {
		byId: { [id: number]: Friend }
		allIds: number[]
	}
	friendsAvailables: {
		byId: { [id: number]: CompanyAvailable }
		allIds: number[]
	}
	addFriend: (friend: Friend) => void
	deleteRequestReceived: (id: number) => void
	deleteRequestSent: (id: number) => void
	fetchFriends: () => Promise<Friend[]>
	fetchFriendRequestsSent: () => Promise<Friend[]>
	fetchFriendRequestsReceived: () => Promise<Friend[]>
	resetFriends: () => void
	resetAllFriendsInfo: () => void
	getAllCompaniesAvailable: () => Promise<CompanyAvailable[]>
	getFriendProviderNameById: (id: number) => string | undefined
	sendRequest: (companyId: number, sendingProviderId: number) => Promise<void>
	acceptRequest: (requestId: number, sendingProviderId: number) => Promise<void>
	rejectReceivedRequest: (requestId: number) => Promise<void>
	rejectSentRequest: (requestId: number) => Promise<void>
}

export const useFriendsStore = create<FriendsState>()(
	devtools(
		(set, get) => ({
			friends: {
				byId: {},
				allIds: [] as number[]
			},
			requestsSent: {
				byId: {},
				allIds: [] as number[]
			},
			requestReceived: {
				byId: {},
				allIds: [] as number[]
			},
			friendsAvailables: {
				byId: {},
				allIds: [] as number[]
			},
			addFriend: (friend) => {
				const normalizedFriend = normalize(friend, friendSchema)
				set((state) => ({
					friends: {
						byId: { ...state.friends.byId, ...normalizedFriend.entities.friends },
						allIds: [...state.friends.allIds, normalizedFriend.result]
					}
				}))
			},
			deleteRequestReceived: (id) => {
				const { requestReceived } = get()
				const newRequestReceivedIds = requestReceived.allIds.filter((requestId) => requestId !== id)
				const newRequestReceivedById = {} as { [id: number]: Friend }
				newRequestReceivedIds.forEach((requestId) => {
					newRequestReceivedById[requestId] = requestReceived.byId[requestId]
				})
				set({
					requestReceived: {
						byId: { ...newRequestReceivedById },
						allIds: newRequestReceivedIds
					}
				})
			},
			deleteRequestSent: (id) => {
				const { requestsSent } = get()
				const newRequestSentIds = requestsSent.allIds.filter((requestId) => requestId !== id)
				const newRequestSentById = {} as { [id: number]: Friend }
				newRequestSentIds.forEach((requestId) => {
					newRequestSentById[requestId] = requestsSent.byId[requestId]
				})
				set({
					requestsSent: {
						byId: { ...newRequestSentById },
						allIds: newRequestSentIds
					}
				})
			},
			fetchFriends: async () => {
				const friends = await getAllFriendsService()
				const normalizedData = normalize(friends, [friendSchema])
				set({
					friends: {
						byId: { ...normalizedData.entities.friends },
						allIds: normalizedData.result
					}
				})
				return friends
			},
			fetchFriendRequestsSent: async () => {
				const friends = await getFriendRequestsSentService()
				const normalizedData = normalize(friends, [friendSchema])
				set({
					requestsSent: {
						byId: { ...normalizedData.entities.friends },
						allIds: normalizedData.result
					}
				})
				return friends
			},
			fetchFriendRequestsReceived: async () => {
				const friends = await getFriendRequestsReceivedService()
				const normalizedData = normalize(friends, [friendSchema])
				set({
					requestReceived: {
						byId: { ...normalizedData.entities.friends },
						allIds: normalizedData.result
					}
				})
				return friends
			},
			resetFriends: () => {
				set({ friends: { byId: {}, allIds: [] } })
			},
			resetAllFriendsInfo: () => {
				set({
					friends: { byId: {}, allIds: [] },
					requestsSent: { byId: {}, allIds: [] },
					requestReceived: { byId: {}, allIds: [] },
					friendsAvailables: { byId: {}, allIds: [] }
				})
			},
			getAllCompaniesAvailable: async () => {
				const friendsAvailables = await getAllCompaniesAvailableService()
				if (!friendsAvailables) return []

				const normalizedData = normalize(friendsAvailables, [friendsAvailablesSchema])
				set({
					friendsAvailables: {
						byId: { ...normalizedData.entities.friendsAvailables },
						allIds: normalizedData.result
					}
				})
				return friendsAvailables
			},
			getFriendProviderNameById: (friendId) => {
				const friendAssociatedNameProvider = get().friends.allIds.map((id) => {
					const friend = get().friends.byId[id]
					if (friend.receivingCompanyId === friendId) {
						return friend.sendingProviderName
					}
					return undefined
				})[0]

				return friendAssociatedNameProvider ?? undefined
			},
			acceptRequest: async (requestId, sendingProviderId) => {
				await acceptFriendRequestService(requestId, sendingProviderId)
				await get().fetchFriends()
				await get().fetchFriendRequestsReceived()
			},
			sendRequest: async (companyId, sendingProviderId) => {
				await sendFriendRequestService(companyId, sendingProviderId)
				await get().fetchFriends()
				await get().fetchFriendRequestsSent()
			},
			rejectReceivedRequest: async (requestId) => {
				await rejectReceivedRequestService(requestId)
				get().deleteRequestReceived(requestId)
			},
			rejectSentRequest: async (requestId) => {
				await rejectSentRequestService(requestId)
				get().deleteRequestSent(requestId)
			}
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'friends store'
		}
	)
)
