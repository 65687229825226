import React, { FC, useEffect, useState } from 'react'
import { emptyString } from 'utils/common'
import './Input.scss'

interface Props {
	label: string
	id: string
	className?: string
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	checked?: boolean
	error?: string
	styles?: React.CSSProperties
	labelHelper?: string
	disabled?: boolean
}

const CheckboxInput: FC<Props> = (props) => {
	const {
		label,
		id,
		className,
		onChange,
		checked = false,
		error = emptyString,
		styles,
		labelHelper,
		disabled
	} = props
	const [isChecked, setIsChecked] = useState(checked)

	useEffect(() => {
		setIsChecked(checked)
	}, [checked])

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked)
		onChange?.(e)
	}

	const classNames = () => {
		let classes = 'checkbox-wrapper'
		if (error) classes += ' error'
		if (labelHelper) classes += ' checkbox-label-helper'

		return classes
	}

	return (
		<div className={classNames()} style={{ ...styles }}>
			<label htmlFor={id}>
				<input
					type='checkbox'
					id={id}
					name={id}
					className={`${className} ${error ? 'error' : emptyString}`}
					onChange={handleChange}
					checked={isChecked}
					disabled={disabled}
				/>
				<div className='checkbox-label-wrapper'>
					<span>{label}</span>
					{labelHelper ? <span className=' checkbox-label-helper-name'>{labelHelper}</span> : null}
				</div>
			</label>
			{error && <span className='input-error-message'>{error}</span>}
		</div>
	)
}

export default CheckboxInput
