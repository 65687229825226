import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import CheckboxInput from 'components/elements/Input/CheckboxInput'
import Input from 'components/elements/Input/Input'
import { BASE_URL, LEGAL_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useToast from 'hooks/useToast'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Register as RegisterType, registerService } from 'services/user'
import { useUserStore } from 'stores/useUserStore'
import { emptyString } from 'utils/common'
import * as yup from 'yup'
import './Register.scss'

const Register: FC = () => {
	const { t } = useTranslation()
	const userState = useUserStore()
	const { showBackendErrorToast } = useToast()

	const formik = useFormik<RegisterType>({
		initialValues: {
			name: emptyString,
			email: emptyString,
			password: emptyString
		},
		validationSchema: yup.object().shape({
			name: yup.string().required(t('errors.required_m', { field: t('iam.full_name') })),
			email: yup
				.string()
				.email(t('errors.mail_not_valid'))
				.required(t('errors.required_m', { field: t('iam.email') })),
			password: yup
				.string()
				.required(t('errors.required_m', { field: t('iam.password') }))
				.min(6, t('errors.min_characters', { num: 6 }))
		}),
		onSubmit: async (values) => {
			await register(values)
		}
	})

	const register = async (values: RegisterType) => {
		try {
			await registerService(values)
			userState.logIn({
				username: values.email,
				password: values.password
			})
		} catch (err) {
			showBackendErrorToast(err)
		}
	}

	return (
		<FormLayout
			title={t('iam.register')}
			leftButton={<ButtonBack url={BASE_URL} />}
			footer={
				<div className='register-footer'>
					<ButtonComponent type='submit' fullWidth onClick={formik.handleSubmit}>
						{t('iam.register')}
					</ButtonComponent>
					<p className='register-footer-legal'>
						{t('iam.legal_info')} <Link to={LEGAL_URL}>{t('iam.legal_link')}</Link>
					</p>
				</div>
			}>
			<FormSectionLayout title={t('iam.enter_your_data_title')}>
				<Input
					id='name'
					label={t('iam.full_name')}
					placeholder={t('iam.full_name_placeholder')}
					onChange={formik.handleChange}
					valueSelected={formik.values['name']}
					error={formik.errors['name']}
				/>
				<Input
					id='email'
					type='email'
					label={t('iam.email')}
					placeholder={t('iam.email_placeholder')}
					onChange={formik.handleChange}
					valueSelected={formik.values['email']}
					error={formik.errors['email']}
				/>
				<Input
					id='password'
					type='password'
					label={t('iam.password')}
					placeholder={t('iam.password_placeholder')}
					onChange={formik.handleChange}
					valueSelected={formik.values['password']}
					error={formik.errors['password']}
					showPasswordStrength
				/>
				<label className='register-occupation-label'>{t('iam.occupation')}</label>
				<div className='register-occupation-checkbox-container'>
					<CheckboxInput id='taxi' label={t('iam.taxi')} />
					<CheckboxInput id='vtc' label={t('iam.vtc')} />
				</div>
			</FormSectionLayout>
		</FormLayout>
	)
}

export default Register
