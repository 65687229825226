import { AirplaneTakeoffIcon, MapPinIcon, MapPinLineIcon } from 'assets/svgs'
import Card from 'components/elements/Card/Card'
import RequestShareTag from 'components/elements/CategoryTag/RequestShareTag'
import SharedTransferTag from 'components/elements/CategoryTag/SharedTransferTag'
import StartEndCategoryTag from 'components/elements/CategoryTag/StartEndCategoryTag'
import HeaderTransferDetails from 'components/elements/HeaderTransferDetails/HeaderTransferDetails'
import { TRANSFER_URL } from 'constants/routes'
import { useTransferDetails } from 'hooks/useTransferDetails'
import { RouteTypeName } from 'models/Transfer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useConfigStore } from 'stores/useConfigStore'
import { timeToSpanish } from 'utils/dateUtils'
import MapLink from '../MapLink/MapLink'
import './DailyTransferCard.scss'

interface Props {
	id: number
}

const DailyTransferCard = (props: Props) => {
	const { id } = props
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { transfer, driver, fomento, accepted, pendings, hasStart, hasEnd } = useTransferDetails(id)
	const configState = useConfigStore()

	const getRouteColor = (route: keyof typeof RouteTypeName) => {
		return configState.colors[route]
	}

	const getDegradedColor = (route: keyof typeof RouteTypeName) => {
		return configState.secondaryColors[route]
	}

	const redirectToTransferDetails = () => {
		navigate(`${TRANSFER_URL}/${id}`)
	}

	const footerIsVisible = hasStart || hasEnd || pendings.length || accepted

	return (
		<Card
			role='button'
			className='daily-transfer-card'
			onClick={() => redirectToTransferDetails()}
			style={{
				borderColor: getRouteColor(transfer.route)
			}}>
			<HeaderTransferDetails
				driver={driver?.name}
				fomento={fomento}
				time={timeToSpanish(transfer.time)}
				backgroundColor={getDegradedColor(transfer.route)}
			/>

			<main className='daily-transfer-card-main'>
				<div className='daily-transfer-card-main-item'>
					<span className='body2'>{t('transfer.origin')}</span>
					<div className='daily-transfer-card-main-item-wrapper'>
						<MapPinIcon />
						<span className='daily-transfer-card-main-item-value'>{transfer.originAddress}</span>
						<MapLink address={transfer.originAddress} />
					</div>
				</div>
				<div className='daily-transfer-card-main-item'>
					<span className='body2'>{t('transfer.destination')}</span>
					<div className='daily-transfer-card-main-item-wrapper'>
						<MapPinLineIcon />
						<span className='daily-transfer-card-main-item-value'>
							{transfer.destinationAddress}
						</span>
						<MapLink address={transfer.destinationAddress} />
					</div>
				</div>
				{transfer.flight && (
					<div className='daily-transfer-card-main-item'>
						<span className='body2'>{t('transfer.flight')}</span>
						<div className='daily-transfer-card-main-item-wrapper flight-icon'>
							<AirplaneTakeoffIcon width={18} height={18} />
							<span className='daily-transfer-card-main-item-value'>{transfer.flight}</span>
						</div>
					</div>
				)}
			</main>

			{footerIsVisible ? (
				<footer className='daily-transfer-card-footer'>
					<span className='body2'>{t('transfer.states')}</span>
					<div className='daily-transfer-card-status-items'>
						<StartEndCategoryTag hasStart={hasStart} hasEnd={hasEnd} small />
						<RequestShareTag pendings={pendings} small />
						<SharedTransferTag accepted={accepted} small />
					</div>
				</footer>
			) : null}
		</Card>
	)
}

export default DailyTransferCard
