import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const PaperPlaneTiltIcon: FC<Props> = (props) => {
	const { width = 32, height = 32 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 32 32'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M28.415 3.58533C28.1635 3.334 27.8496 3.15425 27.5055 3.06461C27.1614 2.97497 26.7997 2.97867 26.4575 3.07533H26.4388L2.44626 10.3553C2.0557 10.4676 1.70863 10.6963 1.45126 11.0108C1.19388 11.3253 1.03842 11.7107 1.00557 12.1158C0.97272 12.5208 1.06404 12.9263 1.26738 13.2781C1.47071 13.63 1.77641 13.9115 2.14376 14.0853L12.8463 19.1541L17.915 29.8566C18.075 30.1993 18.3298 30.4891 18.6492 30.6918C18.9686 30.8944 19.3393 31.0015 19.7175 31.0003C19.775 31.0003 19.8325 30.9978 19.89 30.9928C20.2936 30.9601 20.6776 30.8048 20.9904 30.5476C21.3032 30.2905 21.5299 29.9438 21.64 29.5541L28.915 5.56158C28.915 5.55533 28.915 5.54908 28.915 5.54283C29.0129 5.20154 29.0182 4.84033 28.9304 4.49631C28.8425 4.15229 28.6646 3.83786 28.415 3.58533ZM19.7288 28.9816L19.7225 28.9991L14.8025 18.6128L20.7075 12.7066C20.8871 12.5175 20.9858 12.2658 20.9824 12.005C20.9791 11.7442 20.874 11.4951 20.6896 11.3107C20.5052 11.1263 20.2561 11.0212 19.9953 11.0179C19.7346 11.0146 19.4828 11.1132 19.2938 11.2928L13.3875 17.1978L3.00001 12.2778H3.01751L27 5.00033L19.7288 28.9816Z'
				fill='#713FFF'
			/>
		</svg>
	)
}

export default PaperPlaneTiltIcon
