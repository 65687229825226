import { ENVIRONMENT } from 'constants/environment'
import { Langs } from 'models/Settings'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import i18n from '../i18n'

interface LanguageState {
	language: keyof typeof Langs
	setLanguage: (lang: keyof typeof Langs) => void
}

export const useLanguageStore = create<LanguageState>()(
	devtools(
		persist(
			(set) => ({
				language: Langs.ES,
				setLanguage: (lang: keyof typeof Langs) => {
					set({ language: lang })
					i18n.changeLanguage(lang.toLocaleLowerCase())
				}
			}),
			{
				name: 'language',
				storage: createJSONStorage(() => localStorage)
			}
		),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'language'
		}
	)
)
