import { CloseIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { DAILY_URL } from 'constants/routes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { fullWidth, previousRoute } from 'utils/common'
import FormLayout from './FormLayout'

const TOTAL_STEPS = 9

const calculateStepProgress = (step: number) => {
	const stepPercentage = fullWidth / TOTAL_STEPS
	return stepPercentage * step
}

interface Props {
	children: React.ReactNode
	step: number
	leftButton?: React.ReactNode
	rightButton?: React.ReactNode
	footer?: React.ReactNode
	onSubmit?: () => void
}

const FormTransferLayout: React.FC<Props> = (props) => {
	const { children, step, leftButton, rightButton, footer, onSubmit } = props

	const { t } = useTranslation()
	const navigate = useNavigate()

	return (
		<FormLayout
			title={t('header.title.create_transfer')}
			progress={calculateStepProgress(step)}
			leftButton={
				leftButton ?? (
					<ButtonComponent onClick={() => navigate(previousRoute)} variant='only-text'>
						<strong>{t('header.back')}</strong>
					</ButtonComponent>
				)
			}
			rightButton={
				rightButton ?? (
					<ButtonComponent
						onClick={() => navigate(DAILY_URL)}
						variant='only-icon'
						icon={<CloseIcon />}
					/>
				)
			}
			footer={
				footer ??
				(onSubmit && (
					<ButtonComponent fullWidth onClick={onSubmit}>
						{t('general.continue')}
					</ButtonComponent>
				))
			}>
			{children}
		</FormLayout>
	)
}

export default FormTransferLayout
