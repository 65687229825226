import { Driver } from 'models/Driver'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDriversStore } from 'stores/useDriversStore'

const useDrivers = (driverId?: number) => {
	const driverState = useDriversStore()
	const [driver, setDriver] = useState<Driver | null>(null)
	const [drivers, setDrivers] = useState<Driver[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [error, setError] = useState<Nullable<string>>(null)
	const { t } = useTranslation()

	useEffect(() => {
		if (driverId !== undefined) {
			fetchDriver(driverId)
		} else {
			fetchDrivers()
		}
	}, [driverId])

	useEffect(() => {
		fetchAssociations()
	}, [])

	useEffect(() => {
		setDrivers(driverState.drivers.allIds.map((id) => driverState.drivers.byId[id]))
	}, [driverState.drivers])

	const fetchAssociations = async () => {
		await driverState.fetchAllDriverAssociations()
	}

	const fetchDriver = async (driverId: number) => {
		setLoading(true)
		setError(null)

		try {
			let fetchedDriver = driverState.drivers.byId[driverId]
			if (!fetchedDriver) {
				fetchedDriver = await driverState.fetchDriverById(driverId)
				if (!fetchedDriver) {
					throw new Error('Driver not found')
				}
			}
			setDriver(fetchedDriver)
			setDrivers([])
		} catch (e) {
			setError(t('driver.error_load_driver'))
			// console.error(e)
		}

		setLoading(false)
	}

	const fetchDrivers = async () => {
		setLoading(true)
		setError(null)

		try {
			let fetchedDrivers = driverState.drivers.allIds.map((id) => driverState.drivers.byId[id])

			if (fetchedDrivers.length === 0) {
				fetchedDrivers = await driverState.fetchDrivers()
			}
			setDriver(null)
		} catch (e) {
			setError(t('driver.error_load_drivers'))
			// console.error(e)
		}

		setLoading(false)
	}

	const hasAssociations = (driverId: number) => {
		let hasAssociation = false
		driverState.driversAssociations.allIds.forEach((id) => {
			if (driverState.driversAssociations.byId[id].driverId === driverId) {
				hasAssociation = true
			}
		})
		return hasAssociation
	}

	const removeDriver = async (driverId: number) => {
		setLoading(true)
		setError(null)

		try {
			await driverState.removeDriver(driverId)
		} catch (e) {
			setError(t('driver.delete_driver_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const activateDriver = async (driverId: number) => {
		try {
			await driverState.updateDriver({ isActive: true }, driverId)
		} catch (e) {
			setError(t('driver.activate_driver_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const deactivateDriver = async (driverId: number) => {
		try {
			await driverState.updateDriver({ isActive: false }, driverId)
		} catch (e) {
			setError(t('driver.deactivate_driver_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const getAssociationsByUserId = (userId: number) => {
		return driverState.getAssociationsByUserId(userId)
	}

	const getDriverByIdFromStore = (driverId: number) => {
		return driverState.drivers.byId[driverId]
	}

	return {
		driver,
		drivers,
		loading,
		error,
		hasAssociations,
		removeDriver,
		activateDriver,
		deactivateDriver,
		getAssociationsByUserId,
		getDriverByIdFromStore
	}
}

export default useDrivers
