import { Driver, DriverAssociations, DriverPost, FetchResponse } from 'models/index'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getAllDriversService = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Driver[]>>(`${endpoints.drivers}`, {
			method: 'GET',
			token,
			responseType: 'json'
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getDriverById = async (id: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Driver>>(`${endpoints.drivers}/${id}`, {
			method: 'GET',
			token,
			responseType: 'json'
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const createDriverService = async (driver: DriverPost): Promise<Driver> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Driver>>(`${endpoints.drivers}`, {
			method: 'POST',
			token,
			responseType: 'json',
			bodyReq: driver
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const updateDriverService = async (
	driver: Partial<DriverPost>,
	driverId: number
): Promise<Driver> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<Driver>>(`${endpoints.drivers}/${driverId}`, {
			method: 'PATCH',
			token,
			responseType: 'json',
			bodyReq: driver
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const getDriverAssociations = async () => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<DriverAssociations[]>>(
			`${endpoints.drivers}/associations`,
			{
				method: 'GET',
				token,
				responseType: 'json'
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const resetAndSetAssociationsToDriver = async (
	driverId: number,
	userIds: number[]
): Promise<DriverAssociations[]> => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<DriverAssociations[]>>(
			`${endpoints.drivers}/associations/reset-set`,
			{
				method: 'POST',
				token,
				responseType: 'json',
				bodyReq: { userIds, driverId }
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const deleteAssociationsByDriverId = async (driverId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<void>>(
			`${endpoints.drivers}/associations/${driverId}`,
			{
				method: 'DELETE',
				token
			}
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const deleteDriverById = async (driverId: number) => {
	const token = getUserToken()
	try {
		const response = await customFetch<FetchResponse<void>>(`${endpoints.drivers}/${driverId}`, {
			method: 'DELETE',
			token
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}
