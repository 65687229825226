import Input from 'components/elements/Input/Input'
import InputSwitch from 'components/elements/Input/InputSwitch'
import SelectMunicipalityOptions from 'components/elements/SelectOptions/SelectMunicipalityOptions'
import SelectProvinceOptions from 'components/elements/SelectOptions/SelectProvinceOptions'
import SelecteDocumentTypeOptions from 'components/elements/SelectOptions/SelecteDocumentTypeOptions'
import { FormikProps } from 'formik'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { ProviderForm } from 'models/Provider'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfigStore } from 'stores/useConfigStore'
import { getFormikError } from 'utils/formikUtils'

interface Props {
	formik: FormikProps<ProviderForm>
	setFieldRef: (field: string) => (ref: HTMLInputElement) => void
}

const ProviderInputs: FC<Props> = (props) => {
	const { formik, setFieldRef } = props

	const configStore = useConfigStore()
	const { t } = useTranslation()

	const handleProvinceChange = (provinceId: number) => {
		formik.setFieldValue('provinceId', provinceId)
		formik.setFieldValue('cityId', null)
	}

	return (
		<>
			<FormSectionLayout title={t('provider.identification')}>
				<Input
					ref={setFieldRef('name')}
					type='text'
					valueSelected={formik.values.name}
					id='name'
					label={t('provider.name')}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'name')}
					placeholder={t('provider.placeholder.provider_name')}
				/>
				<Input
					ref={setFieldRef('fiscalName')}
					type='text'
					valueSelected={formik.values.fiscalName}
					id='fiscalName'
					label={t('provider.fiscal_name')}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'fiscalName')}
					placeholder={t('provider.placeholder.fiscal_name')}
				/>
			</FormSectionLayout>
			<FormSectionLayout title={t('provider.documentation')}>
				<SelecteDocumentTypeOptions
					ref={setFieldRef('documentTypeId')}
					label={t('general.document_type')}
					selectedId={formik.values.documentTypeId}
					onSelect={(id) => formik.setFieldValue('documentTypeId', id)}
					error={getFormikError(formik, 'documentTypeId')}
					placeholder={t('provider.placeholder.select_document_type')}
				/>
				<Input
					ref={setFieldRef('document')}
					type='text'
					valueSelected={formik.values.document}
					id='document'
					label={t('provider.document_number')}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'document')}
					placeholder={t('provider.placeholder.document_number')}
				/>
				<InputSwitch
					id='isInternational'
					label={t('provider.international_company')}
					value={formik.values.isInternational}
					onChange={(value) => formik.setFieldValue('isInternational', value)}
				/>
			</FormSectionLayout>
			<FormSectionLayout title={t('provider.location')}>
				<SelectProvinceOptions
					ref={setFieldRef('provinceId')}
					onSelect={handleProvinceChange}
					selectedId={formik.values.provinceId}
					label={t('provider.province')}
					placeholder={t('provider.placeholder.select_province')}
					error={getFormikError(formik, 'provinceId')}
				/>
				<SelectMunicipalityOptions
					ref={setFieldRef('cityId')}
					label={t('provider.city')}
					onSelect={(municipalityId) => formik.setFieldValue('cityId', municipalityId)}
					selectedId={formik.values.cityId}
					provinceSelected={
						formik.values.provinceId
							? configStore.provinces.byId[formik.values.provinceId]?.code
							: null
					}
					placeholder={t('provider.placeholder.select_municipality')}
					error={getFormikError(formik, 'cityId')}
				/>
				<Input
					ref={setFieldRef('address')}
					type='text'
					valueSelected={formik.values.address}
					id='address'
					label={t('provider.address')}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'address')}
					placeholder={t('provider.placeholder.address')}
				/>
				<Input
					ref={setFieldRef('postalCode')}
					type='text'
					valueSelected={formik.values.postalCode}
					id='postalCode'
					label={t('provider.postal_code')}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'postalCode')}
					placeholder={t('provider.placeholder.postal_code')}
				/>
			</FormSectionLayout>
			<FormSectionLayout title={t('provider.provider_status')}>
				<InputSwitch
					dataTest='switch-active'
					id='isActive'
					label={t('general.active')}
					value={formik.values.isActive}
					onChange={(value) => formik.setFieldValue('isActive', value)}
				/>
			</FormSectionLayout>
		</>
	)
}

export default ProviderInputs
