import { RouteTypeName } from 'models/Transfer'
import { useConfigStore } from 'stores/useConfigStore'

const useRouteColors = () => {
	const configState = useConfigStore()

	const getDegradedColor = (route: keyof typeof RouteTypeName) => {
		return configState.secondaryColors[route]
	}

	const getRouteColor = (route: keyof typeof RouteTypeName) => {
		return configState.colors[route]
	}

	return { getDegradedColor, getRouteColor }
}

export default useRouteColors
