import AssociationList, {
	AssociationOption
} from 'components/elements/AssociationList/AssociationList'
import { User } from 'models/User'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProvidersStore } from 'stores/useProvidersStore'

interface Props {
	user: User
	onChangeAssociations: (userIds: number[]) => void
}

const AssociationsProvider: FC<Props> = (props) => {
	const { user, onChangeAssociations } = props

	const providerStore = useProvidersStore()
	const [selectedProviders, setSelectedProviders] = useState(new Set<number>())
	const [associationsOptions, setAssociationsOptions] = useState<AssociationOption[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const { t } = useTranslation()

	useEffect(() => {
		getAssociationsByUserId(user.id)
	}, [providerStore.providersAssociations])

	const getAssociationsByUserId = async (userId: number) => {
		const associations = providerStore.getAssociationsByUserId(userId)
		const providerIds = associations.map((association) => association.providerId)

		setSelectedProviders(new Set(providerIds))
	}

	const formatAssociations = (): AssociationOption[] => {
		const newAssociations: AssociationOption[] = providerStore.providers.allIds
			.filter(
				(id) =>
					providerStore.providers.byId[id].isActive && providerStore.providers.byId[id].isVisible
			)
			.map((id) => {
				const provider = providerStore.providers.byId[id]
				const isChecked = selectedProviders.has(provider.id)
				return {
					id: provider.id,
					name: provider.name,
					isSelected: isChecked
				}
			})
		return newAssociations
	}

	const handleSelection = (selected: AssociationOption[]) => {
		const newSelectedUsers = new Set<number>()
		selected.forEach((association) => {
			if (association.isSelected) {
				newSelectedUsers.add(association.id)
			}
		})
		setSelectedProviders(newSelectedUsers)
		onChangeAssociations(Array.from(newSelectedUsers))
	}
	const getAllProviderAssociations = async () => {
		setIsLoading(true)
		await providerStore.fetchAllProviderAssociations()
		setIsLoading(false)
	}

	useEffect(() => {
		const associations = formatAssociations()
		setAssociationsOptions(associations)
		onChangeAssociations(Array.from(selectedProviders))
	}, [selectedProviders])

	useEffect(() => {
		getAllProviderAssociations()
	}, [])

	return (
		<AssociationList
			associations={associationsOptions}
			title={t('provider_association.association_list_title')}
			addButtonText={t('provider_association.association_list_button')}
			modalContent={{
				title: t('provider_association.association_list_modal_title'),
				subtitle: t('provider_association.association_list_modal_subtitle'),
				description: t('provider_association.association_list_modal_description')
			}}
			handleSelection={handleSelection}
			isLoading={isLoading}
		/>
	)
}

export default AssociationsProvider
