import { CheckIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { CURRENCY_SYMBOL } from 'constants/common'
import { SubscriptionType } from 'models/Company'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './SubscriptionCardContent.scss'

interface Props {
	subscription: SubscriptionType
	onSubscribe: () => void
}

const baseCompany = 1

const SubscriptionPlanCardContent: React.FC<Props> = (props) => {
	const { subscription, onSubscribe } = props

	const { t } = useTranslation()

	const companiesAvailable = useMemo(
		() => subscription.extraCompanies + baseCompany,
		[subscription.extraCompanies]
	)

	return (
		<div className='subscription-card-content'>
			<h2 className='subscription-plan-card-content-title'>
				{subscription.name.toLocaleUpperCase()}
			</h2>
			<span className='subscription-plan-card-content-price'>
				{subscription.amountVatIncluded}
				{CURRENCY_SYMBOL}
			</span>
			<div className='subscription-plan-card-content-details'>
				<div className='subscription-plan-card-content-detail-container'>
					<CheckIcon />
					<span className='subscription-plan-card-content-detail'>
						{t(`settings.my_account.subscription.${subscription.type}`)}
					</span>
				</div>
				<div className='subscription-plan-card-content-detail-container'>
					<CheckIcon />
					<span className='subscription-plan-card-content-detail'>
						{t('settings.my_account.subscription.includes_companies', {
							count: companiesAvailable
						})}
					</span>
				</div>
				<div className='subscription-plan-card-content-detail-container'>
					<CheckIcon />
					<span className='subscription-plan-card-content-detail'>
						{t('settings.my_account.subscription.includes_plates', {
							count: subscription.maxEnrollments
						})}
					</span>
				</div>
			</div>
			<ButtonComponent
				className={`subscription-plan-card-content-button${
					subscription.color ? ` ${subscription.color}` : emptyString
				}`}
				onClick={onSubscribe}>
				{t('settings.my_account.subscription.action')}
			</ButtonComponent>
		</div>
	)
}

export default SubscriptionPlanCardContent
