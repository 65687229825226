import { CancelCommunicationIcon } from 'assets/svgs'
import TransferInfo from 'components/Transfer/TransferOptions/TransferInfo/TransferInfo'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import { FORM_URL, TRANSFER_FOMENTO_URL } from 'constants/routes'
import { useModal } from 'hooks/useModal'
import useToast from 'hooks/useToast'
import { useTransferDetails } from 'hooks/useTransferDetails'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { ErrorResponse } from 'models/CustomFetch'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useFomentoStore } from 'stores/useFomentoStore'
import './Fomento.scss'

const Fomento: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { transferId } = useParams()
	const { fomento, onRefresh } = useTransferDetails(Number(transferId))
	const fomentoState = useFomentoStore()
	const { showBackendErrorToast } = useToast()
	const [isCancelModal, toggleCancelModal] = useModal()

	const communicate = async () => {
		if (!transferId) return
		try {
			await fomentoState.communicate(parseInt(transferId))
			onRefresh()
		} catch (err) {
			const error = err as ErrorResponse
			const errorCode = error.status
			showBackendErrorToast(err)
			if (errorCode === 400) {
				await fomentoState.fetchFomentoInfoByBookingId(parseInt(transferId))
				navigate(`${TRANSFER_FOMENTO_URL}/${transferId}${FORM_URL}`)
			}
		}
	}

	const confirm = async () => {
		if (!transferId) return
		try {
			await fomentoState.confirm(parseInt(transferId))
			onRefresh()
		} catch (err) {
			showBackendErrorToast(err)
		}
	}

	const confirmCancel = async () => {
		if (!transferId) return
		try {
			await fomentoState.cancel(parseInt(transferId))

			onRefresh()
		} catch (err) {
			showBackendErrorToast(err)
		} finally {
			toggleCancelModal()
		}
	}

	return (
		<FormLayout
			title={t('fomento.title')}
			leftButton={<ButtonBack />}
			footer={
				!fomento ? (
					<ButtonComponent fullWidth onClick={communicate}>
						{t('fomento.communicate')}
					</ButtonComponent>
				) : fomento.scheduledAt && !fomento.confirmedAt && !fomento.cancelledAt ? (
					<div className='fomento-actions'>
						<ButtonComponent fullWidth onClick={confirm}>
							{t('fomento.confirm_communication')}
						</ButtonComponent>
						<ButtonComponent variant='secondary' fullWidth onClick={toggleCancelModal}>
							{t('fomento.cancel_communication')}
						</ButtonComponent>
					</div>
				) : null
			}>
			<TransferInfo fomento={fomento} />
			<ModalActions
				type='error'
				icon={<CancelCommunicationIcon />}
				title={t('fomento.modal_cancel_title')}
				description={t('fomento.modal_cancel_description')}
				isVisible={isCancelModal}
				onClose={toggleCancelModal}
				primaryButton={{
					text: t('fomento.cancel_communication'),
					onClick: confirmCancel
				}}
				secondaryButton={{
					text: t('general.cancel'),
					onClick: toggleCancelModal
				}}
			/>
		</FormLayout>
	)
}

export default Fomento
