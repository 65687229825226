import NumberAdjuster from 'components/elements/NumberAdjuster/NumberAdjuster'
import { Extra } from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import { handleGeneralKeyboardEvent } from 'utils/functions'
import './ExtraItem.scss'

interface Props {
	extra: Extra
	onChange: (passengers: number) => void
	value: number
	max?: number
}

const ExtraItem = (props: Props) => {
	const { extra, onChange, value, max = 10 } = props
	const [isOptionSelected, setIsOptionSelected] = useState(false)
	const [valueSelected, setValueSelected] = useState(value)
	const { t } = useTranslation()

	const toggleOption = () => {
		setIsOptionSelected(!isOptionSelected)
	}

	const handleChange = (passengers: number) => {
		setValueSelected(passengers)
		onChange(passengers)
	}

	useEffect(() => {
		setValueSelected(value)
	}, [value])

	return (
		<div className='extra-item-container'>
			<div
				key={extra.id}
				className={`extra-item ${isOptionSelected ? 'selected' : emptyString}`}
				onClick={toggleOption}
				onKeyDown={handleGeneralKeyboardEvent(() => toggleOption())}>
				<div className='extra-icon-container'>{extra.icon}</div>
				<div className='extra-title'>+ {t(extra.name)}</div>
				{!isOptionSelected ? <div className='value-selected'>{valueSelected}</div> : null}
			</div>
			{isOptionSelected && (
				<div className='extra-content'>
					<div className='extra-content-title'>{t('transfer.how_many_extra')}</div>

					<NumberAdjuster
						dataTest='extra'
						onChange={(passengers) => handleChange(passengers)}
						max={max}
						valueSelected={valueSelected}
						style={{ width: '4ch' }}
					/>
				</div>
			)}
		</div>
	)
}

export default ExtraItem
