import { ADMIN_CUSTOM_PAGE_URL, HOME_URL } from 'constants/routes'
import _ from 'lodash'
import { UserRoles } from 'models/User'
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useUserStore } from 'stores/index'

type PublicRouteProps = {
	component: React.ComponentType
	restricted?: boolean
}

const PublicRoute: React.FC<PublicRouteProps> = (props) => {
	const { component: Component, restricted = false } = props
	const userStore = useUserStore()
	const currentUser = userStore.currentUser
	const getMe = userStore.getMe
	const isLogged = !_.isEmpty(currentUser)
	const roleRoute =
		currentUser.userTypeId === UserRoles['super-admin'] ? ADMIN_CUSTOM_PAGE_URL : HOME_URL

	const getCookieValue = (cookieName: string) => {
		const cookies = document.cookie.split(';')
		const cookiePair = cookies
			.map((cookie) => cookie.trim().split('='))
			.find((pair) => pair[0] === cookieName)
		return cookiePair ? cookiePair[1] : undefined
	}

	const token = getCookieValue('token')

	useEffect(() => {
		if (token && !isLogged) {
			getMe(token)
		}
	}, [token, isLogged])
	return isLogged && restricted ? <Navigate to={roleRoute} /> : <Component />
}

export default PublicRoute
