import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import general_en from './locales/en/general.json'
import general_es from './locales/es/general.json'
import { Langs } from './models'

let savedLanguage = Langs.ES
const storedLangData = localStorage.getItem('language')

if (storedLangData) {
	try {
		const parsedData = JSON.parse(storedLangData)
		savedLanguage = parsedData.state.language || Langs.ES
	} catch (error) {
		console.error('Error al parsear los datos del idioma:', error)
	}
}

const resources = {
	es: {
		general: general_es
	},
	en: {
		general: general_en
	}
}

i18n.use(initReactI18next).init({
	compatibilityJSON: 'v3',
	defaultNS: 'general',
	resources,
	lng: savedLanguage.toLowerCase(),
	fallbackLng: 'es',
	interpolation: {
		escapeValue: false
	}
})

export default i18n
