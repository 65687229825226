import { Friend } from 'models/Friend'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { useFriendsStore } from 'stores/useFriendsStore'
import useToast from './useToast'

const useFriends = () => {
	const friendState = useFriendsStore()
	const [friends, setFriends] = useState<Friend[]>([])
	const [requestsSent, setRequestsSent] = useState<Friend[]>([])
	const [requestReceived, setRequestReceived] = useState<Friend[]>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [loadingRejectReceivedRequest, setLoadingRejectReceivedRequest] = useState<boolean>(false)
	const [loadingRejectSentRequest, setLoadingRejectSentRequest] = useState<boolean>(false)
	const [error, setError] = useState<Nullable<string>>(null)
	const { showBackendErrorToast } = useToast()
	const { t } = useTranslation()

	useEffect(() => {
		fetchFriends()
		fetchRequestsSent()
		fetchRequestsReceived()
	}, [])

	const fetchFriends = async () => {
		setLoading(true)
		setError(null)

		try {
			let fetchedFriends = friendState.friends.allIds.map((id) => friendState.friends.byId[id])
			if (fetchedFriends.length === 0) {
				fetchedFriends = await friendState.fetchFriends()
			}
			setFriends(fetchedFriends)
		} catch (e) {
			showBackendErrorToast(e)
			setError(t('friend.friends_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const fetchRequestsSent = async () => {
		setLoading(true)
		setError(null)

		try {
			let fetchedRequestsSent = friendState.requestsSent.allIds.map(
				(id) => friendState.requestsSent.byId[id]
			)
			if (fetchedRequestsSent.length === 0) {
				fetchedRequestsSent = await friendState.fetchFriendRequestsSent()
			}
			setRequestsSent(fetchedRequestsSent)
		} catch (e) {
			showBackendErrorToast(e)
			setError(t('friend.requests_sent_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const fetchRequestsReceived = async () => {
		setLoading(true)
		setError(null)

		try {
			let fetchedRequestsReceived = friendState.requestReceived.allIds.map(
				(id) => friendState.requestReceived.byId[id]
			)
			if (fetchedRequestsReceived.length === 0) {
				fetchedRequestsReceived = await friendState.fetchFriendRequestsReceived()
			}
			setRequestReceived(fetchedRequestsReceived)
		} catch (e) {
			showBackendErrorToast(e)
			setError(t('friend.requests_received_error'))
			console.error(e)
		}

		setLoading(false)
	}

	const rejectReceivedRequest = async (id: number) => {
		setLoadingRejectReceivedRequest(true)
		try {
			await friendState.rejectReceivedRequest(id)
			toast.success(t('friend.request_rejected'))
		} catch (e) {
			console.error(e)
			showBackendErrorToast(e)
		} finally {
			setLoadingRejectReceivedRequest(false)
		}
	}

	const rejectSentRequest = async (id: number) => {
		setLoadingRejectSentRequest(true)
		try {
			await friendState.rejectSentRequest(id)
			toast.success(t('friend.request_rejected'))
		} catch (e) {
			console.error(e)
			showBackendErrorToast(e)
		} finally {
			setLoadingRejectSentRequest(false)
		}
	}

	useEffect(() => {
		const newRequestsReceived = friendState.requestReceived.allIds.map(
			(id) => friendState.requestReceived.byId[id]
		)
		setRequestReceived(newRequestsReceived)
	}, [friendState.requestReceived])

	useEffect(() => {
		const newRequestsSent = friendState.requestsSent.allIds.map(
			(id) => friendState.requestsSent.byId[id]
		)
		setRequestsSent(newRequestsSent)
	}, [friendState.requestsSent])

	return {
		friends,
		requestsSent,
		requestReceived,
		rejectReceivedRequest,
		loadingRejectReceivedRequest,
		rejectSentRequest,
		loadingRejectSentRequest,
		loading,
		error
	}
}

export default useFriends
