import {
	AirplaneTakeoffIcon,
	MapPinIcon,
	MapPinLineIcon,
	StopIcon,
	UserFilledIcon
} from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import ExtraSeatCategoryTag from 'components/elements/CategoryTag/ExtraSeatCategoryTag'
import RequestShareTag from 'components/elements/CategoryTag/RequestShareTag'
import SharedTransferTag from 'components/elements/CategoryTag/SharedTransferTag'
import StartEndCategoryTag from 'components/elements/CategoryTag/StartEndCategoryTag'
import ColorIndicator from 'components/elements/ColorIndicator/ColorIndicator'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import DetailsCardRow from 'components/elements/DetailsCard/DetailsCardRow'
import HeaderTransferDetails from 'components/elements/HeaderTransferDetails/HeaderTransferDetails'
import MapLink from 'components/elements/MapLink/MapLink'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import PaidTag from 'components/elements/PaidTag/PaidTag'
import TransferTimer from 'components/elements/TransferTimer/TransferTimer'
import { CURRENCY_SYMBOL, NO_VALUE } from 'constants/common'
import { DAILY_URL } from 'constants/routes'
import useRouteColors from 'hooks/useRouteColors'
import { useTransferDetails } from 'hooks/useTransferDetails'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { RouteTypeName } from 'models/Transfer'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useTransfersStore } from 'stores/useTransfersStore'
import { useUserStore } from 'stores/useUserStore'
import { dateToSpanish, timeToSpanish } from 'utils/dateUtils'
import FomentoButton from './Buttons/FomentoButton'
import StartEndHour from './Buttons/StartEndHour'
import './TransferDetails.scss'
import TransferDetailsDrawerMenu from './TransferDetailsDrawerMenu'

const TransferDetails = () => {
	const { transferId } = useParams()
	const {
		transfer,
		driver,
		fomento,
		accepted,
		pendings,
		hasStart,
		hasEnd,
		startEndDetails,
		provider,
		user,
		vehicle
	} = useTransferDetails(Number(transferId), true)

	const [menuIsOpen, setMenuIsOpen] = useState(false)
	const [finishTransferModalisVisible, setFinishTransferModalisVisible] = useState(false)
	const transferState = useTransfersStore()
	const userState = useUserStore()

	const startHour = useMemo(() => {
		return startEndDetails?.createdAt ? new Date(startEndDetails?.createdAt) : null
	}, [startEndDetails?.createdAt])

	const endHour = useMemo(() => {
		return startEndDetails?.finishedAt ? new Date(startEndDetails?.finishedAt) : null
	}, [startEndDetails?.finishedAt])

	const { t } = useTranslation()

	const { getDegradedColor, getRouteColor } = useRouteColors()

	if (!transfer) {
		return null
	}

	const routeTranslation = t(`transfer.route.${transfer.route}`)

	const closeFinishTransferModal = () => setFinishTransferModalisVisible(false)

	const closeMenu = () => setMenuIsOpen(false)

	const statusSectionIsVisible = hasStart || hasEnd || pendings.length || accepted

	return (
		<>
			<FormLayout
				title={t('transfer.details.h1')}
				leftButton={<ButtonBack url={`${DAILY_URL}/${transfer.date}`} />}
				rightButton={
					<ButtonComponent onClick={() => setMenuIsOpen(true)} variant='text'>
						{t('general.options')}
					</ButtonComponent>
				}
				footer={
					<div className='transfer-details-footer'>
						<FomentoButton transferId={Number(transferId)} fomento={fomento} />
						{userState.isAdmin() && (
							<StartEndHour
								startHour={startHour}
								endHour={endHour}
								onEndHour={() => setFinishTransferModalisVisible(true)}
								onStartHour={async () => await transferState.startTransfer(Number(transferId))}
							/>
						)}
					</div>
				}>
				{startHour && (
					<FormSectionLayout noDivider>
						<TransferTimer startHour={startHour} endHour={endHour} />
					</FormSectionLayout>
				)}
				<FormSectionLayout
					title={routeTranslation}
					titleIcon={<ColorIndicator color={getRouteColor(transfer.route)} />}
					noDivider>
					<HeaderTransferDetails
						date={dateToSpanish(new Date(transfer.date))}
						driver={driver?.name}
						fomento={fomento}
						time={timeToSpanish(transfer.time)}
						backgroundColor={getDegradedColor(transfer.route)}
					/>
					<DetailsCard>
						<DetailsCardItem title={t('transfer.origin')}>
							<div className='transfer-details-value-with-icon'>
								<MapPinIcon />
								<span className='transfer-details-value-with-icon-text body1'>
									{transfer.originAddress}
								</span>
								<MapLink address={transfer.originAddress} />
							</div>
						</DetailsCardItem>
						<DetailsCardItem title={t('transfer.destination')}>
							<span className='transfer-details-value-with-icon'>
								<MapPinLineIcon />
								{transfer.destinationAddress}
								<MapLink address={transfer.destinationAddress} />
							</span>
						</DetailsCardItem>
						{transfer.flight && transfer.route === RouteTypeName.arrival ? (
							<DetailsCardItem title={t('transfer.flight')}>
								<span className='transfer-details-value-with-icon'>
									<AirplaneTakeoffIcon />
									{transfer.flight}
								</span>
							</DetailsCardItem>
						) : null}
					</DetailsCard>

					<DetailsCard>
						<DetailsCardRow>
							<DetailsCardItem title={t('transfer.passengers')}>
								<span className='transfer-details-value-with-icon' data-testid='passengers'>
									<UserFilledIcon color='#000' />
									{transfer.passengers}
								</span>
							</DetailsCardItem>
							<DetailsCardItem title={t('transfer.extras')}>
								<ExtraSeatCategoryTag transferId={transfer.id} />
							</DetailsCardItem>
						</DetailsCardRow>
					</DetailsCard>
					<DetailsCard>
						<DetailsCardRow>
							<DetailsCardItem title={t('transfer.price')}>
								{transfer.price ? `${transfer.price} ${CURRENCY_SYMBOL}` : NO_VALUE}
							</DetailsCardItem>
							<DetailsCardItem title={t('transfer.status')}>
								<PaidTag paid={transfer.isPaid} />
							</DetailsCardItem>
						</DetailsCardRow>
					</DetailsCard>
					<DetailsCard>
						<DetailsCardRow>
							<DetailsCardItem title={t('transfer.toll')}>
								{transfer.toll} {CURRENCY_SYMBOL}
							</DetailsCardItem>
							<DetailsCardItem title={t('transfer.commission')}>
								{transfer.commission} {CURRENCY_SYMBOL}
							</DetailsCardItem>
						</DetailsCardRow>
					</DetailsCard>
					{statusSectionIsVisible ? (
						<DetailsCard>
							<DetailsCardItem title={t('transfer.status')}>
								<div className='transfer-details-status'>
									<StartEndCategoryTag hasEnd={hasEnd} hasStart={hasStart} />
									<RequestShareTag pendings={pendings} />
									<SharedTransferTag accepted={accepted} />
								</div>
							</DetailsCardItem>
						</DetailsCard>
					) : null}
				</FormSectionLayout>
				<FormSectionLayout title={t('transfer.client')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('transfer.client')}>{transfer.clientName}</DetailsCardItem>
						<DetailsCardItem title={t('transfer.phone')}>
							{transfer.phone ? transfer.phone : NO_VALUE}
						</DetailsCardItem>
						<DetailsCardItem title={t('transfer.email')}>
							{transfer.email ? transfer.email : NO_VALUE}
						</DetailsCardItem>
						<DetailsCardItem title={t('transfer.observations')}>
							{transfer.observations ? transfer.observations : NO_VALUE}
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				<FormSectionLayout title={t('transfer.resources')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('transfer.driver')}>{driver.name}</DetailsCardItem>
						{vehicle && (
							<DetailsCardItem title={t('transfer.vehicle')}>
								{vehicle.brand} {vehicle.model} {vehicle.plate}
							</DetailsCardItem>
						)}
						<DetailsCardItem title={t('transfer.provider')}>{provider.name}</DetailsCardItem>
						<DetailsCardItem title={t('transfer.employee')}>
							{user.name} {user.lastName}
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
			</FormLayout>
			<TransferDetailsDrawerMenu
				transferId={Number(transferId)}
				menuIsOpen={menuIsOpen}
				closeMenu={closeMenu}
			/>
			<ModalActions
				icon={<StopIcon />}
				title={t('transfer.finish_transfer_modal.title')}
				description={t('transfer.finish_transfer_modal.description')}
				primaryButton={{
					text: t('transfer.finish_transfer_modal.primary_button'),
					onClick: async () => {
						await transferState.endTransfer(Number(transferId))
						closeFinishTransferModal()
					}
				}}
				secondaryButton={{
					text: t('transfer.finish_transfer_modal.secondary_button'),
					onClick: closeFinishTransferModal
				}}
				isVisible={finishTransferModalisVisible}
				onClose={closeFinishTransferModal}
			/>
		</>
	)
}

export default TransferDetails
