import { ArrowLeft } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { previousRoute } from 'utils/common'

interface Props {
	url?: string
}

const ButtonBack: FC<Props> = (props) => {
	const { url } = props

	const navigate = useNavigate()

	const route = url ?? previousRoute

	return (
		<ButtonComponent
			variant='only-icon'
			onClick={() => navigate(route as string)}
			icon={<ArrowLeft />}
			dataTest='button-back'
		/>
	)
}

export default ButtonBack
