import ModalFormLayout from 'components/elements/Modal/ModalFormLayout/ModalFormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { NonAdminRoleKeys, UserRoles } from 'models/User'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import SelectRoleCard from './SelectRoleCard/SelectRoleCard'
import './SelectRoleModal.scss'

interface Props {
	isVisible: boolean
	toggleVisibility: () => void
	handleSelectRole: (role: NonAdminRoleKeys) => void
}

const SelectRoleModal: FC<Props> = (props) => {
	const { isVisible, toggleVisibility, handleSelectRole } = props
	const { t } = useTranslation()

	const onSelectRole = (role: NonAdminRoleKeys) => () => {
		handleSelectRole(role)
		toggleVisibility()
	}

	return (
		<ModalFormLayout
			title={t('user.select_role')}
			isVisible={isVisible}
			toggleVisibility={toggleVisibility}>
			<FormSectionLayout className='select-role-modal'>
				<p className='select-role-modal-description'>{t('user.select_role_description')}</p>
				<SelectRoleCard type={UserRoles.provider} onClick={onSelectRole(UserRoles.provider)} />
				<SelectRoleCard type={UserRoles.driver} onClick={onSelectRole(UserRoles.driver)} />
				<SelectRoleCard type={UserRoles.revisor} onClick={onSelectRole(UserRoles.revisor)} />
			</FormSectionLayout>
		</ModalFormLayout>
	)
}

export default SelectRoleModal
