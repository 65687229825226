export const firstElement = 0
export const previousRoute = -1
export const fullWidth = 100
export const oneElement = 1
export const zeroElements = 0
export const zeroAmount = 0
export const firstRangeOfFilter = 0
export const amountOfTransfersTabId = 0
export const emptyString = ''
export const minPasswordLength = 8
