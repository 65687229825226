import { CrossIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './DrawerMenu.scss'

export type MenuItem = {
	label: string
	href: string
	icon?: React.ReactNode
	skip?: boolean
	onClick?: () => void
}
interface Props {
	isOpen: boolean
	onClose: () => void
	items: MenuItem[]
}
const DrawerMenu: React.FC<Props> = (props) => {
	const { isOpen, onClose, items } = props
	const { t } = useTranslation()

	return (
		<div
			className={`drawer-menu overlay ${isOpen ? 'overlay-entering' : 'overlay-leaving'}`}
			onClick={onClose}>
			<div className='menu' onClick={(e) => e.stopPropagation()}>
				<div className='menu-header'>
					<ButtonComponent
						className='drawer-menu-close-button'
						variant='text'
						leftIcon={<CrossIcon width={32} height={32} />}
						onClick={onClose}>
						{t('general.close')}
					</ButtonComponent>
				</div>
				<div className='menu-content'>
					{items
						.filter((item) => !item.skip)
						.map((item, index) => (
							<Link to={item.href} className='menu-item' key={index} onClick={item?.onClick}>
								{item?.icon} {item.label}
							</Link>
						))}
				</div>
			</div>
		</div>
	)
}

export default DrawerMenu
