import { MinusCircleIcon, PlusCircleIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import React, { useState } from 'react'
import { oneElement, zeroElements } from 'utils/common'
import './NumberAdjuster.scss'

interface Props {
	onChange: (value: number) => void
	valueSelected?: number
	max?: number
	title?: string
	style?: React.CSSProperties
	dataTest?: string
}

const NumberAdjuster: React.FC<Props> = ({
	onChange,
	valueSelected = oneElement,
	max,
	title,
	style = {},
	dataTest
}) => {
	const [value, setValue] = useState(valueSelected)

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = parseInt(e.target.value, 10)
		if (max && newValue > max) return
		if (newValue < zeroElements) {
			setValue(zeroElements)
			onChange(zeroElements)
			return
		}
		const newFormattedValue = isNaN(newValue) ? zeroElements : newValue
		setValue(newFormattedValue)
		onChange(newFormattedValue)
	}

	const increment = () => {
		const newValue = value + oneElement
		if (max && newValue > max) return
		setValue(newValue)
		onChange(newValue)
	}

	const decrement = () => {
		const newValue = value - oneElement
		if (newValue < zeroElements) return
		setValue(newValue)
		onChange(newValue)
	}

	return (
		<div>
			{title && <label className='number-adjuster-label'>{title}</label>}
			<div className='number-adjuster' data-testid='number-adjuster'>
				<ButtonComponent variant='only-icon' onClick={decrement} dataTest={`${dataTest}-decrement`}>
					<MinusCircleIcon />
				</ButtonComponent>
				<input
					className='number-adjuster-input'
					style={style}
					type='number'
					value={value}
					onChange={handleInputChange}
					max={max}
				/>
				<ButtonComponent variant='only-icon' onClick={increment} dataTest={`${dataTest}-increment`}>
					<PlusCircleIcon />
				</ButtonComponent>
			</div>
		</div>
	)
}

export default NumberAdjuster
