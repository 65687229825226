import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const PlusCircleFilledIcon: FC<Props> = (props) => {
	const { width = 26, height = 26 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='PlusCircle'>
				<path
					id='Vector'
					d='M13 2.4375C10.9109 2.4375 8.86879 3.05698 7.13179 4.2176C5.3948 5.37822 4.04098 7.02786 3.24153 8.95791C2.44208 10.8879 2.2329 13.0117 2.64046 15.0606C3.04802 17.1096 4.054 18.9916 5.53119 20.4688C7.00838 21.946 8.89044 22.952 10.9394 23.3595C12.9883 23.7671 15.1121 23.5579 17.0421 22.7585C18.9721 21.959 20.6218 20.6052 21.7824 18.8682C22.943 17.1312 23.5625 15.0891 23.5625 13C23.559 10.1997 22.4451 7.51514 20.465 5.53504C18.4849 3.55495 15.8003 2.44099 13 2.4375ZM17.0625 13.8125H13.8125V17.0625C13.8125 17.278 13.7269 17.4847 13.5745 17.637C13.4222 17.7894 13.2155 17.875 13 17.875C12.7845 17.875 12.5779 17.7894 12.4255 17.637C12.2731 17.4847 12.1875 17.278 12.1875 17.0625V13.8125H8.9375C8.72202 13.8125 8.51535 13.7269 8.36298 13.5745C8.21061 13.4222 8.125 13.2155 8.125 13C8.125 12.7845 8.21061 12.5778 8.36298 12.4255C8.51535 12.2731 8.72202 12.1875 8.9375 12.1875H12.1875V8.9375C12.1875 8.72201 12.2731 8.51535 12.4255 8.36298C12.5779 8.2106 12.7845 8.125 13 8.125C13.2155 8.125 13.4222 8.2106 13.5745 8.36298C13.7269 8.51535 13.8125 8.72201 13.8125 8.9375V12.1875H17.0625C17.278 12.1875 17.4847 12.2731 17.637 12.4255C17.7894 12.5778 17.875 12.7845 17.875 13C17.875 13.2155 17.7894 13.4222 17.637 13.5745C17.4847 13.7269 17.278 13.8125 17.0625 13.8125Z'
					fill='#713FFF'
				/>
			</g>
		</svg>
	)
}

export default PlusCircleFilledIcon
