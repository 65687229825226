import { default as Button, default as ButtonComponent } from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Input from 'components/elements/Input/Input'
import { BASE_URL, FORGOT_PASSWORD_URL, HOME_URL } from 'constants/routes'
import { TOAST_DURATION } from 'constants/toast'
import { useFormik } from 'formik'
import useInitializeAppData from 'hooks/useInitializeAppData'
import useToast from 'hooks/useToast'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { handleErrorResponse } from 'services/customFetch'
import { Credentials } from 'services/user'
import { useUserStore } from 'stores/useUserStore'
import { emptyString } from 'utils/common'
import * as yup from 'yup'
import './Login.scss'

const Login = () => {
	const currentUser = useUserStore((state) => state.currentUser)
	const { getAllData } = useInitializeAppData()
	const { showErrorToast } = useToast()
	const logIn = useUserStore((state) => state.logIn)
	const navigate = useNavigate()
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)

	const usernameRef = useRef<HTMLInputElement>(null)
	const passwordRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (currentUser.id) {
			getAllData()
				.then(() => {
					navigate(HOME_URL)
				})
				.catch((error) => {
					console.error(error)
				})
		}
	}, [currentUser])

	const submit = async (credentials: Credentials) => {
		try {
			setLoading(true)
			await logIn(credentials)
		} catch (error: unknown) {
			const errorResponse = handleErrorResponse(error)
			let messagesText = t('errors.login_failed')

			if (errorResponse) {
				messagesText = Object.values(errorResponse.messages).join('\n')
			}

			showErrorToast({
				description: messagesText,
				duration: TOAST_DURATION
			})
		} finally {
			setLoading(false)
		}
	}

	const formik = useFormik<Credentials>({
		initialValues: {
			username: emptyString,
			password: emptyString
		},
		validationSchema: yup.object().shape({
			username: yup.string().required(t('errors.required_m', { field: t('iam.email') })),
			password: yup.string().required(t('errors.required_m', { field: t('iam.password') }))
		}),
		onSubmit: submit
	})

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, field: string) => {
		if (e.key === 'Enter') {
			e.preventDefault()
			if (field === 'username') {
				passwordRef.current?.focus()
			} else if (field === 'password') {
				formik.handleSubmit()
			}
		}
	}

	return (
		<FormLayout
			title={t('iam.login')}
			leftButton={<ButtonBack url={BASE_URL} />}
			footer={
				<div className='login-footer'>
					<Button loading={loading} type='submit' fullWidth onClick={formik.handleSubmit}>
						{t('iam.login')}
					</Button>
					<ButtonComponent
						className='login-forgot-password'
						variant='text'
						onClick={() => navigate(FORGOT_PASSWORD_URL)}>
						{t('iam.forgot_password')}
					</ButtonComponent>
				</div>
			}>
			<FormSectionLayout title={t('iam.enter_your_data_title')}>
				<Input
					id='username'
					type='email'
					label={t('iam.email')}
					placeholder={t('iam.email_placeholder')}
					onChange={formik.handleChange}
					valueSelected={formik.values['username']}
					error={formik.errors['username']}
					onKeyDown={(e) => handleKeyDown(e, 'username')}
					ref={usernameRef}
				/>
				<Input
					id='password'
					type='password'
					label={t('iam.password')}
					className='formControl'
					placeholder={t('iam.password_placeholder')}
					onChange={formik.handleChange}
					valueSelected={formik.values['password']}
					error={formik.errors['password']}
					onKeyDown={(e) => handleKeyDown(e, 'password')}
					ref={passwordRef}
				/>
			</FormSectionLayout>
		</FormLayout>
	)
}

export default Login
