import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const AirplaneTakeoffIcon: FC<Props> = (props) => {
	const { width = 16, height = 16 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='AirplaneTakeoff'>
				<path
					id='Vector'
					d='M10.9999 13.5001C10.9999 13.6327 10.9472 13.7599 10.8534 13.8537C10.7597 13.9474 10.6325 14.0001 10.4999 14.0001H1.49987C1.36726 14.0001 1.24008 13.9474 1.14632 13.8537C1.05255 13.7599 0.99987 13.6327 0.99987 13.5001C0.99987 13.3675 1.05255 13.2403 1.14632 13.1466C1.24008 13.0528 1.36726 13.0001 1.49987 13.0001H10.4999C10.6325 13.0001 10.7597 13.0528 10.8534 13.1466C10.9472 13.2403 10.9999 13.3675 10.9999 13.5001ZM15.3942 5.42262L14.2292 3.92949L14.2217 3.92012C13.845 3.45607 13.3138 3.14312 12.7254 3.03846C12.1369 2.93379 11.5304 3.04442 11.0167 3.35012L7.79362 5.27387L4.42237 4.03387C4.3097 3.99186 4.18566 3.99186 4.07299 4.03387L3.62487 4.20449L3.60487 4.21262C3.45156 4.27843 3.31715 4.38155 3.21389 4.51258C3.11063 4.64362 3.04179 4.79841 3.01365 4.96285C2.9855 5.1273 2.99895 5.29617 3.05276 5.45409C3.10658 5.61201 3.19904 5.75395 3.32174 5.86699L4.68737 7.19137L3.42674 7.95387L1.66049 7.35762C1.53549 7.3155 1.3989 7.32449 1.28049 7.38262L1.09299 7.47449C0.942985 7.54153 0.811804 7.64449 0.711051 7.77428C0.610298 7.90407 0.543078 8.05669 0.515335 8.21864C0.487592 8.38058 0.500181 8.54687 0.551988 8.7028C0.603796 8.85872 0.693226 8.99948 0.81237 9.11262L3.06237 11.317L3.06987 11.3245C3.53076 11.7601 4.14069 12.0031 4.77487 12.0039C5.22007 12.0031 5.65708 11.8841 6.04112 11.6589L15.2542 6.15887C15.315 6.12254 15.3674 6.07371 15.4079 6.01561C15.4484 5.95751 15.4761 5.89147 15.4892 5.82186C15.5022 5.75226 15.5004 5.68067 15.4837 5.61184C15.467 5.54301 15.4359 5.47851 15.3924 5.42262H15.3942Z'
					fill='#130D2A'
				/>
			</g>
		</svg>
	)
}

export default AirplaneTakeoffIcon
