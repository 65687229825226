import ButtonComponent from 'components/elements/Button/Button'
import { ItemDropdown } from 'components/elements/DropdownSelector/DropdownSelector'
import DropdownSelectorModal from 'components/elements/Modal/DropdownSelectorModal'
import { useModal } from 'hooks/useModal'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTransfersShareStore } from 'stores/useTransfersShareStore'
import './TransferShareConfiguration.scss'

const TransferShareConfiguration: FC = () => {
	const { t } = useTranslation()

	const [initialDataModal, toggleInitialDataModal] = useModal()
	const [finalDataModal, toggleFinalDataModal] = useModal()
	const [selectedInitialData, setSelectedInitialData] = useState<ItemDropdown[]>([])
	const [selectedFinalData, setSelectedFinalData] = useState<ItemDropdown[]>([])

	const transferShareState = useTransfersShareStore()

	const formatDataSelected = (data: ItemDropdown[]) => {
		const selectedItems = data.filter((item) => item.isSelected)

		return selectedItems.map((item) => item.name).join(', ')
	}

	const getSelectedActiveItemsIds = (ids: number[], initialData: ItemDropdown[]) => {
		return initialData
			.filter((item) => {
				return !item.isDisabled
			})
			.map((item) => {
				const isSelected = ids.find((id) => id === item.id)
				return {
					...item,
					isSelected: !!isSelected
				}
			})
			.filter((item) => {
				return item.isSelected
			})
			.map((item) => {
				return item.id
			})
	}

	const handleInitialDataSelection = async (ids: number[]) => {
		const newSelectedInitialDataIds = getSelectedActiveItemsIds(ids, selectedInitialData)
		const initial = await transferShareState.updateTransferShareInitialConfiguration(
			newSelectedInitialDataIds
		)
		setSelectedInitialData(initial)
	}

	const handleFinalDataSelection = async (ids: number[]) => {
		const newSelectedFinalDataIds = getSelectedActiveItemsIds(ids, selectedFinalData)
		const after = await transferShareState.updateTransferShareAfterConfiguration(
			newSelectedFinalDataIds
		)
		setSelectedFinalData(after)
	}

	const fetchData = async () => {
		const { initial, after } = await transferShareState.fetchAllTransferShareConfigurations()
		setSelectedInitialData(initial)
		setSelectedFinalData(after)
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<>
			<FormSectionLayout>
				<div className='transfer-share-data-edit'>
					<div className='transfer-share-data-edit-content'>
						<span className='transfer-share-data-edit-content-title'>
							{t('transfer_share.initial_data_shared')}
						</span>
						<p className='transfer-share-data-edit-content-description body2'>
							{formatDataSelected(selectedInitialData)}
						</p>
					</div>
					<ButtonComponent onClick={toggleInitialDataModal} variant='only-text'>
						{t('general.edit')}
					</ButtonComponent>
				</div>
			</FormSectionLayout>
			<FormSectionLayout>
				<div className='transfer-share-data-edit'>
					<div className='transfer-share-data-edit-content'>
						<span className='transfer-share-data-edit-content-title'>
							{t('transfer_share.final_data_shared')}
						</span>
						<p className='transfer-share-data-edit-content-description body2'>
							{formatDataSelected(selectedFinalData)}
						</p>
					</div>
					<ButtonComponent onClick={toggleFinalDataModal} variant='only-text'>
						{t('general.edit')}
					</ButtonComponent>
				</div>
			</FormSectionLayout>
			<DropdownSelectorModal
				title={t('transfer_share.initial_data')}
				subtitle={t('general.share')}
				description={t('transfer_share.initial_data_description')}
				visible={initialDataModal}
				toggleVisibility={toggleInitialDataModal}
				items={selectedInitialData}
				handleClick={handleInitialDataSelection}
				isMultiple
			/>
			<DropdownSelectorModal
				title={t('transfer_share.final_data')}
				subtitle={t('general.share')}
				description={t('transfer_share.final_data_description')}
				visible={finalDataModal}
				toggleVisibility={toggleFinalDataModal}
				items={selectedFinalData}
				handleClick={handleFinalDataSelection}
				isMultiple
			/>
		</>
	)
}

export default TransferShareConfiguration
