import { FC } from 'react'

interface Props {
	width?: number
	height?: number
}

const CalendarBlank: FC<Props> = (props) => {
	const { width = 26, height = 26 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g id='CalendarBlank'>
				<path
					id='Vector_2'
					d='M21.125 3.25H18.6875V2.4375C18.6875 2.22201 18.6019 2.01535 18.4495 1.86298C18.2972 1.7106 18.0905 1.625 17.875 1.625C17.6595 1.625 17.4528 1.7106 17.3005 1.86298C17.1481 2.01535 17.0625 2.22201 17.0625 2.4375V3.25H8.9375V2.4375C8.9375 2.22201 8.8519 2.01535 8.69952 1.86298C8.54715 1.7106 8.34049 1.625 8.125 1.625C7.90951 1.625 7.70285 1.7106 7.55048 1.86298C7.3981 2.01535 7.3125 2.22201 7.3125 2.4375V3.25H4.875C4.44402 3.25 4.0307 3.4212 3.72595 3.72595C3.4212 4.0307 3.25 4.44402 3.25 4.875V21.125C3.25 21.556 3.4212 21.9693 3.72595 22.274C4.0307 22.5788 4.44402 22.75 4.875 22.75H21.125C21.556 22.75 21.9693 22.5788 22.274 22.274C22.5788 21.9693 22.75 21.556 22.75 21.125V4.875C22.75 4.44402 22.5788 4.0307 22.274 3.72595C21.9693 3.4212 21.556 3.25 21.125 3.25ZM7.3125 4.875V5.6875C7.3125 5.90299 7.3981 6.10965 7.55048 6.26202C7.70285 6.4144 7.90951 6.5 8.125 6.5C8.34049 6.5 8.54715 6.4144 8.69952 6.26202C8.8519 6.10965 8.9375 5.90299 8.9375 5.6875V4.875H17.0625V5.6875C17.0625 5.90299 17.1481 6.10965 17.3005 6.26202C17.4528 6.4144 17.6595 6.5 17.875 6.5C18.0905 6.5 18.2972 6.4144 18.4495 6.26202C18.6019 6.10965 18.6875 5.90299 18.6875 5.6875V4.875H21.125V8.125H4.875V4.875H7.3125ZM21.125 21.125H4.875V9.75H21.125V21.125Z'
					fill='#2C0089'
				/>
			</g>
		</svg>
	)
}

export default CalendarBlank
