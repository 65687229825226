import { ImageSquareIcon, UploadSimpleIcon } from 'assets/svgs'
import { useState } from 'react'
import { firstElement } from 'utils/common'
import './ProfileImageInput.scss'

interface Props {
	onChange?: (file: File) => void
	id?: string
	label?: string
	accept?: string
}

const ProfileImageInput: React.FC<Props> = (props) => {
	const { onChange, id, label, accept } = props

	const [file, setFile] = useState<File>()

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const newFile = e.target.files[firstElement]
			setFile(newFile)
			if (onChange) onChange(newFile)
		}
	}

	return (
		<div>
			{label && <label className='label'>{label}</label>}
			<div className='profile-image-input-wrapper'>
				<label
					className='profile-image-input-image-placeholder'
					htmlFor={id || 'profile-image-input'}>
					<div className='profile-image-input-image-placeholder-bg'>
						{file ? (
							<img
								className='profile-image-input-image'
								src={URL.createObjectURL(file)}
								alt='Selected file'
							/>
						) : (
							<ImageSquareIcon />
						)}
					</div>
					<div className='profile-image-input-upload-icon'>
						<UploadSimpleIcon />
					</div>
				</label>

				<input
					id={id || 'profile-image-input'}
					type='file'
					accept={accept || 'image/*'}
					className='profile-image-input'
					onChange={handleChange}
				/>
			</div>
		</div>
	)
}

export default ProfileImageInput
