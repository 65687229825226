import { MinusCircleRemoveIcon, PlusCircleFilledIcon } from 'assets/svgs'
import { ItemDropdown } from 'components/elements/DropdownSelector/DropdownSelector'
import Loading from 'components/elements/Loading/Loading'
import Tooltip, { TooltipProps } from 'components/elements/Tooltip/Tooltip'
import './AssociationListButtons.scss'

interface Props {
	buttonsTitle: string
	addButtonText: string
	associations: ItemDropdown[]
	tooltip?: TooltipProps
	onAdd: () => void
	onRemove: (id: number) => void
	isLoading: boolean
}

const AssociationListButtons: React.FC<Props> = (props) => {
	const { buttonsTitle, addButtonText, associations, tooltip, onAdd, onRemove, isLoading } = props

	if (isLoading) {
		return <Loading />
	}

	return (
		<div className='association-list-buttons'>
			<label className='association-list-buttons-label'>
				{buttonsTitle}.
				{tooltip && <Tooltip title={tooltip.title} description={tooltip.description} />}
			</label>
			<div className='association-list-buttons-container'>
				<button className='association-list-button association-list-button-add' onClick={onAdd}>
					{addButtonText}
					<PlusCircleFilledIcon />
				</button>
				{associations.map((item) => (
					<button
						key={item.id}
						className='association-list-button association-list-button-remove'
						onClick={() => onRemove(item.id)}>
						{item.name}
						<MinusCircleRemoveIcon />
					</button>
				))}
			</div>
		</div>
	)
}

export default AssociationListButtons
