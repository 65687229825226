import Alert from 'components/elements/Alert/Alert'
import { SharedTransfer } from 'models/Transfer'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { currentUserIsAdmin } from 'utils/currentUser'
interface Props {
	pending: SharedTransfer[]
}

const ShareTransferAlertComponent: FC<Props> = (props) => {
	const { pending } = props
	const { t } = useTranslation()
	const navigate = useNavigate()
	const isAdmin = currentUserIsAdmin()

	const [message, setMessage] = useState<string>('')

	useEffect(() => {
		if (!pending) return
		if (!isAdmin) return

		if (pending.length === 1) {
			setMessage(
				t('transfer_share.alert_one_shared', { companyName: pending[0].sendingCompanyName })
			)
		} else if (pending.length > 1) {
			setMessage(t('transfer_share.alert_one_shared', { count: pending.length }))
		}
	}, [pending])

	if (!pending?.length) return null

	const redirectTo = () => {
		if (pending.length === 1) {
			return navigate(`/transfer/receive/${pending[0].id}`)
		}
		navigate('/transfer-requests/received')
	}

	if (!message) return null

	return <Alert message={message} type='info' onClick={redirectTo} />
}

export default ShareTransferAlertComponent
