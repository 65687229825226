import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import InputSwitch from 'components/elements/Input/InputSwitch'
import Loading from 'components/elements/Loading/Loading'
import { ERROR_URL, USERS_EDIT_URL, USERS_URL } from 'constants/routes'
import useDrivers from 'hooks/useDrivers'
import useProviders from 'hooks/useProviders'
import useUsers from 'hooks/useUser'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { UserRoles } from 'models/User'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { emptyString } from 'utils/common'
import RoleTag from './RoleTag/RoleTag'

const UserDetails: FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { userId } = useParams<{ userId: string }>()
	const userIdNumber = parseInt(userId ?? emptyString) || undefined
	const { user, loading, error } = useUsers(userIdNumber)
	const providers = useProviders()
	const drivers = useDrivers()

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: USERS_URL } })
	if (!user) return <div>{t('user.not_found')}</div>

	const getAssociatedProviders = () => {
		if (!userIdNumber) return []
		return providers.getAssociationsByUserId(userIdNumber)
	}
	const getAssociatedDrivers = () => {
		if (!userIdNumber) return []
		return drivers.getAssociationsByUserId(userIdNumber)
	}

	return (
		<FormLayout
			title={t('user.title')}
			leftButton={<ButtonBack url={USERS_URL} />}
			rightButton={
				<ButtonComponent
					variant='only-text'
					onClick={() => navigate(`${USERS_EDIT_URL}/${userId}`)}>
					{t('general.edit')}
				</ButtonComponent>
			}>
			<FormSectionLayout title={t('user.user_details')}>
				<DetailsCard>
					<DetailsCardItem title={t('user.details.fullname')}>
						{user.name} {user.lastName}
					</DetailsCardItem>
					<DetailsCardItem title={t('user.details.email')}>{user.email}</DetailsCardItem>
					<DetailsCardItem title={t('user.details.role')}>
						<RoleTag roleId={user.userTypeId as UserRoles} />
					</DetailsCardItem>
				</DetailsCard>
			</FormSectionLayout>
			<FormSectionLayout title={t('user.user_status')}>
				<DetailsCard>
					<DetailsCardItem title={t('user.status')}>
						<InputSwitch
							id='status'
							label={t('user.active')}
							value={user.isActive}
							dataTest='switch-active'
						/>
					</DetailsCardItem>
				</DetailsCard>
			</FormSectionLayout>
			{user.userTypeId === UserRoles.driver ? (
				<FormSectionLayout title={t('user.associated_driver')}>
					<DetailsCard>
						{getAssociatedDrivers().length ? (
							getAssociatedDrivers().map((association) => (
								<DetailsCardItem key={association.id} title={t('user.details.name')}>
									{drivers.getDriverByIdFromStore(association.driverId)?.name}
								</DetailsCardItem>
							))
						) : (
							<DetailsCardItem title={t('user.name')}>
								{t('user.details.none_associated_driver')}
							</DetailsCardItem>
						)}
					</DetailsCard>
				</FormSectionLayout>
			) : null}
			{user.userTypeId === UserRoles.provider ? (
				<FormSectionLayout title={t('user.associated_provider')}>
					<DetailsCard>
						{getAssociatedProviders().length ? (
							getAssociatedProviders().map((association) => (
								<DetailsCardItem key={association.id} title={t('user.details.name')}>
									{providers.getProviderByIdFromStore(association.providerId)?.name}
								</DetailsCardItem>
							))
						) : (
							<DetailsCardItem title={t('user.name')}>
								{t('user.details.none_associated_provider')}
							</DetailsCardItem>
						)}
					</DetailsCard>
				</FormSectionLayout>
			) : null}
		</FormLayout>
	)
}

export default UserDetails
