import {
	BellSimpleIcon,
	CopyIcon,
	FileSearch,
	FrameCornersIcon,
	NotePencilIcon,
	PaperPlaneTiltIcon,
	ShareFatIcon,
	TrashIcon
} from 'assets/svgs'
import DrawerMenu from 'components/elements/DrawerMenu/DrawerMenu'
import Loading from 'components/elements/Loading/Loading'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import Toast from 'components/elements/Toast/Toast'
import {
	DAILY_URL,
	TRANSFER_FOMENTO_URL,
	TRANSFER_PDF_URL,
	TRANSFER_SHARE_URL,
	TRANSFER_URL
} from 'constants/routes'
import { useModal } from 'hooks/useModal'
import useToast from 'hooks/useToast'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { sendReminderDriverService } from 'services/transfers'
import { toast } from 'sonner'
import { useTransfersStore } from 'stores/useTransfersStore'
import { currentUserIsAdmin } from 'utils/currentUser'
import TransferPoster from './TransferPoster'

interface Props {
	transferId: number
	menuIsOpen: boolean
	closeMenu: () => void
}

const TransferDetailsDrawerMenu: React.FC<Props> = (props) => {
	const { transferId, menuIsOpen, closeMenu } = props

	const transferState = useTransfersStore()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [isPosterVisible, togglePosterModal] = useModal()
	const [isDeleteModalVisible, toggleDeleteModal] = useModal()
	const [loading, setLoading] = useState(false)
	const { showSuccessToast, showBackendErrorToast } = useToast()
	const isAdmin = currentUserIsAdmin()

	const copyToClipboard = async () => {
		await navigator.clipboard.writeText(window.location.href)
		toast.success(t('transfer.details.drawer_menu.copy_to_clipboard_success'))
		closeMenu()
	}

	const deleteTransfer = async () => {
		try {
			setLoading(true)
			await transferState.deleteTransfer(transferId)
			showSuccessToast({ description: t('transfer.details.drawer_menu.delete_transfer_success') })
		} catch (error) {
			console.error(error)
			showBackendErrorToast(error)
		} finally {
			setLoading(false)
			navigate(DAILY_URL)
		}
	}

	const sendToDriver = async () => {
		try {
			setLoading(true)
			await sendReminderDriverService(transferId)
			toast(
				<Toast
					type='info'
					icon={<PaperPlaneTiltIcon />}
					title={t('transfer.details.drawer_menu.send_to_driver_success_title')}
					description={t('transfer.details.drawer_menu.send_to_driver_success_description')}
				/>
			)
		} catch (error) {
			console.error(error)
			showBackendErrorToast(error)
		} finally {
			setLoading(false)
			closeMenu()
		}
	}

	const menuItems = useMemo(
		() => [
			{
				label: t('transfer.details.drawer_menu.see_document'),
				href: `${TRANSFER_PDF_URL}/:transferId`,
				icon: <FileSearch />
			},
			{
				label: t('transfer.details.drawer_menu.fomento'),
				href: `${TRANSFER_FOMENTO_URL}/:transferId`,
				icon: <BellSimpleIcon />
			},
			{
				label: t('transfer.details.drawer_menu.show_poster'),
				href: '#',
				icon: <FrameCornersIcon />,
				onClick: togglePosterModal
			},
			{
				label: t('transfer.details.drawer_menu.edit_transfer'),
				href: `${TRANSFER_URL}/:transferId/edit`,
				icon: <NotePencilIcon />,
				skip: !isAdmin
			},
			{
				label: t('transfer.details.drawer_menu.copy_to_clipboard'),
				href: '#',
				icon: <CopyIcon />,
				onClick: copyToClipboard
			},
			{
				label: t('transfer.details.drawer_menu.send_to_friend'),
				href: `${TRANSFER_SHARE_URL}/:transferId`,
				icon: <ShareFatIcon />,
				skip: !isAdmin
			},
			{
				label: t('transfer.details.drawer_menu.send_to_driver'),
				href: '#',
				icon: <ShareFatIcon />,
				onClick: sendToDriver,
				skip: !isAdmin
			},
			{
				label: t('transfer.details.drawer_menu.delete_transfer'),
				href: '#',
				icon: <TrashIcon />,
				onClick: toggleDeleteModal,
				skip: !isAdmin
			}
		],
		[transferId]
	)

	const menuItemsMapped = menuItems.map((item) => {
		return {
			...item,
			href: item.href.replace(':transferId', transferId.toString())
		}
	})

	if (loading) return <Loading fullscreen />

	return (
		<>
			<DrawerMenu isOpen={menuIsOpen} onClose={closeMenu} items={menuItemsMapped} />
			<TransferPoster
				isVisible={isPosterVisible}
				toggleVisibility={togglePosterModal}
				transferId={transferId}
			/>
			<ModalActions
				type='error'
				title={t('transfer.details.drawer_menu.delete_transfer_modal_title')}
				description={t('transfer.details.drawer_menu.delete_transfer_modal_description')}
				isVisible={isDeleteModalVisible}
				onClose={toggleDeleteModal}
				primaryButton={{
					text: t('general.remove'),
					onClick: deleteTransfer
				}}
				secondaryButton={{
					text: t('general.cancel'),
					onClick: toggleDeleteModal
				}}
			/>
		</>
	)
}

export default TransferDetailsDrawerMenu
